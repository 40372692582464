import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, Badge, ListGroup, ListGroupItem, Card, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../Utils/api';
import Markdown from '../..//Widgets/Markdown';
import CandidateWidget from '../../Widgets/CandidateWidget';
import BannerMessage from '../BannerMessage';
import { getCandidates } from '../../Utils/api';
import { useHistory } from "react-router-dom";
import { getCandidatesData } from '../../Store/dataSlice';
import { useDispatch, useSelector } from 'react-redux';

const ListCandidates = ({ onSelect }) => {
    const history = useHistory();
    const dispatch = useDispatch();



    const [selectedCandidate, setSelectedCandidate] = useState(null)

    const candidates = useSelector((state) => state.data.candidates);
    useEffect(() => {
        if (candidates != null) {
            return;
        }
        getData()
        // getCandidates().then((response) => setCandidates(response));
    }, []);

    const getData = async() => {
        await dispatch(getCandidatesData());
    }

    const onCandidateClick = async (candidate) => {
        setSelectedCandidate(candidate)
        if(onSelect) {
            onSelect(candidate)
        } 
    }

    return (
        <Card>
            <Card.Header className=''>
            <input type="text" className='form-control rounded-5' placeholder='Search...' />

            </Card.Header>
        <ListGroup variant='flush' className='rounded-2 shadow-sm'>
            {/* <ListGroupItem className="container content-space-2  ">
                
            </ListGroupItem> */}
            {/* <div className='text-center'><Link to="/recruiter/candidates">Manage Candidates</Link></div> */}


            {candidates && (
                candidates.length == 0 ?
                    <ListGroupItem className='text-dark bg-light shadow-sm p-4 rounded-2'><b>No Candidates Found. Go ahead and create one to get started</b></ListGroupItem> :
                    <>
                        {candidates.map((candidate) => (
                            

                            <ListGroupItem className={selectedCandidate &&  (selectedCandidate.id == candidate.id) ? 'active bg-light p-4 d-flex justify-content-between align-items-start' : 'p-4 d-flex justify-content-between align-items-start'} key={candidate.id} >
                                <div className="me-auto">
                                <CandidateWidget onClick={onCandidateClick} candidate={candidate} />
                                </div>
                                {/* <i className='la la-plus la-x bg-dark text-white p-2 rounded-pill' pill></i> */}
                            </ListGroupItem>
                        ))}
                    </>
            )}
        </ListGroup>
        </Card>
    );
};

export default ListCandidates;
