// submitCandidateSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { GetState } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

// import api from '../Utils/api';
import api, { getCandidates } from '../Utils/api';

const initialState = {
  jobs: null,
  candidates: null,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    // Define actions to update the shared state
    setJobs: (state, action) => {
      state.jobs = action.payload;
    },
    setCandidates: (state, action) => {
      state.candidates = action.payload;
    },
  },
});

export const { setJobs, setCandidates } = dataSlice.actions;

export const getCandidatesData  = () => async (dispatch, getState) => {
  const currentState = getState();
  if (currentState.data.candidates === null) {
    getCandidates().then((response) => dispatch(setCandidates(response)));
  }
};

export const getJobs  = () => async (dispatch, getState) => {
  const currentState = getState();

  if (currentState.data.jobs === null) {
    dispatch(getJobsAsync());
  }
};

export const getJobsAsync = () => async (dispatch) => {
  try {
    const response = await getJobsFromAPI();
    dispatch(setJobs(response));
  } catch (error) {
    console.error("ERROR!", error);
    throw error.message;
  }
};

const getJobsFromAPI = async () => {
  var data = {};
  await api
    .get(`/jobs`)
    .then((response) => {
      data = response.data || [];
    })
    .catch((error) => {
      console.error('Error fetching jobs:', error);
      throw error.message;
    });

  return data;
};

export default dataSlice.reducer;
