import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';
import BannerMessage from '../App/BannerMessage';
import Markdown from '../Widgets/Markdown';

const Terms = () => {

  const content = `

**Last updated: January 14th, 2024**
Terms of Use 

Welcome to MillionRecruiters.com. Review the following to understand the rules and restrictions that govern your use of our website(s), product(s), services, and applications (the “Services”). If you have any questions or comments regarding these terms or the Services, contact office@millionrecruiters.com 

 

These Terms of Use (the “Terms”) are a binding contract between you and Million Recruiters, LLC. (“Million Recruiters,” “we” and “us”). Your use of the Services in any and ways means that you agree to all of these Terms, and these Terms will remain in effect during your use of the Services. These Terms include the provisions in this document in addition to those found in the Privacy Policy. Your use of and/or participation in certain Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms. 

These Terms include information about future changes to these Terms, a class action waiver and resolution of disputes by arbitration instead of in court and limitations of liability. Please note that your use of and access to our services are subject to the following terms; if you do not agree to all the following, you may not use or access the services in any way whatsoever. 

Arbitration Notice and Class Action Waiver: You agree that disputes between you and us will be resolved by binding, individual arbitration and you waive your right to participate in a class action lawsuit or class-wide arbitration, except for certain types of disputes described in the arbitration agreement section below. 

 

### 1. Your Million Recruiters account 
#### 1.1 Using Million Recruiters 

Access to certain features of Million Recruiters may require account registration. This involves selecting a password, providing a valid email address, and supplying accurate and complete registration information, which you agree to keep updated. You may not use a name or alias that you do not have the right to use, or another person's name with the intent to impersonate that person. Account transfer to another individual is prohibited without prior written permission from Million Recruiters. By creating an account, you consent to receive periodic email notifications and newsletters from Million Recruiters. You may unsubscribe from these communications at any time by contacting support or using the "Unsubscribe" link or reply option within the emails. 

You can unlock new features or areas of Million Recruiters by connecting your account with authorized third-party services like Google or LinkedIn. This lets us access relevant information from your linked account, but only what you allow. Manage your privacy settings on the third-party platform to control how much information we can see. 

You must be old enough to enter into a binding contract in your jurisdiction. If you're younger, you need your parent or guardian's permission. They'll need to agree to these Terms on your behalf. If you're representing an organization, you confirm you have the authority to bind them to these Terms. "You" and "your" then refer to your organization throughout these Terms. 

Million Recruiters is for your own, personal use only. You can't use it for or benefit third parties. You must obey all laws applicable to you, your use of Million Recruiters, and your related actions. Employers are especially responsible for obeying laws relevant to their use of the platform. If using Million Recruiters is illegal where you are, you're not authorized to use it. We take no responsibility for illegal use. 

Keep your Million Recruiters account information and password confidential. Secure any access tools or credentials. You're responsible for all activity linked to your account. 



#### 1.2 Children's Online Privacy Protection Act 

Our Services are not directed to children under 16 years of age. We do not intentionally gather personal data from those we know to be under 16 years of age. The Children's Online Privacy Protection Act requires consent from a parent or guardian for collection of personally identifiable information from children under 16. If you are a child under 16 years of age, please do not attempt to register for or otherwise use the Services or send us any personal information. If we discover we have inadvertently obtained personal data from someone under 16 through use of our Services, we will promptly delete such information. If you believe a child under 16 may have provided personal data to us, please contact us immediately at office@millionrecruiters.com so we can take action. We aim to comply fully with privacy protections for minors as defined in applicable laws and regulations. 

### 2. Restrictions in using Services 

You hereby represent, warrant, and agree that you will not provide or contribute any Content or User Submission (as defined below) to the Services, or otherwise use or interact with the Services, in a manner that:  

Infringes or violates the intellectual property rights or any other rights of any party, including Million Recruiters. 
You further agree to abide by all applicable laws and regulations, including, but not limited to, export control laws, privacy laws, and other regulations governing the intended use of the Services. 
You are prohibited from engaging in any activity that is deemed dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable in nature. 
You are strictly prohibited from jeopardizing the security of any Million Recruiters account, including your own and those of other users. This includes actions such as allowing unauthorized individuals to log in to the Services using your credentials or attempting to obtain the password, account, or other security information of other users. 
Any attempts to violate the security of any computer network, crack passwords or security encryption codes, or interfere with the proper functioning of the Services are strictly prohibited and may result in termination of your account. 
Prohibitions on Automated Processes and Code Tampering 
You may not run any form of auto-responder or spam on the Services, nor may you engage in any processes that run or are activated while you are not logged in, or that otherwise interfere with the proper working of the Services, including placing an unreasonable load on the Services' infrastructure. 
Crawling, scraping, or spidering of any page, data, or portion of the Services or Content, whether through manual or automated means, is strictly prohibited. 
You are not permitted to copy or store any significant portion of the Content, nor are you allowed to decompile, reverse engineer, or otherwise attempt to obtain the source code or underlying ideas or information of or relating to the Services. 
Any violation of the aforementioned provisions may result in the immediate termination of your right to use or access the Services, without prior notice. 

 

### 3. Terms specific to Recruiters 

You hereby unequivocally represent and warrant that you have secured express consent from each Candidate prior to sharing any information pertaining to said Candidate with Million Recruiters and/or with any Employer made accessible through the Services. 

The scope of information subject to this consent encompasses, but is not limited to, the following: 

Candidate's full name 
Contact information, including email address, home address, and phone number 
Resume detailing employment history, skills, and qualifications 
You further acknowledge and agree that any breach of this representation and warranty may subject you to legal liability for potential violations of privacy regulations and applicable data protection laws. 

 

### 4. Rights in Services 

All materials presented, performed, or accessible within the Services, including but not limited to text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined herein), and other content (collectively, the "Content"), are safeguarded by copyright and/or other intellectual property laws. 

You hereby agree to unconditionally adhere to all copyright notices, trademark regulations, information, and restrictions associated with any Content accessed through the Services. 

You are strictly prohibited from engaging in any of the following activities without first obtaining explicit consent from the rightful owner of the Content or in a manner that infringes upon the rights of any third party, including Million Recruiters: Copying, Reproduction, Modification, Translation, Publication, Broadcasting, Transmission, Distribution, Performance, Uploading, Display, Licensing, Sale, Commercialization, and/or any other form of exploitation. 

Subject to the terms and conditions outlined herein, Million Recruiters grants each user of the Services a worldwide, non-exclusive, non-sublicensable, and non-transferable license to utilize (i.e., download and display locally) Content solely for the purpose of engaging with the Services. 

Any use, reproduction, modification, distribution, or storage of Content for any purpose other than using the Services as intended is expressly prohibited without prior written authorization from Million Recruiters. 

You acknowledge and agree that Million Recruiters retains full ownership of the Services. You are strictly prohibited from engaging in the following actions without express written permission from Million Recruiters:, Modification, Publication, Transmission, Participation in the transfer or sale, Reproduction (except as expressly permitted in this Section), Creation of derivative works, Any other form of exploitation of the Services,  

While the Services may, in certain instances, allow you to copy or download specific Content, it is imperative to remember that the restrictions outlined in this Section remain in full effect, even in the presence of such functionalities. 

### 5. Licenses to Million Recruiters or other users 
#### 5.1 User Submissions and your responsibility: 

Any content you post, upload, share, store, or otherwise provide through the Services is deemed a "User Submission." Certain User Submissions may be visible to other users of the Services. 

Your Sole Responsibility: 

You bear full and sole responsibility for all User Submissions you contribute to the Services. 

You represent and warrant that all User Submissions submitted by you are: 

Accurate, Complete, Up-to-date 
In strict compliance with all applicable laws, rules, and regulations 
Prohibited User Submissions: 

You explicitly agree to refrain from posting, uploading, sharing, storing, or otherwise providing any User Submissions that fall under the following categories: 

Infringing upon any third party's intellectual property rights, including but not limited to copyrights, trademarks, and privacy rights. 
Containing sexually explicit content or pornography. 
Containing hateful, defamatory, or discriminatory content, or inciting hatred against any individual or group. 
Exploiting minors. 
Depicting unlawful acts or extreme violence. 
Depicting animal cruelty or extreme violence towards animals. 
Promoting fraudulent schemes, multi-level marketing (MLM) schemes, get-rich-quick schemes, online gaming and gambling, cash gifting, work-from-home businesses, or any other dubious money-making ventures. 
Violating any applicable laws. 

#### 5.2 Licenses 

Purpose of Licenses: 

To enable the display of your User Submissions within the Services. 
To facilitate the use of User Submissions by other users, where applicable. 
Scope of Licenses: 

Worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable, and transferable license granted to Million Recruiters: 
Encompasses rights to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit User Submissions in connection with: 
This site 
The Services 
Million Recruiters' businesses (including those of successors and assigns) 
Extends to promoting and redistributing parts or all of this site, the Services, and derivative works thereof across any media formats and channels, including third-party websites and feeds. 
Persists even after termination of your account or the Services. 
Non-exclusive, perpetual license granted to each user of this site and/or the Services: 
Encompasses rights to access, use, edit, modify, reproduce, distribute, prepare derivative works of, display, and perform User Submissions through this site and/or the Services. 
Persists even after termination of your account or the Services. 
Additional Clarifications: 

The aforementioned licenses granted to Million Recruiters and users do not impact your other ownership or license rights in your User Submissions, including the right to grant further licenses, unless otherwise agreed upon in writing. 
You affirm that you possess all necessary rights to grant these licenses to Million Recruiters without infringing upon or violating any third-party rights, including but not limited to: 
Privacy rights 
Publicity rights 
Copyrights 
Trademarks 
Contract rights 
Any other intellectual property or proprietary rights 
Sharing Information and Content: 

Certain features of the Services permit the sharing of information, including through social networks or other Third Party Accounts. 
Sharing information from the Services through Third Party Accounts constitutes authorization for Million Recruiters to share that information with the respective Third Party Account provider. 
It's imperative to review the policies of Third Party Account providers for details on their information usage practices. 
Redistribution of Content necessitates the ability to edit or delete it promptly upon request from Million Recruiters. 
Technical Modifications: 

You acknowledge and agree that Million Recruiters, in executing the required technical steps to provide the Services to users (including yourself), may need to make modifications to User Submissions to ensure conformity and adaptation to the technical requirements of: Connection networks, Devices, Services, and Media 
The aforementioned licenses encompass these rights to make such modifications. 
 

### 6. Copyright Infringement Policy 

Million Recruiters adheres to the Digital Millennium Copyright Act (DMCA) and reserves the right to: 

Block access to or remove material believed in good faith to be copyrighted material that has been illegally copied and distributed by advertisers, affiliates, content providers, members, or users. 
Terminate services for repeat offenders. 
 

#### 6.1 Procedure for Alleging Copyright Infringements 

 

If you suspect that material or content within or accessible through the Services infringes upon your copyright (or the copyright of someone you are authorized to represent), please submit a Notice of Copyright Infringement to Million Recruiters' Designated Agent, whose contact information is provided below. 

The Notice must incorporate the following elements: 

Authorized Signature: A physical or electronic signature of an individual duly authorized to act on behalf of the copyright owner whose rights are allegedly infringed. 
Identification of Infringed Works: A precise identification of the copyrighted works or materials that are claimed to be infringed. 
Identification of Infringing Material: Specific identification of the material that is claimed to be infringing, including its location within the Services, accompanied by sufficient detail to enable Million Recruiters to locate and verify its existence. 
Contact Information: Full contact information of the notifier, including name, address, telephone number, and email address (if available). 
Good Faith Statement: A statement attesting to the notifier's good faith belief that the material identified as infringing is not authorized by the copyright owner, its agent, or the law. 
Statement of Accuracy and Authority: A statement made under penalty of perjury that the information provided in the Notice is accurate and that the notifier is authorized to act on behalf of the copyright owner. 
Actions Upon Receipt of Proper Notice: 

Upon receipt of a Notice of Copyright Infringement that complies with the aforementioned requirements, Million Recruiters reserves the right to take the following actions: 

Removal or Disablement of Infringing Material: Remove or disable access to the material that is alleged to be infringing. 
Notification to Content Provider: Notify the content provider who is accused of infringement about the removal or disablement of the material. 
Termination of Repeat Offenders: Terminate the access of content providers who are found to be repeat infringers of copyright. 
 

#### 6.2 Procedure to Supply a Counter-Notice to the Designated Agent 

Procedure for Counter-Notification by Content Providers: 

In the event that a content provider maintains a belief that removed or disabled material does not constitute infringement, or that they possess the requisite rights to post and utilize such material, a counter-notification may be submitted to Million Recruiters' Designated Agent, adhering to the following guidelines: 

Signature: A physical or electronic signature of the content provider. 
Identification of Material: Specific identification of the material that was removed or disabled, along with its original location within the Services prior to removal or disablement. 
Statement of Good Faith Belief: A statement attesting to the content provider's good faith belief that the material was removed or disabled due to a mistake or misidentification. 
Contact Information and Consent to Jurisdiction: 
Full contact information of the content provider, including name, address, telephone number, and email address (if available). 
A statement explicitly consenting to the jurisdiction of: 
The Federal Court for the judicial district in which the content provider's address is located. 
In cases where the content provider's address is outside the United States, any judicial district in which Million Recruiters is located. 
An affirmation that the content provider will accept service of process from the individual or entity who initiated the Notice of Copyright Infringement. 
Company's Actions Upon Receipt of Counter-Notification: 

Upon receipt of a counter-notification that adheres to the aforementioned requirements, Million Recruiters may, at its discretion: 

Forward a copy of the counter-notification to the original notifier, informing them of the potential replacement of the removed material or the cessation of its disablement within 10 business days. 
Replace the removed material or restore access to it within 10 to 14 business days (or more, at the Company's discretion), unless the copyright owner initiates a court action against the content provider accused of infringement. 
Please contact Million Recruiters' Designated Agent at: office@millionrecruiters.com 

 

### 7. Disclaimer of Liability for User-Generated Content and Third-Party Links: 

User-Generated Content: 

**Exclusive Responsibility of Originators:** All information and Content, whether publicly posted or privately transmitted through the Services, is solely the responsibility of the individuals from whom such Content originates. 
Access at Your Own Risk: You access all such information and Content at your own risk. Million Recruiters bears no liability for any errors, omissions, damages, or losses you may encounter in connection with such Content. 
No Duty to Monitor or Control: Million Recruiters does not control and has no duty to take action regarding how you interpret, use, or act upon Content accessed through the Services. You hereby release Million Recruiters from all liability stemming from your acquisition or non-acquisition of Content through the Services. 
Identity of Users: Million Recruiters cannot guarantee the identity of any users you interact with and is not responsible for controlling which users gain access to the Services. 
Your Responsibility for Your Contributions: You are solely responsible for all Content you contribute to the Services, and you warrant that you possess all necessary rights to do so in the manner in which you contribute it. 
Third-Party Links and Services: 

**Access at Your Own Risk:** The Services may contain links or connections to third-party websites or services that Million Recruiters does not own or control. You acknowledge and accept that accessing third-party websites or services involves inherent risks for which Million Recruiters is not responsible. 
No Control or Responsibility: Million Recruiters has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, practices, or opinions expressed on third-party websites or by third parties you interact with through the Services. 
No Monitoring or Editing of Third-Party Content: Million Recruiters does not monitor, verify, censor, or edit the content of any third-party site or service. 
Encouraged Review of Third-Party Terms: You are strongly encouraged to be aware when leaving the Services and to carefully review the terms and conditions and privacy policies of each third-party website or service you visit or utilize. 
Release of Liability: By using the Services, you irrevocably release and hold Million Recruiters harmless from any and all liability arising from your use of any third-party website or service. 
Disputes Between Users: 

**No Obligation to Intervene:** Million Recruiters is under no obligation to become involved in disputes between participants on the Services or between users and third parties. 
Release of Claims: In the event of a dispute with one or more other users, you release Million Recruiters, its directors, officers, employees, agents, and successors from all claims, demands, and damages of any kind, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or related to such disputes and/or the Services. 
Waiver of California Civil Code Section 1542: You hereby waive California Civil Code Section 1542, or any similar law of any jurisdiction, which states that a general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in their favor at the time of executing the release, which, if known, would have materially affected their settlement with the debtor or released party. 
 

### 8. Service Modifications and Content Removals 

**Evolving Services:**
Million Recruiters is committed to continuous improvement, and the Services may undergo changes over time. This includes the potential for: 

**Suspension or Discontinuation:** Any portion of the Services may be suspended or discontinued entirely. 
New Features and Limits: Introduction of new features or modification of existing ones, along with the possibility of imposing limitations on specific features. 
Access Restrictions: Restriction or revocation of access to certain parts or the entirety of the Services. 
Content Removal: 

We reserve the absolute right to remove any Content from the Services at any time, without prior notice. This applies to all situations, including but not limited to: 

**Violation of Terms:** If we have reason to believe your contribution violates these Terms. 
Discretionary Decisions: Based on our sole judgment and discretion, regardless of the reason. 
This right to remove Content is fundamental to our ability to maintain a positive and safe environment for all users. 



### 9. Cessation of Services Usage 

You may voluntarily cease utilization of the Services by notifying the Company through the designated communication channel for Employers or by emailing office@millionrecruiters.com per the Privacy Policy and licenses detailed herein, to understand information treatment after Services cessation. 

The Company reserves discretion to suspend or terminate your Services access or account for any reason, including violation of these Terms and Conditions. The Company solely decides any violation of the restrictions herein; Candidates cannot enforce Terms against Employers. Account termination may lead to Content destruction, so consider that before terminating voluntarily. 

If you mistakenly deleted your account, promptly contact us and we will attempt to recover or restore the account, but cannot guarantee doing so. 

Provisions survive termination where nature dictates, including payment obligations, limitation of liability, intellectual property ownership, and the arbitration clause for dispute resolution. 

 

### 10. Notice Regarding Referral Programs 

Million Recruiters may, from time to time, offer rewards or incentives to those who refer others to the Services ("Referrers"). For details regarding any current referral offers, please refer to our homepage. 

Eligibility: 

Referrers: Individuals or entities who are not current customers of Million Recruiters nor registered users of the Services. 

Referee: Individuals or entities who are neither current customers of Million Recruiters nor registered users of the Services. 

Terms and Conditions: 

Referral Rewards: Referrers shall receive the stated reward or incentive for each Referee who completes the required action specified in the offer (e.g., account signup, purchase). 

Referee Eligibility: Referees must be first-time recipients of the offer. Multiple referrals to the same individual or entity will not be counted. 

Modification or Termination: Million Recruiters reserves the right to modify or terminate any special offers at any time, at its sole discretion. 

Revocation of Offers: Million Recruiters may revoke the special offer from either Referrer or Referee for any reason or no reason whatsoever. 

Violation of Terms: If Million Recruiters determines that Referrer or Referee is attempting to obtain unfair advantage or otherwise violate the terms or spirit of the offer, Million Recruiters may: 

Revoke any rewards or incentives issued to either Referrer or Referee. 

Charge the Referrer or Referee for any rewards or incentives used prior to revocation or issued to ineligible parties. 

Additional Terms: All special offers are subject to any other terms, conditions, and restrictions set forth on the Services or presented in connection with the specific offer. 

 

### 11. Miscellaneous  
**Disclaimer of Warranties:**

Million Recruiters, its licensors, suppliers, partners, parent, subsidiaries, affiliates, and their respective officers, directors, members, employees, consultants, contract employees, representatives, agents, successors, and assigns (collectively, the "Million Recruiters Parties"), make no representations or warranties regarding: 

The Services, including any Content contained in or accessed through them. 

The accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services. 

Any claims, actions, suits procedures, costs, expenses, damages, or liabilities arising from use of or participation in the Services. 

Suggestions or recommendations of services or products offered or purchased through or in connection with the Services, including any Employer Services. 

The Services and Content are provided by Million Recruiters (and its licensors and suppliers) "as-is," without warranties of any kind, express or implied, including implied warranties of merchantability, fitness for a particular purpose, non-infringement, or uninterrupted or error-free use. 

**Limitation of Liability:**

To the fullest extent permitted by law, under no circumstances and under no legal theory (including tort, contract, strict liability, or otherwise) shall any Million Recruiters Party be liable to you or any other person for: 

Any indirect, special, incidental, punitive, or consequential damages of any kind, including damages for lost profits, business interruption, loss of data, loss of goodwill, work stoppage, accuracy of results, or computer failure or malfunction. 

Any substitute goods, services, or technology. 

Any amount in excess of $100 or the amounts paid by you to Million Recruiters in the 12 months preceding the claim. 

Any matter beyond Million Recruiters' reasonable control. 

**Indemnification:**

You agree to indemnify and hold the Million Recruiters Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses, and expenses (including attorneys' fees) arising from or related to: 

Your use of the Services (including actions by third parties using your account). 

Your violation of these Terms. 

**Assignment:**

You may not assign, delegate, or transfer these Terms or your rights or obligations hereunder, or your Services account, without Million Recruiters' prior written consent. Million Recruiters may transfer, assign, or delegate these Terms and its rights and obligations without consent. 

**Choice of Law:**

These Terms are governed by and construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of Delaware, without regard to conflict of laws provisions. 

**Arbitration Agreement:**

Arbitration agreement. Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Million Recruiters and limits the manner in which you can seek relief from Million Recruiters. Both you and Million Recruiters acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, Million Recruiters' officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof. 

1. Arbitration Rules; Applicability of Arbitration Agreement. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in Kent County, Delaware. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. 

2. Costs of Arbitration. The Rules will govern payment of all arbitration fees. Million Recruiters will pay all arbitration fees for claims less than seventy-five thousand ($10,000) dollars. Million Recruiters will not seek its attorneys' fees and costs in arbitration unless the arbitrator determines that your claim is frivolous. 

3. Small Claims Court; Infringement. Either you or Million Recruiters may assert claims, if they qualify, in small claims court in Kent County, Delaware or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights. 

4. Waiver of Jury Trial. YOU AND MILLION RECRUITERS WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Million Recruiters are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and Million Recruiters over whether to vacate or enforce an arbitration award, YOU AND MILLION RECRUITERS WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge. 

5. Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Million Recruiters is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (7) below. 

6. Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following: office@millionrecruiters.com sent within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms' arbitration agreement. 

7. Exclusive Venue. If you send the opt-out notice in (6), and/or in any circumstances where the foregoing arbitration agreement permits either you or Million Recruiters to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing

...
`

  return (
    <>

<BannerMessage heading="Terms of Use" subheading="Last updated: January 14th, 2024" />


<Container className='mb-5'>

  <Row className="g-4">
  <Col className=''>
    <Card>
      <Card.Body><Markdown content={content} /></Card.Body>
    </Card>
    </Col>
  </Row>
</Container>
    </>
  );
};

export default Terms;
