import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Image, Stack, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import { logout } from '../Store/authSlice'; // Import your logout action
import { logoutAsync } from '../Store/authSlice';
import ClientProfile from './ClientProfile';
import RecuiterProfile from './RecuiterProfile';
import { useDispatch, useSelector } from 'react-redux';
import CreateProfile from './CreateProfile';
import Messages from './Messages';
import api, { handleFileUpload } from '../Utils/api';
import { updateUserProfileAsync } from '../Store/authSlice';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner
import { NavLink } from 'react-router-dom';
import Avatar from '../Widgets/Avatar';

function WelcomeMessageSide() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [greeting, setGreeting] = useState('');
  const [time, setTime] = useState('');

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);


  const user = useSelector((state) => state.auth.user);
  const status = useSelector((state) => state.auth.status);
  // const profile = useSelector((state) => state.auth.profile);
  // const persona = useSelector((state) => state.auth.persona);

  useEffect(() => {
    const currentHour = new Date().getHours();
    // Determine the time of day and set the greeting accordingly
    if (currentHour >= 5 && currentHour < 12) {
      setGreeting('Good morning');
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }

    // Format the current date
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    setTime(currentDate);

  }, []);


  const logout = async () => {
    await dispatch(logoutAsync({}));
    history.push('/');
    return
  };

  const handleInputChange = async (e) => {
    e.preventDefault();
  try {
    const { files } = e.target;
    const newValue = files[0]
    const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB

  

    if (newValue.size > maxSizeInBytes) {
      console.log('File size exceeds the maximum allowed size (5MB).');
      return
    }



    // setError(null)
    setUploading(true)


      const response = await handleFileUpload(newValue)
      const updatedProfile = { ...user.profile, avatar: response };
      await dispatch(updateUserProfileAsync({ profile: updatedProfile }));

      setUploading(false)

    }
    catch (error) {
      setUploading(false)
      setError("failed to upload image")
      return
    }


  }



  return (


    <div >

      <Stack direction="horizontal" gap={0} className='profile-card' >
        {user && (
          <div className=''>
            <Container>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <div className="p-3">
                    <div style={{ position: "relative" }} className=' text-start'>


                      <Avatar
                        style={{ width: "195px", height: "195px", borderRadius: "5%", fontSize: "24px" }}
                        src={user.profile.avatar}
                        name={user.profile.name}
                      />

                      <label className="custom-file-upload">

                        <input
                          type="file"
                          className="form-control text-black bg-white shadow-none border-1"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleInputChange}
                        />


                        {uploading ?
                          <Spinner style={{ position: "fixed", top: "230px", left: "190px", zIndex: "10" }} className=" bg-light text-muted small rounded-circle p-2"></Spinner> :
                          <span style={{ position: "fixed", top: "230px", left: "190px", zIndex: "10" }} className=" la la-edit bg-light text-muted small rounded-circle p-2"></span>
                        }



                      </label>
                    </div>

                    <Stack direction="vertical" className='mt-3 text-start' gap={0}>
                      <div className='p-0 h-0'><small>{time}</small></div>
                      <div className='p-0 h-0 '>
                        <h5 className='m-0 p-0'>{greeting},<br />{user.profile.name} 
                        {/* <a className="text-dark" href="#" onClick={(e) => { e.preventDefault(); logout() }}><i className='las la-sign-out-alt me-2'></i></a> */}
                        </h5>
                        <div className='text-muted'>You are signed in as a {user.type}</div>
                        {/* <div className='mb-1'><NavLink className="text-decoration-underline text-black" to="/profile">Edit Profile</NavLink></div> */}
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {/* {user && (
          <div className='bg-white shadow-sm mb-3 rounded-2'>
            <Container>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <div className="p-3">

                    <i style={{ fontSize: "70px", width: "100px" }} className='text-center bg-dark text-white rounded p-3 la la-user'></i>

                    <Stack direction="vertical" className='mt-3' gap={0}>
                      <div className='p-0 h-0'><small>{time}</small></div>
                      <div className='p-0 h-0'>
                        <h5 className='m-0 p-0'>{greeting}  <a className="text-dark" href="#" onClick={(e) => { e.preventDefault(); logout() }}><i className='las la-sign-out-alt me-2'></i></a></h5>

                        <div className='text-muted'>You are signed in as a {user.type}</div>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )} */}

      </Stack>

      <Stack direction="horizontal" gap={0} className='profile-card-small' >
      <Avatar
                        style={{ width: "100%", height: "auto", borderRadius: "20%", fontSize: "24px", padding: "5px 5px" }}
                        src={user.profile.avatar}
                        name={user.profile.name}
                      />
                      </Stack>
    </div>
  );
}

export default WelcomeMessageSide;
