import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import GenericForm from './GenericForm';
import api from '../Utils/api';
import { useHistory } from 'react-router-dom';
import { Modal, Stack, Card, Button, Table, ButtonGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BannerMessage from './BannerMessage';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner
import Client from './Client/Client';
import Markdown from '../Widgets/Markdown';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../Store/authSlice';
import ListCandidates from './Recruiter/ListCandidates';
import CandidateWidget from '../Widgets/CandidateWidget';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CandidatePipeline from './CandidatePipeline';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { setSubmittedData } from '../Store/submitCandidateSlice';

const SubmitCandidate = ({ jobId, onCandidateAdded }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const persona = useSelector((state) => state.auth.persona);
    // const [jobDetails, setJobDetails] = useState(null)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)

    const [showModal, setShowModal] = useState(false);
    const [showModalSmall, setShowModalSmall] = useState(false);
    const user = useSelector((state) => state.auth.user);

    const [tempData, setTempData] = useState({
        error: null,
        stage: '',
        notes: '',
        index: -1,
    });


    const [candidate, setCandidate] = useState(null);



    // Example: When something changes, dispatch the action to update the shared state
    const handleDataChange = async (data) => {
        // Dispatch the action to update the shared state
        console.log("dispatching on candidate change")
        dispatch(setSubmittedData(data));
        console.log("dispatched")
        onCandidateAdded()
    };


    const addCandidateToPipeline = async (candidate) => {
        console.log("Candidate Selected for Job", candidate)
        setCandidate(candidate)
        setShowModal(false)
        setShowModalSmall(true)        

    }




    return (
        <>

<Modal show={showModalSmall} size="md" backdrop="static" keyboard={false} onHide={() => { setShowModalSmall(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                      
</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                    {candidate && (
                            <div>
                        {user && <div className='strong'>Hello {user.profile.name}</div>}
                        <p>Please add a quick note to highlight why <strong>{candidate.name}</strong> will be a great fit for the role!</p>
                            </div>
                        )}
                        <Stack direction='vertical' gap={3}>
                            {tempData.error && (<div className='text-danger'>{tempData.error}</div>)}
                            <textarea type='text'
                                placeholder="Enter notes here..."
                                rows="10"
                                value={tempData.notes}
                                onChange={(e) => setTempData({ ...tempData, notes: e.target.value })}
                                className='form-control text-black bg-white shadow-none border-1 ' />
                            {/* <Button onClick={addMessage} variant='success' className='btn-sm border-0 bg-green'><i className='la la-reply'></i></Button> */}
                            <Button onClick={async (e) => {
                                e.preventDefault()
                                if (!tempData.notes || tempData.notes == "") {
                                    setTempData({ ...tempData, error: "Please provide some notes" })
                                    return
                                }
                                // handleModalSubmit(tempData.notes)


                                var jobCandidate = {
                                    "candidate_id": candidate.id,
                                    "notes": tempData.notes
                                }
                        
                                await api.post(`/jobs/${jobId}/candidates`, jobCandidate)
                                    .then(response => {
                                        const data = response.data;
                                        console.log(data);
                        
                                        setMessage("Your candidate was successfully submitted")
                                        setShowModalSmall(false)
                                        handleDataChange(jobId)
                        
                                    })
                                    .catch(error => {
                                        console.error('Error creating workflow:', error)
                                        setError(error.response.data)
                                    });

                            }} variant='success' className='btn-sm border-0 bg-green'>Submit</Button>

                        </Stack>
                    </div>
                </Modal.Body>
            </Modal>
            
            <Offcanvas show={showModal} placement='end' onHide={() => { setShowModal(false) }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Submit Candidate</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ButtonGroup className='w-100  mb-3'>
                        <Link className=" btn btn-outline-dark" to="/recruiter/candidates">Manage Candidates</Link>
                        <Link className=" btn btn-outline-dark" to="/recruiter/create">Create New Candidates</Link>
                    </ButtonGroup>
                    <ListCandidates onSelect={addCandidateToPipeline} />
                </Offcanvas.Body>
            </Offcanvas>
            <Button onClick={() => { setShowModal(true) }} variant='dark' className="mt-2 w-100">Submit Candidate</Button>

        </>
    );
};

export default SubmitCandidate;
