import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import api from '../Utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LinkedInPage from './LinkedIn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Stack } from 'react-bootstrap';
import BannerMessage from '../App/BannerMessage';
import HomeHero2 from '../Home/HomeHero2';

const LoginOptions = ({type}) => {
    const history = useHistory();

    const [selectedOption, setSelectedOption] = useState('');

    const setOption = async (option) => {
        if(selectedOption == option) {
            setSelectedOption("")
            return
        }
        setSelectedOption(option)
    };

    useEffect(() => {
        console.log(type)

        setSelectedOption(type)
    }, [type]);
    

    return (
        <>




            {/* <h6 className='m-0'>Getting started is easy!</h6>
            <h4 className='fw-bolder'>Choose <span className="text-primary">Account</span> Type:</h4> */}
            <Row className='mt-3 g-4 row-eq-height' style={{ display: "flex"}}>
                {/* <Col xl={12}>
                <HomeHero2 />
                </Col> */}
            <Col xs={12} md={12} lg={4} xl={4} className='' >
                    <div className={selectedOption === "employer" ? "border border-1 text-start border-dark bg-light rounded-3 h-100" : "text-start bg-white h-100 border border-black border-opacity-50 rounded-3"}>
                    <div onClick={(e) => { e.preventDefault(); setOption("employer") }} className="form-check link mb-3 pe-4  text-black text-left align-middle " >
                        {/* <input
                            className="form-check-input link float-end m-3"
                            type="radio"
                            name="flexRadioDefault"
                            id="employerRadio"
                            value="employer"
                            checked={selectedOption === "employer" ? true : false}
                            onChange={(e) => setSelectedOption(e.target.value)}
                        /> */}

                        {/* <div className='pt-4 text-center' style={{ height: "80px" }}>
                            {selectedOption === "employer" ? <i className='la la-check-circle text-success la-4x'></i> : ""}
                        </div> */}
                        <Stack direction='horizontal' gap={3}>
                        <div className=" h5  m-3" htmlFor="employerRadio">
                        I’m a <span className="text-primary">client</span> looking to hire at half the cost of traditional recruitment companies
                        </div>
                        <div>{selectedOption === "employer" ? <i className='la la-check-circle text-success la-2x'></i> : ""}</div>
                        </Stack>


</div>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={4} xl={4} className='' >
                    <div className={selectedOption === "recruiter" ? "border border-1 text-start border-dark bg-light  rounded-3 h-100" : "text-start bg-white h-100 border border-black border-opacity-50 rounded-3"}>
                    <div onClick={(e) => { e.preventDefault(); setOption("recruiter") }} className="form-check link mb-3 pe-4   text-black text-left align-middle " >
                        
                        

                        <Stack direction='horizontal' gap={3}>
                        <div className=" h5  m-3" htmlFor="employerRadio">
                        I’m a <span className="text-primary">recruiter</span> looking to leverage my skillset to earn a big commission
                        </div>
                        <div>{selectedOption === "recruiter" ? <i className='la la-check-circle text-success la-2x'></i> : ""}</div>
                        </Stack>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={4} xl={4} className='' >
                    <div className={selectedOption === "candidate" ? "border border-1 text-start border-dark bg-light  rounded-3 h-100" : "text-start bg-white h-100 border border-black border-opacity-50 rounded-3"}>
                    <div onClick={(e) => { e.preventDefault(); setOption("candidate") }} className="form-check link mb-3 pe-4  text-black text-left align-middle " >
                    <Stack direction='horizontal' gap={3}>
                        <div className=" h5  m-3" htmlFor="employerRadio">
                        I’m a <span className="text-primary">candidate</span> seeking my dream job, confidentially & privately
                        </div>
                        <div>{selectedOption === "candidate" ? <i className='la la-check-circle text-success la-2x'></i> : ""}</div>
                        </Stack>

                      

                    </div></div>


                </Col>



            </Row>

            <Row xs={12} md={12} xl={12}>
                <Col>

                    {selectedOption ? <div className='text-center'> <LinkedInPage selectedOption={selectedOption} /></div> : <></>}
                </Col>
            </Row>

        </>
    );
};

export default LoginOptions;
