import React, { useEffect, useState } from 'react';
import './Home.css'; // Import CSS for styling
import TeamMemberCarousel from './TeamMemberCarousel';
import LinkedInPage from '../Login/LinkedIn.js'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import LinkedInValidate from '../Login/LinkedInValidate.js';
import { Image, Badge, Stack, ButtonGroup, Button } from 'react-bootstrap'
import illustration_1 from '../assets/banner.avif'
import LoginOptions from '../Login/LoginOptions.js';
import Print from '../Common/Print.js';

import hero_block_1 from '../assets/job-hero-block-1.svg'
import hero_block_2 from '../assets/job-hero-block-2.svg'
import hero_block_3 from '../assets/job-hero-block-3.svg'
import photo_1 from '../assets/photo_2.png'

import user_1 from '../assets/user_1.avif'
import user_2 from '../assets/user_2.avif'
import user_3 from '../assets/user_3.avif'
import user_4 from '../assets/user_4.avif'

import img23 from '../assets/img23.jpg'
import BoxAnimation from '../Widgets/BoxAnimation.js';
import HomeSection1 from './HomeSection1.js';
import HomeSection2 from './HomeSection2.js';
import HomeSection1Alternate from './HomeSection1Alternate.js';
import HomeSection3 from './HomeSection3.js';
import HomeHero1 from './HomeHero1.js';
import HomeHero2 from './HomeHero2.js';

import flow from '../assets/flow.png'

function Home() {


  const [selectedOption, setSelectedOption] = useState('');


  return (
    <div className="home" style={{ position: "relative" }} >
      
      {/* <div className='banner'></div> */}

      {/* style={{backgroundColor: "rgb(58, 175, 210)"}} */}
      <div style={{ position: "relative" }} className=' pt-0  bg-info text-dark'>
      <div className='section-bg-top' style={{ zIndex: "0" }}></div>
      <div style={{ position: "relative", zIndex: "1000px" }}>
          <Container>
            <Row className=''>
              <Col md={{ span: 8, offset: 2 }} className='text-center'>
                <div className='mt-5 p-5'>
                  <div className=''>
                    <span className=' lh-1 fs-1' ><span className='highlight'>Million Recruiters</span></span>
                  </div>
                  <div className='fs-6'><b>Half the Cost. Exponential in Reach.</b></div>
                  <div className='mt-4 ms-1 '>
                    <span className='bg-secondary rounded-md-pill shadow rounded-5 mb-4 p-2 ps-3 pe-3 text-white'><strong>Get Started with Zero Fees! <a className='text-white' href="#">Learn More</a></strong></span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }} >
                <div className='mt-0 mb-5'>
                  <HomeHero2 />
                </div>
              </Col>
            </Row>
          </Container>
          </div>
      </div>

      {/* <div className='bg-white text-center' style={{ height: "50px"}}>
  <b>Trusted By:</b>
<TeamMemberCarousel />
</div> */}

      {/* <HomeSection1 /> */}
      {/* <HomeSection1Alternate /> */}



      {/* <Container className=''>
                <Row>
                  <Col className='col-12 mb-2'>



                    <h4 style={{ lineHeight: "1.2em" }} className=' badge  fs-5 w-100 text-start p-4 text-wrap bg-primary'>

                      No SaaS fees, no monthly fees, no initiation fees, no gimmicks. </h4>

                    <h4 style={{ lineHeight: "1.2em" }} className='badge  fs-5 w-100 text-start p-4 text-wrap bg-primary'>The only time you'll ever get an invoice from us is if you hire a Million Recruiters candidate. We then distribute it to the Recruiter.</h4>

                 
                    <h4 style={{ lineHeight: "1.2em" }} className='  badge  fs-5 w-100 text-start p-4 text-wrap bg-primary'>When you're happy (i.e. you've made a hire), the Recruiter gets paid.</h4>
                  </Col>
                </Row>
              </Container> */}

      {/* <div className='bg-white'>
<Container>

<Row className=''>

  <Col md={{ span: 8, offset: 2 }} className='text-center'>

    <div className='mt-3 p-5'>

              <p className=''><span className='highlight fs-4'>No monthly fees, no initiation fees, no gimmicks.</span>
              <br/>The only time you'll ever get an invoice from us is if you hire a Million Recruiters candidate. We then distribute it to the Recruiter. When you're happy (i.e. you've made a hire), the Recruiter gets paid.
              </p>

              
              <ButtonGroup >
                <Button variant="outline-primary">How does it work?</Button>
                <Button variant="outline-primary">Why Million Recruiters?</Button>
                <Button variant="outline-primary">Sign Up</Button>
              </ButtonGroup>

</div>
</Col>
</Row>
</Container>


</div> */}

      <HomeSection2 />

      <HomeSection3 />




      {/* <TeamMemberCarousel /> */}


      <div className='bg-white p-5'>

        <div className='text-center'><span className='bg-white rounded-pill p-2 ps-3 pe-3 mb-4 btn border-primary text-primary'><b>Sign Up Now</b></span></div>
        <Container>
          <Row>
            <Col >
              <div className='text-center'>
              <h6 className='m-0'>Getting started is easy!</h6>
            <h4 className='fw-bolder'>Choose <span className="text-primary">Account</span> Type:</h4>
                <LoginOptions key="home" />
              </div>
            </Col>
          </Row>
        </Container>

      </div>

    </div>
  );
}

export default Home;
