import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
import api from '../Utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import LoginOptions from './LoginOptions';
import BannerMessage from '../App/BannerMessage';

const Login = () => {

    const { type } = useParams();


    // useEffect(() => {
    //     console.log(type)
    // }, [type]);

    return (
        <>
        <BannerMessage heading='Choose <span className="text-primary">Account</span> Type' subheading="Getting started is easy" />

            <Container className='mt-5'>
                <Row>
                    <Col className='shadow-sm bg-white p-5 mb-5 rounded-3  col-md-12 col-lg-10 offset-lg-1 '>
                        <LoginOptions type={type} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Login;
