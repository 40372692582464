import React, { useState, useEffect, useRef } from 'react';
// import './Home.css'; // Import CSS for styling
import Workflows from './Workflows';
import CreateWorkflow from './CreateWorkflow'
import CardBody from 'react-bootstrap/esm/CardBody';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import WelcomeMessage from '../App/WelcomeMessage';
import BannerMessage from '../App/BannerMessage';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

function DashboardWorkflow({ user, profile }) {

  // const [email, setEmail] = useState(null)
  // const [name, setName] = useState(null)
  // const [photo, setPhoto] = useState(null)

  useEffect(() => {
    // // const token = JSON.parse(localStorage.getItem('user'));
    // setEmail(user.email)
    // setName(user.name)
    // setPhoto(user.picture)

    console.log(user)

  }, []);

  return (
    <div className="home">
      <BannerMessage heading="Workflows" subheading="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups." />

      <Container >
        <Row xs={1} md={2} lg={3} xl={4} className="mb-2">
          
          <Col xs={12} md={12} lg={8} xl={8} >

            {/* <div className='bg-white rounded-3 shadow-sm p-3'>
              <Card.Header>
                <Card.Title className='pt-2'>My Workflows</Card.Title>
              </Card.Header>
              <Card.Body>

                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p> */}
                <Workflows />
                {/* </Card.Body>
              <Card.Footer>

              </Card.Footer>
            </div> */}
          </Col>

          <Col>
                  <ListGroup className='mb-3'>
                  <ListGroupItem>Menu Item 1</ListGroupItem>
                  <ListGroupItem>Menu Item 2</ListGroupItem>
                  </ListGroup>
                  <CreateWorkflow />
          </Col>

        </Row>
      </Container>


      {/* <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <CreateWorkflow />
          </div>
        </div>
      </div> */}

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12 p-4">

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardWorkflow;
