import React, { useState, useEffect, useRef } from 'react';
import GenericForm from './GenericForm';

import { Nav, NavItem, NavLink, Offcanvas, Modal, Card, Stack, Row, Container, Col, Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import CreateClient from './Client/CreateClient';

import { useDispatch, useSelector } from 'react-redux';

import api, { handleFileUpload } from '../Utils/api';

import { updateUserProfileAsync } from '../Store/authSlice';

const CreateProfile = () => {
    const dispatch = useDispatch();

    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const user = useSelector((state) => state.auth.user);
    // const persona = useSelector((state) => state.auth.persona);
    // const email = useSelector((state) => state.auth.email);

    
    const [candidateDescription, setCandidateDescription] = useState({})
    const [activeStep, setActiveStep] = useState(null)
    // const profile = useSelector((state) => state.auth.profile) || {};
    const [uploading, setUploading] = useState(false)

    const [clients, setClients] = useState(null)
    const [clientOptions, setClientOptions] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [client, setClient] = useState(null)
    const [steps, setSteps] = useState([])

    const clientCreated = async () => {
        setShowModal(false)
        await getClients();
    }

    const getClients = () => {
        var apiURL = '/clients'
        api.get(apiURL)
            .then((response) => {
                var _clients = []
                var _clientOptions = []
                for (var i in response.data) {
                    var details = response.data[i]
                    // details.Metadata = JSON.parse(details.Metadata)
                    _clients.push(details);
                    _clientOptions.push({
                        "label": details.name + " (" + details.location + ")",
                        "value": details.id
                    })
                }
                console.log(_clientOptions)

                setClients(_clients)
                setClientOptions(_clientOptions || [])
            })
            .catch((error) => {
                setClients([])
                console.error('Error fetching login :', error);
                setError(error.error)
            });
    }
    useEffect(() => {
        if(!user) {
            return
        }

        if (user.type == "employer") {
            getClients()
        }
    }, [])

    useEffect(() => {
        if(user.type == "employer" && clientOptions == null) {
            return
        }


        var steps = []
        steps.push({
            id: 1,
            title: "Overview",
            subtitle: "Tell us a bit more about yourself",
            template: {
                fields: [
                    { name: 'name', label: 'Name', type: 'text', value: user.profile.name || user.metadata.name || "", required: true },
                    { name: 'linkedin_url', label: 'LinkedIn Profile URL', value: user.profile.linkedin_url, type: 'text', required: true },
                    { name: 'phone', label: 'Phone Number', type: 'text', value: user.profile.phone, required: true },
                    { name: 'email', label: 'Email', type: 'text', value: user.profile.email || user.email, required: true },
                    { name: 'role', label: 'Role', type: 'text', value: user.profile.role, required: true },
                    { name: 'location', label: 'Location', type: 'text', value: user.profile.location,  required: true },
                ],
                button: {
                    label: "Next",
                    variant: "dark"
                },
            },
            onSubmit: async function (formData) {
                if(user.profile.avatar != "") {
                    formData.avatar = user.profile.avatar
                }
                else if(user != null && user.metadata) {
                    formData.avatar = user.metadata.picture
                }
    
                setCandidateDescription({ ...candidateDescription, ...formData });
                
                setActiveStep(2)
            }
        })
    
        if (user.type == "candidate") {
            steps.push({
                id: 2,
                title: "Bio",
                subtitle: "More details about the candidate",
                template: {
                    fields: [
                        { name: 'resume_file', label: 'Upload Resume', type: 'file', required: false },
                        { name: 'bio', label: 'Bio', type: 'textarea', required: true },
                        {
                            name: 'salary_type', label: 'Salary Type', type: 'select', options: [
                                {
                                    "label": "Hourly",
                                    "value": "hourly"
                                },
                                {
                                    "label": "Yearly",
                                    "value": "yearly"
                                },
                            ], required: true
                        },
                        { name: 'min_salary', label: 'Minium Salary in USD', type: 'text', required: true },
                        { name: 'max_salary', label: 'Maximum Salary in USD', type: 'text', required: true },
                        {
                            name: 'sponsorship_required', label: 'Does candidate require sponsorship?', type: 'select', options: [
                                {
                                    "label": "Yes",
                                    "value": "yes"
                                },
                                {
                                    "label": "No",
                                    "value": "no"
                                },
                                {
                                    "label": "Not Sure",
                                    "value": "not_sure"
                                }
                            ], required: true
                        },
                    ],
                    button: {
                        label: "Next",
                        variant: "primary"
                    },
                },
                onSubmit: async function (formData) {
                    setUploading(true)
    
                    if (formData.resume_file) {
                        try {
                            const response = await handleFileUpload(formData.resume_file)
                            console.log(response)
                            formData.resume = response
                        }
                        catch (error) {
                            setError(error)
                            return
                        }
                    }
    
                    setCandidateDescription({ ...candidateDescription, ...formData });
                    setActiveStep(3)
                }
            })
        }
    
        if (user.type == "recruiter") {
            steps.push({
                id: 2,
                title: "Recruiting History",
                subtitle: "Please list atleast 2 roles you have filled by including company name, linkedin or website, funding information and role title. Feel free to eloborate on each role and experience recuriting for them.",
                template: {
                    fields: [
                        { name: 'history', label: 'Recruiting History',  value: user.profile.history, type: 'textarea', required: true },
                    ],
                    button: {
                        label: "Next",
                        variant: "dark"
                    },
                },
                onSubmit: async function (formData) {
                    setCandidateDescription({ ...candidateDescription, ...formData });
                    setActiveStep(3)
                }
            })
        }
    
        if (user.type == "employer") {
            steps.push({
                id: 2,
                title: "Company Details",
                subtitle: "What is the name of the company your represent?",
                template: {
                    fields: [
                        { name: 'company', label: 'Select Company', type: 'select', value: user.profile.company, options: clientOptions || [], required: true },
                        {
                            name: '', label: "<a class='text-decoration-underline' href='#'>Don't see your company? Add New</a>", type: 'link', onClick: function (event) {
                                event.preventDefault();
                                setShowModal(true)
                            }
                        }
                    ],
                    button: {
                        label: "Submit",
                        variant: "dark"
                    },
                },
                onSubmit: function (formData) {
                    console.log(formData)
                    setCandidateDescription({ ...candidateDescription, ...formData });
    
                    setClient(formData.company)
                    setActiveStep(3)
                }
            })
        }
    
        if (user.type == "recruiter") {
            steps.push({
                id: 3,
                title: "Submit",
                subtitle: "Fine Print",
                template: {
                    fields: [
                        { name: 'terms', label: 'By clicking, you acknowledge that you have read and understood, and agree to Million Recruiters <a className="text-decoration-underline" href="/#/terms">Terms & Conditions</a> and <a className="text-decoration-underline" href="/#/privacy">Privacy Policy</a> and <a href="/#/agreement" className="text-decoration-underline">Recruiting Agreement</a>.', type: 'checkbox', required: true },
                        // { name: 'agreement', label: 'By clicking, you acknowledge that you have read and understood Million Recruiters Recruiting Agreement.', type: 'checkbox', required: true },
                    ],
                    button: {
                        label: "Update Profile",
                        variant: "success"
                    },
                },
                onSubmit: async function (formData) {
                    setCandidateDescription({ ...candidateDescription, ...formData });
                    await dispatch(updateUserProfileAsync({ profile: candidateDescription }));
                }
            })
        } else {
            steps.push({
                id: 3,
                title: "Submit",
                subtitle: "Fine Print",
                template: {
                    fields: [
                        { name: 'terms', label: 'By clicking, you acknowledge that you have read and understood, and agree to Million Recruiters <a className="text-decoration-underline" href="/#/terms">Terms & Conditions</a> and <a className="text-decoration-underline" href="/#/privacy">Privacy Policy</a>.', type: 'checkbox', required: true },
                        // { name: 'agreement', label: 'By clicking, you acknowledge that you have read and understood Million Recruiters Recruiting Agreement.', type: 'checkbox', required: true },
                    ],
                    button: {
                        label: "Update Profile",
                        variant: "success"
                    },
                },
                onSubmit: async function (formData) {
                    setCandidateDescription({ ...candidateDescription, ...formData });
                    console.log(candidateDescription)
                    await dispatch(updateUserProfileAsync({ profile: candidateDescription }));
                    setMessage("Profile successfully updated")
                    setActiveStep(1)
                }
            })
        }
    
        setSteps(steps)

        if(activeStep == null) {
            setActiveStep(1)

        }
        
    }, [clientOptions, candidateDescription]);



    // const stepsd = [
    //     {
    //         id: 0,
    //         title: "Profile Image",
    //         subtitle: "A picture is worth a thousand words",
    //         template: {
    //             fields: [
    //                 { name: 'profile_image_file', label: 'Profile Image', type: 'file', required: true },
    //             ],
    //             button: {
    //                 label: "Next",
    //                 variant: "primary"
    //             },
    //         },
    //         onSubmit: async function (formData) {
    //             setUploading(true)

    //             try {
    //                 const response = await handleFileUpload(formData.profile_image_file)
    //                 formData.profile_image = response
    //             }
    //             catch (error) {
    //                 setError("failed to upload image")
    //                 return
    //             }
    //             setCandidateDescription({ ...candidateDescription, ...formData });
    //             setActiveStep(1)
    //         }
    //     },
    //     {
    //         id: 1,
    //         title: "Overview",
    //         subtitle: "More details about the candidate",
    //         template: {
    //             fields: [
    //                 { name: 'name', label: 'Name', type: 'text', required: true },
    //                 { name: 'linkedin_url', label: 'LinkedIn Profile URL', type: 'text', required: true },
    //                 { name: 'phone', label: 'Phone Number', type: 'text', required: true },
    //                 { name: 'email', label: 'Email', type: 'text', required: true },
    //                 { name: 'role', label: 'Role', type: 'text', required: true },
    //                 { name: 'location', label: 'Location', type: 'text', required: true },
    //             ],
    //             button: {
    //                 label: "Next",
    //                 variant: "primary"
    //             },
    //         },
    //         onSubmit: async function (formData) {
    //             setCandidateDescription({ ...candidateDescription, ...formData });
    //             setActiveStep(2)
    //         }
    //     },
    //     {
    //         id: 2,
    //         title: "Bio",
    //         subtitle: "More details about the candidate",
    //         template: {
    //             fields: [
    //                 { name: 'resume_file', label: 'Upload Resume', type: 'file', required: true },
    //                 { name: 'bio', label: 'Bio', type: 'textarea', required: true },
    //                 {
    //                     name: 'salary_type', label: 'Salary Type', type: 'select', options: [
    //                         {
    //                             "label": "Hourly",
    //                             "value": "hourly"
    //                         },
    //                         {
    //                             "label": "Yearly",
    //                             "value": "yearly"
    //                         },
    //                     ], required: true
    //                 },
    //                 { name: 'min_salary', label: 'Minium Salary in USD', type: 'text', required: true },
    //                 { name: 'max_salary', label: 'Maximum Salary in USD', type: 'text', required: true },
    //                 {
    //                     name: 'sponsorship_required', label: 'Does candidate require sponsorship?', type: 'select', options: [
    //                         {
    //                             "label": "Yes",
    //                             "value": "yes"
    //                         },
    //                         {
    //                             "label": "No",
    //                             "value": "no"
    //                         },
    //                         {
    //                             "label": "Not Sure",
    //                             "value": "not_sure"
    //                         }
    //                     ], required: true
    //                 },
    //             ],
    //             button: {
    //                 label: "Next",
    //                 variant: "primary"
    //             },
    //         },
    //         onSubmit: async function (formData) {
    //             setUploading(true)

    //             try {
    //                 const response = await handleFileUpload(formData.resume_file)
    //                 formData.resume = response
    //             }
    //             catch (error) {
    //                 setError(error)
    //                 return
    //             }

    //             setCandidateDescription({ ...candidateDescription, ...formData });
    //             setActiveStep(3)
    //         }
    //     },
    //     {
    //         id: 3,
    //         title: "Visa",
    //         subtitle: "Sponsorship details",
    //         template: {
    //             fields: [


    //             ],
    //             button: {
    //                 label: "Next",
    //                 variant: "primary"
    //             },
    //         },
    //         onSubmit: function (formData) {
    //             setCandidateDescription({ ...candidateDescription, ...formData });
    //             setActiveStep(4)
    //         }
    //     },
    //     {
    //         id: 4,
    //         title: "Submit",
    //         subtitle: "",
    //         template: {
    //             fields: [
    //                 { name: 'terms', label: 'By clicking, you acknowledge that you have read and understood, and agree to MR Terms & Conditions  and Privacy Policy  and  Recruiting Agreement.', type: 'checkbox', required: true },
    //                 { name: 'agreement', label: 'By clicking, you acknowledge that you have read and understood MR Recruiting Agreement.', type: 'checkbox', required: true },
    //             ],
    //             button: {
    //                 label: "Submit Job",
    //                 variant: "primary"
    //             },
    //         },
    //         onSubmit: function (formData) {
    //             setCandidateDescription({ ...candidateDescription, ...formData });

    //             api.post(`/candidates`, candidateDescription)
    //                 .then(response => {
    //                     const data = response.data;
    //                     console.log(data);
    //                     setCandidateDescription({})
    //                     setActiveStep(1)
    //                     setMessage("Your candidate was successfully created")
    //                 })
    //                 .catch(error => {
    //                     console.error('Error creating workflow:', error)
    //                     setError(error.response.data)
    //                 });
    //         }
    //     }
    // ]

    // persona


    return (
        <>

            <Offcanvas show={showModal} placement='end' backdrop="static" keyboard={false} onHide={() => { setShowModal(false) }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>New Client</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CreateClient onCreate={clientCreated} />
                </Offcanvas.Body>
            </Offcanvas>


            <Container className='mt-3'>
                {/* <Row className=''>
                    <Col>
                       
                    </Col>
                </Row> */}

                <Row className="g-4">
                    <Col xs={12} md={12} lg={12} xl={12}>
                        <Nav variant="underline" defaultActiveKey="/home" className='mb-3'>
                            {steps.map((step) => (
                                <Nav.Item onClick={() => { 
                                    if (activeStep < step.id) {
                                        return
                                    }
                                    setActiveStep(step.id) }} >
                                    <NavLink className={`ms-2 me-auto text-center w-100 text-center ${activeStep == step.id ? 'active' : ''} `} >
                                        <div className="fw-bold">{step.title}</div>
                                    </NavLink>
                                </Nav.Item>

                            ))}
                        </Nav>
                        <Card>
                        {message && (
                            <div className='alert alert-success mb-2'><b>{message}</b></div>
                        )}

                        {error && (
                            <div className='alert alert-danger mb-2'><b>{error}</b></div>
                        )}
                        
                                {steps.map((step) => (
                                    <div className={activeStep == step.id ? 'active' : 'hidden'} key={step.id}>
                                    <Card.Header><b>{step.subtitle}</b></Card.Header>
                                    <Card.Body>
                                    <Stack direction="vertical" gap={3} className={activeStep == step.id ? 'active' : 'hidden'} key={step.id}>
                                        <GenericForm template={step.template} onSubmit={step.onSubmit} />
                                    </Stack>
                                    </Card.Body>
                                    </div>
                                ))}
                            

                        </Card>
                    </Col>
                </Row>


            </Container>
        </>
    );
};

export default CreateProfile;
