import React from 'react';
import { Stack, Image, Badge } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import DateTime from './DateTime';

const JobInfoCard = ({ job, as, onClick }) => {
    const history = useHistory();


    return (
        <div>

            <Stack direction='horizontal' gap={3}>
                <div>
                    <div className={as} style={{ cursor: "pointer" }} onClick={(e) => {
                        e.preventDefault()
                        if (onClick) {
                            onClick(job)
                        }
                        else {
                            history.push(`/jobs/${job.id}`)
                        }
                    }}>
                        {job.client && job.client.image != "" && (<div className='ms-auto'><Image src={job.client.image} rounded style={{ width: "50px", height: "50px" }} className='mb-2' /></div>)}
                        {job.client && job.client.name != "" && (<div className='small'>{job.client.name}</div>)}
                        <h6 className='p-0 m-0'><strong>{job.title}</strong></h6>
                    </div>

                    <div className=''>
                        <div className='mt-1 small'>
                            {job.location ? <span className='pe-2'>{job.location}</span> : <span className='pe-2'>NA</span>}
                            {job.job_type && (<span className='border-start border-end ps-2 pe-2'>{job.job_type}</span>)}
                            {job.role_type && (<span className='ps-2 pe-2'>{job.role_type}</span>)}

                        </div>
                        <div>${job.min_salary} - ${job.max_salary} / {job.salary_type}</div>
                        <div className='mt-2'>
                            {job.skills && job.skills.split(",").map((tag) => (
                                // <span key={tag} className="badge bg-white border border-info text-dark me-2 mb-1">
                                //     {tag}
                                // </span>
                                <Badge className='badge bg-white border border-info text-dark me-2 mb-1'>{tag}</Badge>

                            ))}
                        </div>

                        <div className='small text-muted'>Posted <DateTime timestamp={job.created_at} /></div>
                    </div>
                </div>
            </Stack>
        </div>

    );
};

export default JobInfoCard;
