import React from 'react';

import ReactDOM from 'react-dom/client';

import Blog from './Blog/Blog';
import Home from './Home/Home';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';

import NavbarTop from './Common/NavbarTop';
import Footer from './Common/Footer';
import WorkflowDetails from './Workflow/WorkflowDetails';
import Workflows from './Workflow/Workflows';
import DashboardWorkflow from './Workflow/DashboardWorkflow';
import Dashboard from './App/Dashboard';
import AdminPage from './Admin/AdminPage';
import CreateJob from './App/Client/CreateJob';
import JobDetails from './App/JobDetails';
import Login from './Login/Login';
import { useSelector } from 'react-redux';
import AuthenticatedRoute from './AuthenticatedRoute';
import './theme.scss';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';


import store from './Store/Store';
import Privacy from './Pages/Privacy';
import AboutUs from './Pages/AboutUs';
import CreateCandidate from './App/Recruiter/CreateCandidate';
import Candidates from './App/Recruiter/Candidates';
import CandidateDetails from './App/Recruiter/CandidateDetails';
import ReviewCandidates from './App/ReviewCandidates';
import logo from './assets/logo_1.png'
import { Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import CandidateProfile from './App/Candidate/CandidateProfile';
import WelcomeMessageSide from './App/WelcomeMessageSide';
import NavbarSide from './Common/NavbarSide';
import Content from './Content';
import AppInitializer from './AppInitializer'; // Import the AppInitializer

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}

    <Router>
    <AppInitializer /> {/* Add AppInitializer to the component tree */}

      {/* <NavbarSide /> */}
      <Content />


    </Router>

    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


