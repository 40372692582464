import React, { useState, useEffect, Link, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import api from '../Utils/api';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner
import { Button, Stack, Modal, Row, Col, Card } from 'react-bootstrap';
import GenericForm from '../App/GenericForm';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync } from '../Store/authSlice'; // Import your actions
import login_btn from '../assets/login_btn.png'

function LinkedInPage({ selectedOption }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  // const [code, setCode] = React.useState(null);
  const [error, setError] = React.useState("");
  const [localError, setLocalError] = React.useState("");

  // const initialized = useRef(false);

  const [loading, setLoading] = useState(false)
  // const [loggedIn, setLoggedIn] = useState(null)
  // const [token, setToken] = useState(null)
  // const [email, setEmail] = useState(null)
  // const [name, setName] = useState(null)
  // const [photo, setPhoto] = useState(null)


  const template = {
    fields: [
      { name: 'email', id: "email", label: 'Email or Username', type: 'text', required: true },
      { name: 'password', id: "password", label: 'Password', type: 'password', required: true },
    ],
    button: {
      label: "Sign In",
      variant: "primary"
    },
  };


  const handleLocalLogin = async (formData, error) => {
    console.log('Form data submitted:', formData);
    try {
      await dispatch(loginAsync({
        email: formData.email,
        password: formData.password,
        type: selectedOption,
      }, "local"));

      history.push('/');
    } catch (error) {
      setLocalError(error);
    }
  };


  const linkedInLogin = (e) => {
    e.preventDefault()

    console.log(selectedOption)
    setLoading(true)
    api.get('/linkedin/login?profile=' + selectedOption)
      .then((response) => {
        localStorage.setItem("state", response.data.state)
        window.location.href = response.data.url
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error fetching login :', error);
      });
  }


  return (
    <div className='mt-2 text-start'>

      <Modal show={showModal} size="sm" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {localError && <div className='mb-2 p-1 text-danger strong'><b>{localError}</b></div>}
          <GenericForm template={template} onSubmit={handleLocalLogin} />
        </Modal.Body>
      </Modal>

      {error && <div className='alert p-1 alert-danger'>{error}</div>}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          <div>
            {/* <h5 className='text-capitalize'>{selectedOption} Login</h5> */}

            <div className='ms-auto me-auto p-3 text-center' >

              <a href="#" onClick={linkedInLogin} className=''>
                <img src={login_btn} style={{ height: "70px" }} />
              </a>

              <div className='mt-3 text-decoration-underline'><a href="#"  size="sm" onClick={(e) => { e.preventDefault(); setShowModal(true) }}>Use Local Login</a></div>
            </div>
            <p className='alert alert-warning mt-2 text-muted small'>By clicking "Sign In with LinkedIn" above, you acknowledge that you have read and understood, and agree to Million Recruiters <a href="/#/terms" className='text-decoration-underline'>Terms & Conditions</a> and <a href="/#/privacy" className='text-decoration-underline'>Privacy Policy</a> { selectedOption == "recruiter" && <>and <a className='text-decoration-underline' href="/#/agreement">Recruiting Agreement</a></>} .</p>
          </div>

        </div>
      )}

    </div>
  );
};

export default LinkedInPage;


