import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import CardBody from 'react-bootstrap/esm/CardBody';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { CardHeader, CardTitle, ListGroup, ListGroupItem, Stack } from 'react-bootstrap';
import CreateJob from './CreateJob';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import JobPosts from '../JobPosts';
import illustration_1 from '../../assets/recruiter.avif'
import WelcomeMessage from '../WelcomeMessage';
import api from '../../Utils/api';
import BannerMessage from '../BannerMessage';
import Print from '../../Common/Print';
import ClientProfile from '../ClientProfile';
import { useDispatch, useSelector } from 'react-redux';
import Messages from '../Messages';
import WelcomeMessageSide from '../WelcomeMessageSide';
import Client from './Client';
import JobPostsCards from '../JobPostsCards';
import SupportCard from '../../SupportCard';

function DashboardClient() {
    const [showModal, setShowModal] = useState(false);

    const user = useSelector((state) => state.auth.user);
    // const profile = useSelector((state) => state.auth.profile);


    return (
        <>
            {/* <Modal show={showModal} size="xl" backdrop="static" keyboard={false} onHide={() => { setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit My Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientProfile user={user} profile={profile} />
                </Modal.Body>
            </Modal> */}

            <BannerMessage heading="Client Dashboard" subheading="Amplify your quality and reach by leveraging the power of everyone, everywhere" 
            menu={ user.type == "employer" ? <a  className="btn btn-primary floating_button" href="/#/employer/create"><i className='la la-plus la-1x'></i></a> : ""}
            />

            <Container className=' mb-3'>

                <Row className='display-flex h-100'>



                    <Col xs={12} md={12} lg={12} xl={12} >
                        <Row xs={1} md={1} lg={1} xl={1}>

                            <Col >
                                {/* <p className='m-0 p-0'><b>Please see all posted roles below</b></p> */}
                                <div className=''>

                                    <JobPostsCards />
                                </div>

                                {/* <h5 className='m-0 p-0'>Manage Postings</h5> */}


                            </Col>


                        </Row>
                    </Col>

                    {/* <Col className='' xs={12} md={12} lg={4} xl={3} >
                        <div className='mb-2'>
                            {user.profile.company && (<Client id={user.profile.company} />) }
                        
                        </div>

                        
                    </Col>
 */}


                    {/* <Col xs={12} md={5} lg={2} xl={2} className=''>
                    <SupportCard /> */}
                    {/* <ListGroup >
<ListGroupItem>Post Job</ListGroupItem>
<ListGroupItem>
                <Stack direction="vertical" gap={1} className=' '>
                    <Stack direction="horizontal" gap={3} >
                    <div className='h1 m-0 p-0 text-center'><i className='fi fi-rr-edit '></i></div>
                    <div>
                        <p>I’d like to use the automated job posting workflow (less than 3 minutes)..</p>
                    </div>
                    </Stack>
                    <Link className="rounded-2 btn  btn-secondary text-white w-100" to="/employer/create">Post Job</Link>
                </Stack>
                </ListGroupItem>
                <ListGroupItem>
                <Stack direction="vertical" gap={1} className=''>
                    <Stack direction="horizontal" gap={3} >
                    <div className='h1 m-0 p-0 text-center'><i className='fi fi-rr-calendar '></i></div>
                    <div>
                        <p>I’d like to be contacted by a company representative. Click below to setup chat</p>
                    </div>
                    </Stack>
                    <a target="_blank" className="rounded-2 btn btn-primary text-white w-100" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3Kl_qY76FNj_-p8wzHzKb-0Uqb142xLfhvfzFS34Krz--iDZDDp-StOo3PEAKAUQ_vinlvVerf">Calendly</a>

                </Stack>
                </ListGroupItem>
                </ListGroup>

               
                 */}


                    {/* </Col> */}


                    {/* <Col xs={12} md={12} lg={4} xl={3} className='' >
                    
                </Col> */}

                </Row>






            </Container>
        </>
    );
}

export default DashboardClient;
