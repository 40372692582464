import React, { useState, useEffect, useRef } from 'react';
import GenericForm from './GenericForm';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, ListGroup, ListGroupItem, Card, Alert } from 'react-bootstrap';
import api from '../Utils/api';
import { Link } from 'react-router-dom';
import TruncatedDescription from '../Widgets/TruncatedDescription';
import CandidatePipeline from './CandidatePipeline';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../Store/dataSlice';
import Client from './Client/Client'

const JobCompensation = () => {
    useEffect(() => {
    }, []);


    return (
        <Card>

<Card.Header className=''><b>Earn Comission</b></Card.Header>
<Card.Body>
                                        Earn comission based on the stage your candidate is in.

                                        <Container className='p-0'>
                                            <Row className='border-bottom border-top'>
                                                <Col>Hired</Col>
                                                <Col>Undisclosed</Col>
                                            </Row>

                                            <Row className='border-bottom'>
                                                <Col>Interview</Col>
                                                <Col>Undisclosed</Col>
                                            </Row>

                                            <Row className='border-bottom'>
                                                <Col>Submission</Col>
                                                <Col>Undisclosed</Col>
                                            </Row>
                                        </Container>
                                        </Card.Body>
        </Card>
    );
};

export default JobCompensation;
