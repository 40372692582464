import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Stack } from 'react-bootstrap';
import { NavDropdown, Navbar, Form, Button, Container, Row, Col, Nav, Spinner } from 'react-bootstrap';
import WelcomeMessage from '../App/WelcomeMessage';
import LinkedInValidate from '../Login/LinkedInValidate';
import logo from '../assets/logo_1.png'
import { useDispatch, useSelector } from 'react-redux';


function NavbarTop() {
  const history = useHistory();
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.auth.loading);


  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const jwtToken = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const profile = useSelector((state) => state.auth.profile);
  const persona = useSelector((state) => state.auth.persona);
  const status = useSelector((state) => state.auth.status);
  const urlParams = new URLSearchParams(window.location.search);


  useEffect(() => {

  }, []);



  return (
    // shadow-sm border rounded-5
    <div id="topnav" className={loggedIn ? 'bg-white hidden' : 'bg-white border-bottom'} >
      <Container className=''>

        <Navbar  expand="lg" className="">
          <Container >
            <Navbar.Brand href="#"><img src={logo} style={{ height: "30px" }} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <Nav.Link href="/#">Home</Nav.Link>
                <Nav.Link href="/#/blog">Blog</Nav.Link>
                {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#" disabled>
                  Link
                </Nav.Link> */}
              </Nav>
              <Form className="d-flex  d-lg-none d-block">
                <Nav className=''>
                  <Nav.Link href="/#/login">Login</Nav.Link>
                </Nav>
                </Form>
              <Form className="d-flex d-none d-lg-block">
                <Nav className=''>
                  <Nav.Link href="/#/login/employer" className='border-end'>Post a Role</Nav.Link>
                  <Nav.Link href="/#/login">Login</Nav.Link>
                  <Button variant='btn-outline-dark' className='btn btn-outline-dark rounded-pill ps-3 pe-3 btn-sm' style={{ lineHeight: "30px"}} href="/#/login/recruiter">Start Recruiting</Button>
                </Nav>
                {/* {loading ?
                  <div className="text-center mt-2">
                    <Spinner animation="border" size="md" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div> : ""}
                {loggedIn ? <WelcomeMessage user={user} profile={profile} persona={persona} /> : <>
                  <>
                    <Link className=" nav-link border-end" to="/login">Post a Role</Link>
                    <Link className=" nav-link" to="/login">Login</Link>
                    <Link className="bg-secondary text-white rounded-5 me-2 m-0 nav-link" to="/login">Start Recruiting</Link>
                    <LinkedInValidate />
                  </>

                </>} */}
              </Form>
              <LinkedInValidate />
            </Navbar.Collapse>
          </Container>
        </Navbar>



        {/* <Nav style={{ height: "50px", overflow: "hidden" }}
          className={loggedIn ? 'navbar  navbar-expand-lg  bg-white container   p-2' : 'shadow-sm   navbar navbar-expand-lg  bg-white container '}
        >
          <div className="container-fluid p-0">
            <Link className="p-0 nav-link navbar-brand" to="/" href="#"><span className=''><img src={logo} style={{ height: "30px" }} /></span></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink exact className="nav-link" to="/">{loggedIn ? <div>Dashboard</div> : "Home"}</NavLink>
                </li>
                {loggedIn && (
                  <>
                    {persona == "candidate" && (
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/candidate/profile">My Candidate Profile</NavLink>
                        </li>
                      </>
                    )}
                    {persona == "recruiter" && (
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/recruiter/candidates">Candidates</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/jobs/-/candidates/review">Review Submissions</NavLink>
                        </li>
                      </>
                    )}
                    {persona == "employer" && (
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/employer/create">Post Job</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/jobs/-/candidates/review">Review Submissions</NavLink>
                        </li>
                      </>
                    )}
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/admin">Admin</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/workflows">Workflows</NavLink>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/blog">Blog</NavLink>
                </li>

              </ul>
            </div>
            <form className="d-flex" role="search">
              {loading ?
                <div className="text-center mt-2">
                  <Spinner animation="border" size="md" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div> : ""}
              {loggedIn ? <WelcomeMessage user={user} profile={profile} persona={persona} /> : <>
                <>
                  <Link className=" nav-link border-end" to="/login">Post a Role</Link>
                  <Link className=" nav-link" to="/login">Login</Link>
                  <Link className="bg-secondary text-white rounded-5 me-2 m-0 nav-link" to="/login">Start Recruiting</Link>
                  <LinkedInValidate />
                </>

              </>}
            </form>

          </div>

        </Nav> */}
      </Container>
    </div>
  );
}

export default NavbarTop;
