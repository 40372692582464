import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, Modal, ListGroup, ListGroupItem, Card, Alert, Button } from 'react-bootstrap';
import api from '../Utils/api';
import { Link } from 'react-router-dom';
import CreatePost from './CreatePost';
import TruncatedDescription from '../Widgets/TruncatedDescription';
import { useDispatch, useSelector } from 'react-redux';
import DateTime from '../Widgets/DateTime';

const BlogPosts = ({ email }) => {
    const dispath = useDispatch()

    // const [loggedIn, setLoggedIn] = useState(null)
    const loggedIn = useSelector((state) => state.auth.loggedIn);

    const [posts, setPosts] = useState(null)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('jwtToken');
//     if (token) {
//       setLoggedIn(true)
//     }
//   }, []);


    useEffect(() => {

        if (posts != null) {
            return;
        }

        getPosts()


    }, [posts]);

    const getPosts = async () => {
        var apiURL = '/blog'

        api.get(apiURL)
            .then((response) => {
                setPosts(response.data)
            })
            .catch((error) => {
                setPosts([])
                console.error('Error fetching login :', error);
                setError(error.error)
            });
    }

    const blogCreated = async () => {
        setShowModal(false)
        await getPosts();
    }

    return (
        <div>



            {message && (
                <Alert variant='success'>{message}</Alert>
            )}

            {error && (
                <Alert variant='danger'>{error}</Alert>
            )}



            <div className="container content-space-2 p-3 mb-3 hidden">
                <input type="text" className='form-control rounded-5' placeholder='Search...' />
            </div>


            <Stack gap={2}>
                {posts && (
                    <Row className='display-flex'>
                        {posts.map((job) => (
                            <Col className='mb-3' xs={12} md={12} lg={4} xl={4} >
                                <div className='h-100  '>
                                    <Card>
                                        
                                        <Card.Header className='bg-white'>
                                            <div className=' d-flex justify-content-between align-items-middle '>
                                                {job.source === "linkedin" && (<i style={{ fontSize: "28px" }} className='lab la-linkedin'></i>)}
                                                {job.source === "twitter" && (<i style={{ fontSize: "28px" }} className='lab la-twitter'></i>)}
                                                {job.source === "google" && (<i style={{ fontSize: "28px" }} className='lab la-google'></i>)}
                                                {job.source === "medium" && (<i style={{ fontSize: "28px" }} className='lab la-medium'></i>)}
                                                {job.source === "instagram" && (<i style={{ fontSize: "28px" }} className='lab la-instagram'></i>)}
                                                {job.source === "" && (<i style={{ fontSize: "28px" }} className='lab la-google'></i>)}

                                                <div className='ms-auto '>
                                                <small><DateTime timestamp={job.created_at} /></small>
                                                </div>
                                            </div>
                                        </Card.Header>

                                        {job.image != "" && (
                                            <>
                                            <div className='img-thumbnail' style={{ background: `url(${job.image})`, backgroundSize: "cover",  backgroundPosition: "center center", width: "100%", height: "200px" }}></div>
                                            {/* <Card.Img variant="top" src={job.image} /> */}
                                            </>
                                        )}
                                        <Card.Body>
                                            
                                                <Stack direction="horizontal" className='align-top' gap={3} >
                                                    <div>
                                                        <div className='m-0 p-0 mb-3'>
                                                        <a target="_blank" style={{ textDecoration: "none" }}  href={job.url}>
                                                            <b >{job.title}</b>
                                                        </a>
                                                        </div>
                                                        {/* <p className='m-0 p-0'>{job.description}</p> */}
                                                        <TruncatedDescription description={job.description} maxLength={100} />


                                                    </div>
                                                </Stack>
                                            
                                            
                                        </Card.Body>

                                    </Card>
                                </div>
                            </Col>
                        ))}

                    </Row>
                )}



            </Stack>

{loggedIn && (
    <>


<Modal show={showModal} size="xl" backdrop="static" keyboard={false} onHide={() => { setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Post New Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatePost onCreate={blogCreated} />
                </Modal.Body>
            </Modal>

    <div className='text-center mt-4 '><Button onClick={() => { setShowModal(true) }} variant='primary' className='w-50'>Create New Post</Button></div>
    </>
)}
            
        </div>
    );
};

export default BlogPosts;
