import React from 'react';
import { Ratio } from 'react-bootstrap';

const Avatar = ({ src, name, style }) => {
  const [imgError, setImgError] = React.useState(false);

  const getInitials = (name) => {
    if(!name) {
      return "<i class='la la-user'></i>"
    }
    const names = name.split(' ');
    const initials = names.map(name => name[0]).join('');
    return initials.toUpperCase();
  };

  return (
    <div>
      {src && !imgError ? (
        <img
          style={style}
          src={src}
          onError={() => setImgError(true)}
          alt={name}
          className='img-square'
        />
      ) : (
        <div className='bg-dark text-white p-0 m-0' style={{...style, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <b dangerouslySetInnerHTML={{ __html: getInitials(name) }} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
