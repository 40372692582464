import React, { useState, useEffect, useRef } from 'react';
import GenericForm from './GenericForm';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { CardGroup, Dropdown, Stack, ListGroup, Image, ListGroupItem, Card, Alert, Button, Form, Nav, NavLink } from 'react-bootstrap';
import api from '../Utils/api';
import { Link } from 'react-router-dom';
import TruncatedDescription from '../Widgets/TruncatedDescription';
import CandidatePipeline from './CandidatePipeline';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsAsync } from '../Store/dataSlice';
import Client from './Client/Client'
import { useHistory } from 'react-router-dom';
import JobInfoCard from '../Widgets/JobInfoCard';
import illustration_1 from '../assets/illustration_2.webp'

const JobPostsCards = ({ size, onClick, selectedJob }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // const [jobs, setJobs] = useState(null)
    // const loading = useSelector((state) => state.auth.loading);

    const jobs = useSelector((state) => state.data.jobs);

    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [deletionConfirmation, setDeletionConfirmation] = useState(null);
    const user = useSelector((state) => state.auth.user);

    const [selected, setSelected] = useState(null)

    const [cols, setCols] = useState(5)


    const [searchTerm, setSearchTerm] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const [jobTypeFilters, setJobTypeFilters] = useState([]);
    const [roleTypeFilters, setRoleTypeFilters] = useState([]);

    const [salaryRange, setSalaryRange] = useState([0, 1000000]); // Example range




    useEffect(() => {

        // console.log("LOADING.....")

        if (size == "lg") {
            setCols(12)
        }

        if (jobs != null) {
            return;
        }

        getData();
    }, []);

    const getData = async () => {
        await dispatch(getJobsAsync());
        console.log(jobs)
    }

    const deleteJob = async (id) => {
        var apiURL = '/jobs/' + id

        console.log(apiURL)
        api.delete(apiURL)
            .then((response) => {
                getData()
            })
            .catch((error) => {
                console.error('Error fetching login :', error);
                setError("Failed deleting job")
            });
    }

    const closeJob = async (job) => {
        console.log(job)
        const updatedObject = { ...job, status: 'CLOSED' };

        var apiURL = '/jobs'


        api.post(apiURL, updatedObject)
            .then((response) => {
                getData()
            })
            .catch((error) => {
                console.error('Error fetching login :', error);
                setError("Failed deleting job")
            });
    }

    const uniqueLocations = jobs ? Array.from(new Set(jobs.map(job => job.location))) : [];

    const allSkills = jobs ? jobs
        .map(job => job.skills.split(','))
        .flat()
        .map(skill => skill.trim())
        .filter(skill => skill !== '') : []; // Optional: Filter out any empty strings, if necessary

    const uniqueSkills = Array.from(new Set(allSkills));
    const [selectedSkills, setSelectedSkills] = useState([]);



    const filteredJobs = jobs && jobs.filter(job => {
        // Normalize and compare salary
        const minSalary = parseInt(job.min_salary.replace(/,/g, ''), 10);
        const maxSalary = parseInt(job.max_salary.replace(/,/g, ''), 10);


        // Check if the job's salary range intersects with the selected salary range
        const matchesSalary = (minSalary <= salaryRange[1]) && (maxSalary >= salaryRange[0]);

        // Apply search filter
        const matchesSearch = job.title.toLowerCase().includes(searchTerm.toLowerCase());

        // Apply location filter
        const matchesLocation = locationFilter ? job.location === locationFilter : true;

        // Apply job type filter - job.job_type needs to match any of the selected jobTypeFilters
        const matchesJobType = jobTypeFilters.length > 0 ? jobTypeFilters.includes(job.job_type) : true;

        // Apply role type filter - job.role_type needs to match any of the selected roleTypeFilters
        const matchesRoleType = roleTypeFilters.length > 0 ? roleTypeFilters.includes(job.role_type) : true;

        // Split job's skills into an array and trim each one
        const jobSkillsArray = job.skills.split(',').map(skill => skill.trim());

        // Check if any of the selected skills are included in the job's skills
        const matchesSkills = selectedSkills.length === 0 || selectedSkills.some(skill => jobSkillsArray.includes(skill));

        return matchesSearch && matchesLocation && matchesJobType && matchesRoleType && matchesSkills && matchesSalary;
    });


    const toggleSkill = (skill) => {
        setSelectedSkills(prev => {
            if (prev.includes(skill)) {
                return prev.filter(s => s !== skill); // Remove the skill
            } else {
                return [...prev, skill]; // Add the skill
            }
        });
    };


    const handleJobTypeFilterChange = (e) => {
        const value = e.target.value;
        setJobTypeFilters(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    const handleRoleTypeFilterChange = (e) => {
        const value = e.target.value;
        setRoleTypeFilters(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };



    return (
        <div>

            {message && (
                <Alert className='m-3 p-2' variant='success'>{message}</Alert>
            )}

            {error && (
                <Alert className='m-3 p-2' variant='danger'>{error}</Alert>
            )}


            {jobs && (
                jobs.length == 0 ?
                    <Container className=''>
                        <div className='row justify-content-center align-items-center vh-100-adjusted '>
                            {user.type == "employer" && (
                                <Col xl={{ span: 5, offset: 0 }} className=' text-center'>
                                    <Card className='bg-transparent border-0'>
                                        <Card.Body className='p-5'>
                                            <div className='text-center'><Image className='w-100' src={illustration_1} /></div>
                                            {/* <div className='mb-3 w-100 '>
                                                <i  className='las la-4x la-business-time rounded-pill p-5 bg-white'></i>
                                            </div> */}
                                            <div className='lead mb-1'><strong>No jobs created yet!</strong></div>
                                            <p className=''>Tap into our open talent network to crowdsource and rapidly identify highly-skilled, ideal fit candidate</p>
                                            <a className="h6 bg-dark text-white rounded-3 p-2 d-block w-100" href="/#/employer/create">
                                                <strong>Get Started</strong>
                                            </a>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}

                            {user.type == "recruiter" && (
                                <Col xl={{ span: 4, offset: 0 }} className=' text-center'>
                                    <Card className='bg-transparent border-0'>
                                        <Card.Body className='p-5'>
                                            <div className='text-center'><Image className='w-100' src={illustration_1} /></div>
                                            <div className='mb-1 lead  text-center'><strong>There are no jobs yet!</strong></div>
                                            {/* <p className='lead'>Tap into our open talent network to crowdsource and rapidly identify highly-skilled, ideal fit candidate</p>
                                            <a className="h6 bg-primary text-white rounded-3 p-2 d-block w-100" href="/#/employer/create">
                                                <strong>Get Started</strong>
                                            </a> */}

                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                        </div>
                    </Container>

                    :
                    <Container className='p-0'>
                        {/* <Row className={`${size == "lg" ? 'hidden' : ''}`}>
                           
                        </Row> */}

                        <Row>

                            <Col className={`${size == "lg" ? 'col-12 mb-1' : 'col-12 col-xl-9 col-lg-9 col-md-12 col-xs-12  mb-3'}`} >

                                <Nav variant="underline" activeKey="/active" className='mb-3'>
                                    <Nav.Item onClick={() => {
                                        // if (activeStep < step.id) {
                                        //     return
                                        // }
                                        // setActiveStep(step.id)
                                    }} >
                                        <Nav.Link className={`ms-2 me-auto text-center text-center active`} >
                                            <span className="fw-bold">Active</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className={`ms-2 me-auto text-center text-center`} >
                                            <span className="fw-bold">Archived</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                {jobs && (
                                    <Row>

                                        {filteredJobs.map((job) => (
                                            <Col className={`${size == "lg" ? 'col-12 mb-1' : 'col-12 col-xl-4 col-lg-12 col-md-12 col-xs-12  mb-3'}`} >

                                                <Card key={job.id} className={selected && (selected.id == job.id) || selectedJob && (selectedJob.id == job.id) ? 'selected h-100 ' : 'h-100'}>



                                                    {deletionConfirmation && deletionConfirmation === job.id ? (
                                                        <Card.Body style={{ minHeight: size == "lg" ? "50px" : "150px" }} className='bg-danger bg-opacity-10'>
                                                            <b>Are you sure you want to delete this job?</b>

                                                            <div className='mt-2'>
                                                                <button
                                                                    onClick={() => {
                                                                        deleteJob(deletionConfirmation);
                                                                        setDeletionConfirmation(null);
                                                                    }}
                                                                    className='btn btn-sm btn-danger'
                                                                >
                                                                    Confirm
                                                                </button>
                                                                <button
                                                                    onClick={() => setDeletionConfirmation(null)}
                                                                    className='btn btn-sm btn-dark border-0 ms-2'
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>

                                                        </Card.Body>
                                                    ) :
                                                        <div  >
                                                            <Card.Body className=''>

                                                                <div className=''>


                                                                    <div className='float-end'>
                                                                        <Dropdown>

                                                                            <Dropdown.Toggle variant="transparent" className='border-0 shadow-none p-0' id="dropdown-basic">
                                                                                <i className=' text-primary me-2 las la-ellipsis-v'></i>
                                                                            </Dropdown.Toggle>


                                                                            <Dropdown.Menu>
                                                                                <Link to={`/jobs/${job.id}`} className="dropdown-item">
                                                                                    <i className='text-dark float-start me-2 las la-eye'></i> View Details</Link>

                                                                                <Link to={`/jobs/${job.id}/candidates/review`} className="dropdown-item">
                                                                                    <i className='text-dark float-start me-2 las la-users'></i> Review Candidates</Link>
                                                                                {user.type == "employer" && (
                                                                                    <>
                                                                                        <Dropdown.Divider />
                                                                                        <Dropdown.Item className='' onClick={(e) => { e.preventDefault(); history.push(`/jobs/${job.id}/edit`) }}>
                                                                                            <i className='text-primary float-start me-2 las la-edit'></i>  Edit Details</Dropdown.Item>

                                                                                        <Dropdown.Item onClick={(e) => { e.preventDefault(); closeJob(job) }} >
                                                                                            <i className='text-warning float-start me-2 las la-times'></i> Close Posting</Dropdown.Item>

                                                                                        <Dropdown.Item onClick={(e) => { e.preventDefault(); setDeletionConfirmation(job.id) }} >
                                                                                            <i className='text-danger float-start me-2 las la-trash'></i> Delete Posting</Dropdown.Item>
                                                                                    </>
                                                                                )}

                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>

                                                                    <JobInfoCard as="h6" job={job} onClick={onClick} />

                                                                </div>

                                                            </Card.Body>
                                                        </div>
                                                    }

                                                </Card>
                                            </Col>

                                        ))}

                                    </Row>
                                )}



                            </Col>

                            {size != "lg" && (
                                <Col xs={12} md={12} lg={3} xl={3} >
                                    <div className='mb-4'>
                                        <Card className='bg-light border-0'>
                                            <Card.Header className='bg-light'><strong>Showing {filteredJobs.length} of {jobs.length} listings</strong></Card.Header>
                                            <Card.Body>
                                                {jobs && (
                                                    <Stack direction='vertical' gap={0}>



                                                        <input
                                                            type="text"
                                                            className="form-control rounded-5"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                        <select className="form-select rounded-5 mt-3" value={locationFilter} onChange={(e) => setLocationFilter(e.target.value)}>
                                                            <option value="">All Locations</option>
                                                            {uniqueLocations.map(location => (
                                                                <option key={location} value={location}>{location}</option>
                                                            ))}
                                                        </select>



                                                        <div className="mt-3">
                                                            <strong>Salary Range</strong>

                                                            <div>
                                                                <Form.Label className='m-0'>Minimum Salary (${Intl.NumberFormat('en-US').format(salaryRange[0])})</Form.Label>

                                                                <Form.Range
                                                                    type="range"
                                                                    min="0"
                                                                    max="1000000"
                                                                    step="10000"
                                                                    value={salaryRange[0]}
                                                                    onChange={(e) => setSalaryRange([parseInt(e.target.value, 10), salaryRange[1]])}
                                                                />
                                                            </div>
                                                            <div className='mt-2'>
                                                                <Form.Label className='m-0'>Maximum Salary (${Intl.NumberFormat('en-US').format(salaryRange[1])})</Form.Label>
                                                                <Form.Range
                                                                    type="range"
                                                                    min="0"
                                                                    max="1000000"
                                                                    step="10000"
                                                                    value={salaryRange[1]}
                                                                    onChange={(e) => setSalaryRange([salaryRange[0], parseInt(e.target.value, 10)])}
                                                                />
                                                            </div>
                                                            {/* <div className='small p-2'>Selected Range: ${salaryRange[0]} - ${salaryRange[1]}</div> */}
                                                        </div>


                                                        <div className="mt-3 ">
                                                            <strong>Job Type</strong>
                                                            <div className='form-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="remote"
                                                                    name="jobType"
                                                                    value="remote"
                                                                    className="form-check-input"
                                                                    checked={jobTypeFilters.includes("remote")}
                                                                    onChange={handleJobTypeFilterChange}
                                                                />
                                                                <label htmlFor="remote">Remote</label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="hybrid"
                                                                    name="jobType"
                                                                    value="hybrid"
                                                                    className="form-check-input"
                                                                    checked={jobTypeFilters.includes("hybrid")}
                                                                    onChange={handleJobTypeFilterChange}
                                                                />
                                                                <label htmlFor="hybrid">Hybrid</label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="onsite"
                                                                    name="jobType"
                                                                    value="onsite"
                                                                    className="form-check-input"
                                                                    checked={jobTypeFilters.includes("onsite")}
                                                                    onChange={handleJobTypeFilterChange}
                                                                />
                                                                <label htmlFor="onsite">On Site</label>
                                                            </div>
                                                            {/* Repeat for other job types */}
                                                        </div>

                                                        <div className="mt-3 ">
                                                            <strong>Role Type</strong>
                                                            <div className='form-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="fulltime"
                                                                    name="roleType"
                                                                    value="fulltime"
                                                                    className="form-check-input"
                                                                    checked={roleTypeFilters.includes("fulltime")}
                                                                    onChange={handleRoleTypeFilterChange}
                                                                />
                                                                <label htmlFor="fulltime">Full Time</label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="contractor"
                                                                    name="roleType"
                                                                    value="contractor"
                                                                    className="form-check-input"
                                                                    checked={roleTypeFilters.includes("contractor")}
                                                                    onChange={handleRoleTypeFilterChange}
                                                                />
                                                                <label htmlFor="contractor">Temporary / Contract</label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="contract-to-hire"
                                                                    name="roleType"
                                                                    value="contract-to-hire"
                                                                    className="form-check-input"
                                                                    checked={roleTypeFilters.includes("contract-to-hire")}
                                                                    onChange={handleRoleTypeFilterChange}
                                                                />
                                                                <label htmlFor="contract-to-hire">Contract to Hire</label>
                                                            </div>
                                                            {/* Repeat for other role types */}
                                                        </div>


                                                        <div className="skills-cloud mt-3 mb-1">
                                                            <div><strong>Skills</strong></div>
                                                            {uniqueSkills.map(skill => (
                                                                <span
                                                                    key={skill}
                                                                    className={`badge me-1 mb-1 ${selectedSkills.includes(skill) ? 'text-primary bg-white border border-primary' : 'border border-info text-muted'}`}
                                                                    onClick={() => toggleSkill(skill)}
                                                                >
                                                                    {skill} {selectedSkills.includes(skill) && (<i className='la la-close'></i>)}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </Stack>
                                                )}
                                            </Card.Body>
                                        </Card>

                                    </div>
                                </Col>

                            )}

                        </Row>
                    </Container>
            )}



        </div>
    );
};

export default JobPostsCards;
