import React, { useState, useEffect, useRef } from 'react';
import GenericForm from '../GenericForm';
import BannerMessage from '../BannerMessage';
import { Nav, NavItem, NavLink, Card, Stack, Row, Container, Col, Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import api from '../../Utils/api';
import { handleFileUpload } from '../../Utils/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const CreateCandidate = () => {


    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [candidateDescription, setCandidateDescription] = useState({
    })
    const [activeStep, setActiveStep] = useState(null)
    const profile = useSelector((state) => state.auth.profile);
    const [uploading, setUploading] = useState(false)
    const [pdfContent, setPdfContent] = useState('');

    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getCandidateDetails(id)
        } 
    }, [id]);

    const getCandidateDetails = async (candidate_id) => {
        console.log(candidate_id)

        var apiURL = '/candidates/' + candidate_id

        api.get(apiURL)
            .then((response) => {
                setCandidateDescription(response.data || null)
            })
            .catch((error) => {
                console.error('Error fetching login :', error);
                setError(error.error)
            });

    }



    useEffect(() => {
        // const profile = JSON.parse(localStorage.getItem('profile'));
        var obj = {
            // "client": profile.company
        }
        // setJobDescription({ ...jobDescription, ...obj });
        // console.log(obj)
        setActiveStep(0)
    }, []);



    const steps = [
        {
            id: 0,
            title: "Image",
            subtitle: "More details about the candidate",
            template: {
                fields: [
                    { name: 'profile_image_file', label: 'Profile Image', type: 'file', required: true },
                    {
                        name: '', label: "<b class='text-decoration-underline'>Don't want image? Skip</b>", type: 'link', onClick: function (event) {
                            event.preventDefault();
                            // // setCandidateDescription({ ...candidateDescription, ...formData });
                            // if(candidateDescription != null && candidateDescription.profile_image != "") {
                            //     candidateDescription.profile_image = candidateDescription.profile_image
                            // }
                            // value: candidateDescription.linkedin_url


                            setActiveStep(1)
                        }
                    }
                ],
                button: {
                    label: "Next",
                    variant: "primary"
                },
            },
            onSubmit: async function (formData) {
                setUploading(true)

                if(!formData.profile_image_file) {
                    setActiveStep(1)
                    return
                }

                try {
                    const response = await handleFileUpload(formData.profile_image_file)
                    formData.profile_image = response
                }
                catch (error) {
                    setError("failed to upload image")
                    return
                }


                console.log(formData.profile_image)
                setCandidateDescription({ ...candidateDescription, ...formData });
                console.log(candidateDescription)
                setActiveStep(1)
            }
        },
        {
            id: 1,
            title: "Overview",
            subtitle: "More details about the candidate",
            template: {
                fields: [
                    { name: 'name', label: 'Name', type: 'text', value: candidateDescription.name, required: true },
                    { name: 'linkedin_url', label: 'LinkedIn Profile URL', value: candidateDescription.linkedin_url, type: 'text', required: true },
                    { name: 'skills', label: 'Top Skills (press Enter to add)', value: candidateDescription.skills, type: 'tags' },
                    { name: 'phone', label: 'Phone Number', type: 'text', value: candidateDescription.phone, required: true },
                    { name: 'email', label: 'Email', type: 'text', value: candidateDescription.email, required: true },
                    // { name: 'profile_image_file', label: 'Profile Image', type: 'file', required: true },                    
                    { name: 'role', label: 'Current Role/Title', type: 'text', value: candidateDescription.role, required: true },
                    { name: 'location', label: 'Location', type: 'text', value: candidateDescription.location, required: true },
                ],
                button: {
                    label: "Next",
                    variant: "primary"
                },
            },
            onSubmit: async function (formData) {
                setCandidateDescription({ ...candidateDescription, ...formData });
                console.log(candidateDescription)
                setActiveStep(2)
            }
        },
        {
            id: 2,
            title: "Bio",
            subtitle: "More details about the candidate",
            template: {
                fields: [
                    { name: 'resume_file', label: 'Upload Resume', type: 'file', required: true },
                    { name: 'bio', label: 'Bio', type: 'textarea', value: candidateDescription.bio, required: true },
                    {
                        name: 'salary_type', label: 'Salary Type', value: candidateDescription.salary_type, type: 'select', options: [
                            {
                                "label": "Hourly",
                                "value": "hourly"
                            },
                            {
                                "label": "Yearly",
                                "value": "yearly"
                            },
                        ], required: true
                    },
                    { name: 'min_salary', label: 'Minium Salary in USD', value: candidateDescription.min_salary, type: 'text', required: true },
                    { name: 'max_salary', label: 'Maximum Salary in USD', value: candidateDescription.max_salary, type: 'text', required: true },
                    {
                        name: 'sponsorship_required', label: 'Does candidate require sponsorship?', value: candidateDescription.sponsorship_required, type: 'select', options: [
                            {
                                "label": "Yes",
                                "value": "yes"
                            },
                            {
                                "label": "No",
                                "value": "no"
                            },
                            {
                                "label": "Not Sure",
                                "value": "not_sure"
                            }
                        ], required: true
                    },
                ],
                button: {
                    label: "Next",
                    variant: "primary"
                },
            },
            onSubmit: async function (formData) {
                setUploading(true)


                if (formData.resume_file) {
                    try {
                        const response = await handleFileUpload(formData.resume_file)
                        console.log(response)
                        formData.resume = response
                    }
                    catch (error) {
                        setError(error)
                        return
                    }
                }

                setCandidateDescription({ ...candidateDescription, ...formData });
                console.log(candidateDescription)
                setActiveStep(3)
            }
        },
        {
            id: 3,
            title: "Submit",
            subtitle: "",
            template: {
                fields: [
                    { name: 'terms', label: 'By clicking, you acknowledge that you have read and understood, and agree to MR Terms & Conditions  and Privacy Policy  and  Recruiting Agreement.', type: 'checkbox', required: true },
                    { name: 'agreement', label: 'By clicking, you acknowledge that you have read and understood MR Recruiting Agreement.', type: 'checkbox', required: true },
                ],
                button: {
                    label: "Create Candidate",
                    variant: "primary"
                },
            },
            onSubmit: function (formData) {
                setCandidateDescription({ ...candidateDescription, ...formData });
                console.log(candidateDescription)

                // userProfile.terms = formData.terms
                // userProfile.agreement = formData.agreement
                // setUserProfile(userProfile)

                api.post(`/candidates`, candidateDescription)
                    .then(response => {
                        const data = response.data;
                        console.log(data);
                        setCandidateDescription({})
                        setActiveStep(1)
                        setMessage("Your candidate was successfully created")
                    })
                    .catch(error => {
                        console.error('Error creating workflow:', error)
                        setError(error.response.data)
                    });
            }
        }
    ]




    return (
        <>
            <BannerMessage heading={id ? "Edit Candidate Details" : "New Candidate"} subheading="Let's get started" />

            <Container className=''>
                <Row className=''>
                    <Col>

                        {message && (
                            <div className='text-success p-3'><b>{message}</b></div>
                        )}

                        {error && (
                            <div className='text-danger p-3'><b>{error}</b></div>
                        )}
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col xs={12} md={12} lg={12} xl={12}>


<hr/>                    <div>Resume: {pdfContent}</div>


                        <Nav variant="underline" defaultActiveKey="/home">
                            {steps.map((step) => (
                                <Nav.Item onClick={() => {
                                    if (activeStep < step.id) {
                                        return
                                    }
                                    setActiveStep(step.id)
                                }} className='mb-3' >
                                    <NavLink className={`ms-2 me-auto text-center w-100 text-center ${activeStep == step.id ? 'active' : ''} `} >
                                        <div className="fw-bold">{step.title}</div>
                                    </NavLink>
                                </Nav.Item>

                            ))}
                        </Nav>




                        <Card>
                            {steps.map((step) => (
                                <div className={activeStep == step.id ? 'active' : 'hidden'} key={step.id}>
                                    <Card.Header>
                                        <strong>{step.subtitle}</strong>
                                    </Card.Header>
                                    <Card.Body>

                                        <GenericForm template={step.template} onSubmit={step.onSubmit} />
                                        {/* </Card.Body> */}
                                    </Card.Body>
                                </div>
                            ))}

                        </Card>
                    </Col>
                </Row>


            </Container>
        </>
    );
};

export default CreateCandidate;
