import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';
import GenericForm from '../App/GenericForm';
import api from '../Utils/api';
import BlogPosts from './BlogPosts';
import BannerMessage from '../App/BannerMessage';

const CreatePost = ({onCreate}) => {
  // type Post struct {
  //   ID           string    `json:"id"`   // Email
  //   Type         string    `json:"type"` // Persona
  //   Title        string    `json:"title"`
  //   Description  string    `json:"description"`
  //   Image        string    `json:"image"`
  //   DateCreated  time.Time `json:"date_created"`
  //   DateModified time.Time `json:"date_modified"`
  //   Created_By   string    `json:"created_by"`
  // }

  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [blogPost, setBlogPost] = useState({})
  const [activeStep, setActiveStep] = useState(null)

  useEffect(() => {
    setActiveStep(1)
  }, []);

  const steps = [
    {
      id: 1,
      title: "Basic Information",
      subtitle: "General info about blog post",
      template: {
        fields: [
          { name: 'title', label: 'Blog Title', type: 'text', value: "", required: true },
          { name: 'description', label: 'Description', type: 'text', value: "", required: true },
          { name: 'url', label: 'Link', type: 'text', value: "", required: true },
          { name: 'image', label: 'Image', type: 'text', value: "", required: false },

        ],
        button: {
          label: "Next",
          variant: "primary"
        },
      },
      onSubmit: function (formData) {
        console.log(formData);
        setBlogPost({ ...blogPost, ...formData });
        setActiveStep(2)
      }
    },
    {
      id: 2,
      title: "Create Blog",
      subtitle: "",
      template: {
        fields: [
          {
            name: 'source', label: 'Source', type: 'select', options: [
              {
                "icon": "fa fa-linkedin",
                "label": "LinkedIn",
                "value": "linkedin"
              },
              {
                "icon": "fa fa-google",
                "label": "Google",
                "value": "google"
              },
              {
                "icon": "fa fa-twitter",
                "label": "Twitter",
                "value": "twitter"
              }
              ,
              {
                "icon": "fa fa-medium",
                "label": "Medium",
                "value": "medium"
              },
              {
                "icon": "fa fa-instagram",
                "label": "Instagram",
                "value": "instagram"
              }
            ], required: true
          },

        ],
        button: {
          label: "Create Blog",
          variant: "primary"
        },
      },
      onSubmit: function (formData) {
        blogPost.type = formData.type
        api.post(`/blog`,  blogPost )
          .then(response => {
            const data = response.data;
            setBlogPost({})
            setActiveStep(1)
            onCreate()
            
          })
          .catch(error => {
            console.error('Error creating workflow:', error)
            setError(error.response.data)
          });
      }
    }
  ]






  return (
    <>

      
            <div >
              {/* <h3>New Blog Post</h3> */}
              <Card className=''>
                {steps.map((step) => (
                  <Stack direction="vertical" gap={3} className={activeStep == step.id ? 'active' : 'hidden'} key={step.id}>
                    <Card.Header className='bg-grey-gradient text-dark border-0'>
                      <div className='mt-3 mb-3'>
                        <h5 className='m-0 p-0'>{step.title}</h5>
                        <div>{step.subtitle}</div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <GenericForm template={step.template} onSubmit={step.onSubmit} />
                    </Card.Body>
                  </Stack>
                ))}
              </Card>

            </div>
          
    </>
  );
};

export default CreatePost;
