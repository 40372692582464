import React from 'react';

const DateTime = ({ timestamp }) => {

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const now = new Date();

        // Format the time
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase();

        // Check if the date is today
        const isToday = date.toDateString() === now.toDateString();

        // Calculate the difference in days
        const diffTime = Math.abs(now - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        let formattedDate;

        if (isToday) {
            // If today, show "Today"
            formattedDate = `Today ${timeString}`;
        } else if (diffDays === 1) {
            // If yesterday, show "Yesterday"
            formattedDate = `Yesterday ${timeString}`;
        } else {
            // Otherwise, show "X days ago"
            formattedDate = `${diffDays} days ago ${timeString}`;
        }

        return formattedDate;

    }

    return (
        <span>
            {formatDate(timestamp)}
        </span>
    );
};

export default DateTime;
