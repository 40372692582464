import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { CardHeader, CardTitle, ListGroup, ListGroupItem, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WelcomeMessage from './WelcomeMessage';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeMessageSide from './WelcomeMessageSide';
import WelcomeMessageAlt from './WelcomeMessageAlt';

function BannerMessage({ heading, subheading, menu }) {
  const user = useSelector((state) => state.auth.user);
  const profile = useSelector((state) => state.auth.profile);
  const persona = useSelector((state) => state.auth.persona);

  return (
    // style={{  zIndex: 999, boxShadow: "0.5rem 0.5rem 1.5rem rgba(0,0,0,0.05)" }}
    // bg-gray
    <div className='sticky bg-light sticky-top  w-100 p-4'>
    <Container className=''>
      
        <Row>
          <Col>
          <div className="text-start ">
            {/* col-md-8 offset-md-2  */}
            
            {/* <Stack direction='horizontal' gap={3}> */}
            <div>
              {/* <i className='fa fa-home fa-4x'></i> */}
              {menu}
              <div className=''>
                  <h4 style={{ lineHeight: "1.2em"}} className='text-capitalize text-start p-0 m-0 mt-2 mb-0'>
                    <b dangerouslySetInnerHTML={{ __html: heading }} />
                  </h4>
                  <div className='p-0 m-0 mt-0 small'>{subheading}</div>
              </div>

              {/* <div className='ms-auto'><WelcomeMessage user={user} profile={profile} persona={persona} /></div> */}
            {/* </Stack> */}
            </div>
          </div>
        </Col>
        {/* <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ maxWidth: "90px"}} className='ms-auto '><WelcomeMessage /></Col> */}

        </Row>
      
    </Container>
    </div>

  );
}

export default BannerMessage;
