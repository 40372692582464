import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, Offcanvas, ListGroup, ListGroupItem, Card, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../Utils/api';
import Markdown from '../..//Widgets/Markdown';
import CandidateWidget from '../../Widgets/CandidateWidget';
import BannerMessage from '../BannerMessage';
import { getCandidates } from '../../Utils/api';
import ListCandidates from './ListCandidates';
import { useHistory } from "react-router-dom";
import CandidateDetails from './CandidateDetails';
import CreateCandidate from "./CreateCandidate"

const Candidates = ({ email }) => {
    const history = useHistory();

    const [candidate, setCandidate] = useState(null)
    const [showModal, setShowModal] = useState(false);

    const [addCandidate, setAddCandidate] = useState(false)


    useEffect(() => {
        if (!candidate) {
            setShowModal(false)
        }
    }, []);

    const onSelect = async (candidate) => {
        // console.log("selected", candidate)
        setCandidate(candidate)
        setShowModal(false)
        return
        history.push(`/candidates/${candidate.id}`)
    }

    const onSelectDetails = async () => {
        history.push(`/candidates/${candidate.id}`)
    }

    // <Link style={{ textDecoration: "none" }} className='' to={`/candidates/${candidate.id}`}></Link>
    return (
        <div>

            <BannerMessage heading="
                <div>
                    My Candidates
                </div>
            "
            menu={<Link to={`/recruiter/create`} className="floating_button btn btn-primary"><i className='la la-plus la-1x'></i></Link>}
                // menu={<Button onClick={() => { setShowModal(true) }} variant="info text-dark"><i className='la la-1x la-bars'></i></Button>}
                subheading="Manage your candidate pipelines. The next big thing is just the block away!" />

            <Offcanvas placement='end' show={showModal} onHide={() => { setShowModal(false) }}>
                <Offcanvas.Header className='bg-light' closeButton>
                    <Offcanvas.Title as="h5">Select Candidate</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ListCandidates onSelect={onSelect} />
                    {/* <Button variant='btn-outline-dark' onClick={() => {
                            setCandidate(null);
                            setAddCandidate(true);
                            setShowModal(false)
                        }} className="btn btn-outline-dark mt-3 w-100"><i className='fa fa-plus me-2'></i> New Candidate</Button> */}

                    <Link to={`/recruiter/create`} className="btn btn-outline-dark mt-3 w-100"><i className='fa fa-plus me-2'></i> New Candidate</Link>

                </Offcanvas.Body>
            </Offcanvas>


            {/* <div className={`sidebar ${!candidate  ? 'open' : ''} ${addCandidate  ? 'open' : ''}`}>
            <h5 className='p-2 '>Job Postings</h5>

            <ListCandidates onSelect={onSelect} />
                        <Button variant='btn-outline-dark' onClick={() => {
                            setCandidate(null);
                            setAddCandidate(true);
                            setShowModal(true)
                        }} className="btn btn-outline-dark mt-3 w-100"><i className='fa fa-plus me-2'></i> New Candidate</Button>

            </div> */}

            <div >
                <Container  className={`content-container ${showModal ? 'offcanvas-open' : ''}`}>
                    <Row className='display-flex h-100'>
                        {/* <Col className='offset-md-2 col-md-8'> */}
                        <Col xl={3} lg={4} md={5} sm={12} xs={12} className='mb-3'>

                        <ListCandidates onSelect={onSelect} />
                        {/* <Link to={`/recruiter/create`} className="btn btn-outline-dark mt-3 w-100"><i className='fa fa-plus me-2'></i> New Candidate</Link> */}

                    </Col>
                        <Col >
                            {/* <Button onClick={() => { setShowModal(true) }} variant="info text-white"><i className='la la-bars'></i></Button> */}
                            {candidate ? (
                                <div className='mb-5'>
                                    <CandidateDetails embed={true} candidate_id={candidate.id} />
                                </div>
                            ) :
                                <div className=''>
                                    {/* <div>
                        <Row className=''>
                            <Col>
                                <h5 className='text-black m-0 p-0 mt-3'>Onboard New Candidate</h5>
                                <p>Let's get started!<br />Build your profile and let recruiters find you. Get job postings delivered right to your email.</p>
                            </Col>
                        </Row>
                        <CreateCandidate />
                    </div> */}
                                </div>
                            }

                            {/* {!addCandidate ? (
                            <div className=''>
                                
                            </div>
                        ) :
                        <div className=''>
                        <div>
                        <Row className=''>
                            <Col>
                                <CreateCandidate />
                            </Col>
                        </Row>
                        
                    </div>
                    </div>
                        } */}


                        </Col>
                        {/* <Col >
                    </Col> */}
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Candidates;
