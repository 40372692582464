import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import api, { getJobCandidates } from '../Utils/api';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CandidateWidget from '../Widgets/CandidateWidget';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../Widgets/StrictModeDroppable';
import { setSubmittedData } from '../Store/submitCandidateSlice';
import { Toast, Card, Collapse, Modal, Accordion, Container, Alert, Image, ListGroup, Dropdown, ListGroupItem, Nav, NavLink, Stack, Button, Badge } from 'react-bootstrap';
import CandidateDraggableColumn from './CandidateDraggableColumn';
import BannerMessage from './BannerMessage';
import SubmitCandidate from './SubmitCandidate';
import JobCompensation from './JobCompensation';
import JobInfoCard from '../Widgets/JobInfoCard';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CandidateDetails from './Recruiter/CandidateDetails';
import Messages from './Messages';
import DateTime from '../Widgets/DateTime';
import Avatar from '../Widgets/Avatar';
import Client from './Client/Client';
import { removeCandidateFromPipeline } from '../Utils/api';
import Markdown from '../Widgets/Markdown';

// import { setSubmittedData } from '../Store/submitCandidateSlice';

const CandidatePipelineAlt = ({ job }) => {
    const dispatch = useDispatch();

    const history = useHistory();
    const [jobID, setJobID] = useState(null)
    const user = useSelector((state) => state.auth.user);
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [deletionConfirmation, setDeletionConfirmation] = useState(null);
    const [activeTab, setActiveTab] = useState("details");

    const [open, setOpen] = useState(false);

    const [tempData, setTempData] = useState({
        error: null,
        stage: '',
        notes: '',
        index: -1,
    });



    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [selectedCandidates, setSelectedCandidates] = useState([])
    const prevIdRef = useRef();

    const [readOnly, setReadOnly] = useState(true)
    const [allowEdit, setAllowEdit] = useState(false)

    const [isDragging, setIsDragging] = useState(false);
    const [candidateStages, setCandidateStages] = useState([]);

    const [selectedCandidate, setSelectedCandidate] = useState(null)


    // var CandidateStages = ["SUBMITTED", "INTERVIEWING", "REJECTED"]
    const [activeStage, setActiveStage] = useState("Submitted")



    useEffect(() => {
        if (user.type === "recruiter") {
            setReadOnly(true)
            setAllowEdit(true)
            setCandidateStages(["Pending Submission", "Submitted", "Interviewing", "Rejected", "Withdrawn"])
        }

        // If employer and if the job id matches the creator than only allow drag
        if (user.type === "employer") {
            setReadOnly(false)
            setAllowEdit(false)
            setCandidateStages(["Pending Submission", "Submitted", "Interviewing", "Rejected", "Withdrawn"])
        }

    }, []);


    // const test = useSelector((state) => {
    //     console.log("State", state)
    //     submittedData = state.submitCandidate.submittedData;
    // })

    var submittedData = useSelector((state) => state.submitCandidate.submittedData);
    useEffect(() => {
        if (submittedData) {
            console.log("job id", submittedData)
            setJobID(submittedData)
            getData(submittedData);
        }
    }, [submittedData, dispatch]);

    const onCandidateAdded = () => {
        setActiveStage("Pending Submission")
        console.log("Pipeline = onCandidateAdded")
        getData(jobID)
    }

    const onCandidateRemove = async () => {
        console.log("Pipeline = onCandidateRemove")
        getData(jobID)
    }

    const getData = async (id) => {
        try {
            setSelectedCandidate(null)
            setSelectedCandidates([])
            const response = await getJobCandidates(id)
            setSelectedCandidates(response);
            // setSelectedCandidates(selectedCandidates => [...response || [], ...selectedCandidates]);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const handleInputChange = async (e) => {
        console.log(e.target.value)
        if (e.target.value == "") {
            return
        }
        changeStage(e.target.value)
    }

    const changeStage = async (stage) => {
        const updatedCandidates = [...selectedCandidates];
        console.log(updatedCandidates)

        const index = updatedCandidates.findIndex(user => user.candidate_id === selectedCandidate.candidate_details.id);
        if (index === -1) return; // or handle error

        setTempData({ stage, index });
        setShowModal(true);
    }

    const sendMessage = async (msg) => {
        var msg = {
            "job_id": job.id,
            "candidate_id": selectedCandidate.candidate_details.id,
            "user_id": user.id,
            "text": msg,
        }

        // console.log(msg)
        // return 

        await api.post(`/jobs/${jobID}/messages`, msg)
            .then(response => {
                const data = response.data;
                console.log(data)
                selectedCandidate.messages.unshift(data)
            })
            .catch(error => {
                console.error('Error creating workflow:', error)
                setError(error.response.data)
            });

    }
    const handleModalSubmit = async (notes) => {
        const updatedCandidates = [...selectedCandidates];
        const { stage, index } = tempData;

        await sendMessage(`Moved from <strong>${updatedCandidates[index].stage}</strong> to <strong>${stage}</strong><blockquote>${notes}</blockquote>`);
        // await sendMessage(notes);

        updatedCandidates[index].stage = stage;
        // updatedCandidates[index].notes = notes; 

        setSelectedCandidates(updatedCandidates);
        udpateCandidateInPipeline(updatedCandidates[index]);

        setShowModal(false); // Hide the modal

        setSelectedCandidate(null)

    };

    const onStageChange = (result) => {
        if (!result.destination) return; // Item was dropped outside of a droppable
        setIsDragging(false);

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Copy the selectedCandidates array to avoid mutating state directly
        const updatedCandidates = [...selectedCandidates];
        // Get the candidate that was dragged
        const draggedCandidate = updatedCandidates.filter((candidate) => candidate.stage === result.source.droppableId)[sourceIndex];
        // Get the candidate index that was dragged
        const draggedCandidateIndex = updatedCandidates.map(e => e.type).indexOf(draggedCandidate.type);
        // Update the stage property of the candidate's candidate_details object
        draggedCandidate.stage = result.destination.droppableId;
        // Update the array at that index with new object
        updatedCandidates[draggedCandidateIndex] = draggedCandidate

        setSelectedCandidates(updatedCandidates);

        udpateCandidateInPipeline(draggedCandidate)
    };


    const udpateCandidateInPipeline = async (candidate) => {
        console.log("Candidate Selected for Job", candidate)

        await api.post(`/jobs/${jobID}/candidates`, candidate)
            .then(response => {
                const data = response.data;
                console.log(data);
                handleDataChange(jobID)

            })
            .catch(error => {
                console.error('Error creating workflow:', error)
                setError(error.response.data)
            });
    }

    // Example: When something changes, dispatch the action to update the shared state
    const handleDataChange = (data) => {
        // Dispatch the action to update the shared state
        console.log("dispatching on candidate change")
        dispatch(setSubmittedData(data));
    };


    // const addMessage = async (e) => {

    //     e.preventDefault()
    //     var msg = {
    //         "job_id": job.id,
    //         "candidate_id": selectedCandidate.candidate_details.id,
    //         "user_id": user.id,
    //         "text": message,
    //     }

    //     await api.post(`/jobs/${jobID}/messages`, msg)
    //         .then(response => {
    //             const data = response.data;
    //             console.log(data)
    //             selectedCandidate.messages.unshift(data)
    //         })
    //         .catch(error => {
    //             console.error('Error creating workflow:', error)
    //             setError(error.response.data)
    //         });

    //     setMessage(null)
    //     // const response = await getJobCandidates(id)
    //     // setSelectedCandidates(response);        
    // }



    return (
        <>
            {job && (
                <>

                    <Modal show={showModal} size="sm" backdrop="static" keyboard={false} onHide={() => { setShowModal(false) }}>
                        <Modal.Header closeButton>
                            <Modal.Title><h6>Please add any specific details/message you like </h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className=''>
                                <Stack direction='vertical' gap={3}>
                                    {tempData.error && (<div className='text-danger'>{tempData.error}</div>)}
                                    <textarea type='text'
                                        placeholder="Enter notes here..."
                                        value={tempData.notes}
                                        onChange={(e) => setTempData({ ...tempData, notes: e.target.value })}
                                        className='form-control text-black bg-white shadow-none border-1 ' />
                                    {/* <Button onClick={addMessage} variant='success' className='btn-sm border-0 bg-green'><i className='la la-reply'></i></Button> */}
                                    <Button onClick={(e) => {
                                        e.preventDefault()
                                        if (!tempData.notes || tempData.notes == "") {
                                            setTempData({ ...tempData, error: "Please provide some notes" })
                                            return
                                        }
                                        handleModalSubmit(tempData.notes)
                                    }} variant='success' className='btn-sm border-0 bg-green'>Submit</Button>

                                </Stack>
                            </div>
                        </Modal.Body>
                    </Modal>





                    <Container className='p-0'>
                        <Row>
                            <Col xl={9} className='mb-2'>

                                <Card className='h-100 '>
                                    <Card.Body>
                                        <JobInfoCard as="h6" job={job} />
                                    </Card.Body>

                                </Card>

                            </Col>
                            <Col xl={3} className=''>
                                {/* <div ><Client  client={job.client} /></div> */}
                                {user.type == "recruiter" ?
                                    <div className='h-100'>
                                        <JobCompensation />
                                        <SubmitCandidate onCandidateAdded={onCandidateAdded} jobId={jobID} />
                                    </div> :
                                    <Card className='h-100 d-none d-sm-block'>
                                        <Card.Header className=''><b>What happens after your move a candidate?</b></Card.Header>
                                        <Card.Body>
                                            <p>We start the magic for you. Drag candidates from one stage to another and we will take care of the rest behing the scenes.</p>
                                        </Card.Body>
                                    </Card>
                                }
                            </Col>

                        </Row>
                        <Row className='sticky-top pt-3 bg-light d-none d-sm-block'>
                            <Col xl={12} >
                                <Nav variant="underline" className=' mb-4'>
                                    {candidateStages.map((stage, index) => (
                                        <NavLink key={index}
                                            onClick={() => {
                                                setSelectedCandidate(null)
                                                setActiveStage(stage)
                                            }}

                                            className={` ${activeStage == stage ? 'active' : ''} `}>

                                            <b>{stage} ({selectedCandidates.filter((candidate) => candidate.stage.toUpperCase() === stage.toUpperCase()).length})</b>

                                        </NavLink>
                                    ))}
                                </Nav>

                            </Col>
                        </Row>

                        <Row className='sticky-top pt-3 bg-light d-block d-sm-none'>
                            <Col xl={12} >
                                <Nav variant="pills"  className='flex-column mb-4'>
                                    {candidateStages.map((stage, index) => (
                                        <NavLink key={index}
                                            onClick={() => {
                                                setSelectedCandidate(null)
                                                setActiveStage(stage)
                                            }}

                                            className={` ${activeStage == stage ? 'active' : ''} `}>

                                            <b>{stage} ({selectedCandidates.filter((candidate) => candidate.stage.toUpperCase() === stage.toUpperCase()).length})</b>

                                        </NavLink>
                                    ))}
                                </Nav>

                            </Col>
                        </Row>

                        <Row >
                            <Col xs={12} md={12} lg={12} xl={12}>
                                {selectedCandidates.filter((candidate) => candidate.stage.toUpperCase() === activeStage.toUpperCase()).length == 0 ?
                                    <div className='alert alert-info bg-white'><b>No Candidates Currently in {activeStage} stage!</b></div> :
                                    ""
                                }
                            </Col>

                            <Col xs={12} md={12} lg={12} xl={3} className='h-100 '>
                                {selectedCandidates.filter((candidate) => candidate.stage.toUpperCase() === activeStage.toUpperCase()).length == 0 ?
                                    "" :
                                    <div className='mb-3'>
                                        <ListGroup>

                                            {selectedCandidates.filter((candidate) => candidate.stage.toUpperCase() === activeStage.toUpperCase())
                                                .map((candidate, index) => (
                                                    <ListGroupItem
                                                        key={candidate.candidate_details.id}
                                                        className={` ${selectedCandidate && selectedCandidate.candidate_details.id == candidate.candidate_details.id ? 'active' : '  '} `}
                                                    >

                                                        <div >
                                                            <CandidateWidget
                                                                candidate={candidate.candidate_details}
                                                                onClick={() => {
                                                                    setSelectedCandidate(candidate)
                                                                    // setOpen(!open)
                                                                    setOpen(true)
                                                                }}
                                                            />
                                                        </div>
                                                    </ListGroupItem>

                                                ))}
                                        </ListGroup>
                                    </div>
                                }
                            </Col>
                            <Col xs={12} md={12} lg={12} xl={9} className='mb-4'>

                                {deletionConfirmation && (
                                    <Alert variant='danger' className='p-1 ps-3'>
                                        <Stack direction='horizontal' gap={3}>
                                            <b>Sure?</b>
                                            <div className='ms-auto'>
                                                <button
                                                    onClick={async () => {
                                                        console.log(deletionConfirmation);
                                                        await removeCandidateFromPipeline(jobID, deletionConfirmation)
                                                        dispatch(setSubmittedData(jobID));
                                                        setDeletionConfirmation(null);
                                                        // onCandidateRemove()
                                                    }}
                                                    className='btn btn-sm btn-danger ms-2'
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    onClick={() => setDeletionConfirmation(null)}
                                                    className='btn btn-sm btn-dark border-0 ms-2'
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Stack>
                                    </Alert>
                                )}

<div>


                                {selectedCandidate ?
                                    <div>





                                        {/* {activeTab == "details" && ( */}
                                        <div>
                                            <Card className='mb-3'>
                                                <Card.Body className=''>

                                                    {user.type == "recruiter" && activeStage != "Withdrawn" && (<div>
                                                        <a href="#" className='small badge bg-secondary' onClick={async (e) => {
                                                            e.preventDefault();
                                                            changeStage("withdrawn")
                                                        }}>
                                                            <i className='fa fa-times me-1'></i>Withdraw</a>
                                                    </div>)}
                                                    {user.type != "recruiter" && (
                                                        <div>
                                                            <strong>Current Stage:</strong>

                                                            <select value={activeStage} className='form-select p-0 bg-white shadow-none border-0' onChange={handleInputChange}>
                                                                {candidateStages.map((stage, index) => (

                                                                    <option key={stage} value={stage}>{stage}</option>
                                                                ))}


                                                            </select>
                                                        </div>
                                                    )}
                                                </Card.Body>

                                            </Card>


                                            <Card className='mt-3 mb-3'>
                                                {/* <Card.Header><strong>Why is this candidate great fit for the role? Recruiter Notes</strong></Card.Header> */}
                                                <Card.Body className=''>
                                                    <small className='text-muted'>Recruiter Notes</small>
                                                    <div><strong>Why is this candidate great fit for the role?</strong></div>
                                                    <Markdown content={selectedCandidate.notes} />
                                                </Card.Body>
                                                <Card.Footer>
                                                    <small>Submitted <strong><DateTime timestamp={selectedCandidate.created_at} /></strong> by <strong>{selectedCandidate.recruiter.name}</strong>
                                                        {/* <Avatar
                                                                        style={{ width: "20px", height: "20px", borderRadius: "50%", }}
                                                                        src={selectedCandidate.recruiter.avatar}
                                                                        name={selectedCandidate.recruiter.name}
                                                                    /> */}

                                                    </small>

                                                </Card.Footer>
                                            </Card>


                                            <CandidateDetails embed={true} candidate_id={selectedCandidate.candidate_details.id} />
                                        </div>
                                        {/* )} */}

                                        {/* {activeTab == "activity" && ( */}
                                        <div className='mt-3'>
                                            <Card >
                                                <Card.Header><strong>Activity</strong></Card.Header>
                                                <Card.Body className='p-0'>
                                                    <div className=' p-3'>
                                                        {/* <Stack direction='horizontal' gap={3}>
                                                    <input type='text' value={message} onChange={(e) => setMessage(e.target.value)} className='form-control text-black bg-white shadow-none border-1 ' />
                                                    <Button onClick={addMessage} variant='success' className='btn-sm border-0 bg-green'><i className='la la-reply'></i></Button>
                                                </Stack> */}

                                                        <Stack direction='horizontal' gap={3}>
                                                            {tempData.error && (<div className='text-danger'>{tempData.error}</div>)}
                                                            <textarea type='text'
                                                                placeholder="Enter notes here..."
                                                                value={tempData.notes}
                                                                onChange={(e) => setTempData({ ...tempData, notes: e.target.value })}
                                                                className='form-control text-black bg-white shadow-none border-1 ' />
                                                            {/* <Button onClick={addMessage} variant='success' className='btn-sm border-0 bg-green'><i className='la la-reply'></i></Button> */}
                                                            <Button onClick={async (e) => {
                                                                e.preventDefault()
                                                                if (!tempData.notes || tempData.notes == "") {
                                                                    setTempData({ ...tempData, error: "Please provide some notes" })
                                                                    return
                                                                }
                                                                await sendMessage(tempData.notes)
                                                                setTempData({ ...tempData, error: null, notes: "" })

                                                            }} variant='success' className='btn-sm h-100 border-0 bg-green'><i className='la la-send'></i></Button>

                                                        </Stack>


                                                    </div>

                                                    <div className="feed-activity-list mt-3">
                                                        {selectedCandidate.messages && (
                                                            <div>
                                                                {selectedCandidate.messages.map((message, index) => (
                                                                    <div className='feed-element'>
                                                                        <div className='pull-left'>
                                                                            <Avatar
                                                                                style={{ width: "50px", height: "50px", borderRadius: "50%", }}
                                                                                src={message.user.avatar}
                                                                                name={message.user.name}
                                                                            />

                                                                        </div>
                                                                        <div className='media-body'>
                                                                            <small className='pull-right'><DateTime timestamp={message.created_at} /></small>
                                                                            <strong>{message.user.name}</strong>
                                                                            <div className='small '>
                                                                                {/* {message.text} */}
                                                                                <span dangerouslySetInnerHTML={{ __html: message.text }} />
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                ))}
                                                            </div>
                                                        )}


                                                    </div>

                                                </Card.Body>




                                            </Card>


                                        </div>
                                        {/* )} */}
                                    </div>
                                    : <div></div>}

</div>                                    
                            </Col>
                        </Row>



                        {/* <Col xl={3} className=''>
                            <div className='rounded-3 bg-white p-3 mb-3'>
                                <JobInfoCard as="h5" job={job} />
                            </div>


                            {user.type == "recruiter" ?
                                <div>
                                    <JobCompensation />
                                    <SubmitCandidate onCandidateAdded={onCandidateAdded} jobId={jobID} />
                                </div> :
                                <Card className=''>
                                    <Card.Header><b>What happens after your move a candidate?</b></Card.Header>
                                    <Card.Body>
                                        <div className='text-center'><i className='la la-users text-black la-5x'></i></div>
                                        <p>We start the magic for you. Drag candidates from one stage to another and we will take care of the rest behing the scenes.</p>
                                    </Card.Body>
                                </Card>


                            }

                        </Col> */}

                    </Container>





                </>
            )}


        </>


    );
};

export default CandidatePipelineAlt;
