import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, ListGroup, ListGroupItem, Card, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../Utils/api';
import Markdown from '../../Widgets/Markdown';
import CandidateWidget from '../../Widgets/CandidateWidget';
import BannerMessage from '../BannerMessage';
import { getCandidates } from '../../Utils/api';
// import ListCandidates from './ListCandidates';
import { useHistory } from "react-router-dom";
// import CandidateDetails from './CandidateDetails';
import CreateCandidate from '../Recruiter/CreateCandidate';
import { useDispatch, useSelector } from 'react-redux';

const CandidateProfile = ({ email }) => {
    const history = useHistory();
    const profile = useSelector((state) => state.auth.profile) || {};

    // const [candidate, setCandidate] = useState(null)


    // const onSelect = async (candidate) => {
    //     // console.log("selected", candidate)
    //     setCandidate(candidate)
    //     return
    //     history.push(`/candidates/${candidate.id}`)
    // }

    // const onSelectDetails = async () => {
    //     history.push(`/candidates/${candidate.id}`)
    // }

    // <Link style={{ textDecoration: "none" }} className='' to={`/candidates/${candidate.id}`}></Link>
    return (
        <div>

            <BannerMessage heading="My Profile" subheading="Manage your candidate pipelines. The next big thing is just the block away!" />

            <Container className='mb-3 '>
                <Row className='display-flex h-100'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='bg-white rounded-2 shadow-sm h-100 p-4 mb-4'>
                    <Stack direction='horizontal' gap={3}>
                                        


                                        { profile.profile_image != "" ? <img style={{ width: "100px", height: "100px" }} src={profile.profile_image} className="img-fluid rounded-2" alt="img" /> : 
              <div style={{ width: "100px", height: "100px" }}  className='  text-center bg-primary text-white rounded p-3'><i className='la-2x la la-user'></i> </div>
            }

                                        <div>
                                            <div>${profile.min_salary} - ${profile.max_salary} / {profile.salary_type}</div>
                                            <div>{profile.location}</div>

                                            <div><span className='badge bg-info me-2 mt-2 mb-2'>{profile.email}</span></div>
                                        </div>
                                    </Stack>
                                    </div>

                        <div className='bg-white rounded-2 p-3 shadow-sm'>
                            <Markdown content={profile.bio} />
                        </div>

                    </Col>
                </Row>
                <Row className='display-flex mt-4'>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div className='bg-white rounded-2 shadow-sm h-100 p-4 mb-4'>
                            <h3>Jobs Submitted for</h3>
                            <div className='text-secondary'><b>No Jobs found</b></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                        <div className='bg-white rounded-2 shadow-sm h-100 p-4 mb-4'>
                            <h3 >Matching Jobs</h3>
                            <div className='text-secondary'><b>No Jobs found</b></div>
                        </div>
                    </Col>

                </Row>
            </Container>

        </div>
    );
};

export default CandidateProfile;
