import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, Modal, ListGroup, ListGroupItem, Card, Alert, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../Utils/api';
import TruncatedDescription from '../../Widgets/TruncatedDescription';

const Client = ({ client }) => {
    const [error, setError] = useState(null)
    // const [client, setClient] = useState(null);

    // const getClientDetails = async () => {
    //     var apiURL = '/clients/' + id

    //     api.get(apiURL)
    //         .then((response) => {
    //             setClient(response.data)
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching login :', error);
    //             setError(error.error)
    //         });
    // }


    // useEffect(() => {
    //     getClientDetails()

    // }, []);


    return (
        <div>


            {error && (
                <Alert variant='danger'>{error}</Alert>
            )}




            {client && (
                <Card className=''>
                    <Card.Header className=''>
                    <div><small>Company Info</small><br/><b>{client.name}</b></div>
                    </Card.Header>
                        <Card.Body>
                            <Stack  direction='vertical' gap="1">
                                {/* <div style={{ width: "350px", height: "150px", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundImage:`url(${client.image})` }}></div> */}
                                <div>
                                <div><Image className='mb-2 mt-1 w-100 p-4' rounded src={client.image} /></div>
                                {/* <div className=''>{client.description}</div> */}
                                <TruncatedDescription description={client.description} maxLength={200} />
                                </div>
                            </Stack>
                        </Card.Body>
                        <Card.Footer>
                        <div className='small'>{client.location} | <a className='' href={client.url} target="_blank">Website</a></div>
                        </Card.Footer>
                    </Card>
            )}





        </div>
    );
};

export default Client;
