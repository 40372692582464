import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import GenericForm from './GenericForm';
import api from '../Utils/api';
import { useHistory } from 'react-router-dom';
import { Nav, NavLink, Stack, Modal, ListGroup, ListGroupItem, Card, Alert, Button } from 'react-bootstrap';
import CreateClient from './Client/CreateClient';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfileAsync } from '../Store/authSlice';
import RecuiterProfile from './RecuiterProfile';
import CreateProfile from './CreateProfile';
import BannerMessage from './BannerMessage';

const EditProfile = ({ user, email, persona }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [clients, setClients] = useState(null)
    const [clientOptions, setClientOptions] = useState(null)
    const [showModal, setShowModal] = useState(false);

    // const [userProfile, setUserProfile] = useState({})
    // const [client, setClient] = useState(null)

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(null)



    return (
        <>

            <BannerMessage heading="Profile" subheading="Edit your profile" />
            <CreateProfile />
        </>
    );
};

export default EditProfile;
