import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import api from '../Utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LinkedInPage from '../Login/LinkedIn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, CardGroup, Container, Stack } from 'react-bootstrap';
import TeamMemberCarousel from './TeamMemberCarousel';
import img23 from '../assets/img23.jpg'
import HomeHero1 from './HomeHero1';
import hero_1 from '../assets/hero_1.png'

const HomeSection3 = () => {


    return (
        <div className='' style={{ position: "relative" }} >
            <div className='section-bg-middle'></div>
            <div className=' p-5'>

                <div className='text-center'><span className='bg-white rounded-pill p-2 ps-3 pe-3 mb-4 btn border-primary text-primary'><b>Why Million Recruiters?</b></span></div>

                          
                <Container>

                    <Row xs={12} md={12} lg={12} xl={12} className="g-4">

                        <Col style={{ position: 'relative' }} xs={12} md={12} lg={1} xl={1} className=""></Col>
                        {/* Employer Callout */}
                        <Col style={{ position: 'relative' }} xs={12} md={12} lg={5} xl={5} className=" p-0 pe-3 rounded-3 ">
                            {/* <HomeHero1 /> */}

                            {/* <div className='text-center'>
                                <img src={hero_1} className='w-100 ' />
                            </div> */}

                            <div className='p-4'>
                                <h3 className='text-black fw-bolder mt-2 mb-0'>Benefits to Employers</h3>
                            <p className='m-0 p-0 mb-3 '>Pay Only When You Hire: No Hidden Fees, No Monthly Charges</p>
                                <Stack gap={2} className='bg-white p-1 border border-1 rounded-3'>


                                    <div className='border-bottom p-1'>
                                        <Stack direction="horizontal" gap={3}>
                                            {/* <i className='las la-4x la-users text-dark me-1'></i> */}
                                            <i className='la-users la la-3x m-2 me-1 p-2 bg-dark  text-white rounded-circle'></i>
                                            <div className="p-0">
                                                <b>More Qualified Candidates</b><br />
                                                Access to an exponentially larger pool of qualified candidates
                                            </div>
                                            <div className='ms-auto relative d-none d-lg-block' >
                                                <div className="p-2 ms-auto"><i className='las la-2x la-check-circle text-success me-1'></i></div>
                                                {/* <div className="p-2"><h3 className='text-danger'><i className='fi fi-rr-x'></i></h3></div> */}
                                            </div>
                                        </Stack>
                                    </div>

                                    <div className='border-bottom p-1'>
                                        <Stack direction="horizontal" gap={3}>
                                            {/* <i className='las la-4x la-handshake me-1 text-dark '></i> */}
                                            <i className='la-handshake la la-3x m-2 me-1 p-2 bg-dark  text-white rounded-circle'></i>
                                            <div className="p-0">
                                                <b>White Glove Service</b><br />
                                                Million Recruiters does the heavy lifting to identify the very best people
                                            </div>
                                            <div className='ms-auto relative d-none d-lg-block' >
                                                <div className="p-2 ms-auto"><i className='las la-2x la-check-circle text-success me-1'></i></div>
                                                {/* <div className="p-2"><h3 className='text-danger'><i className='fi fi-rr-x'></i></h3></div> */}
                                            </div>
                                        </Stack>
                                    </div>

                                    <div className='  p-1'>
                                        <Stack direction="horizontal" gap={3}>
                                            <i className='la-money la la-3x m-2 me-1 p-2 bg-dark  text-white rounded-circle'></i>
                                            <div className="p-0">
                                                <b>Half the Cost</b><br />
                                                Less than half the cost of traditional recruiting agencies
                                            </div>
                                            <div className='ms-auto relative d-none d-lg-block'>
                                                <div className="p-2 ms-auto"><i className='las la-2x la-check-circle text-success me-1'></i></div>
                                                {/* <div className="p-2"><h3 className='text-danger'><i className='fi fi-rr-x'></i></h3></div> */}
                                            </div>
                                        </Stack>
                                    </div>

                                </Stack>

                                {/* <Card className='border-1 bg-white  rounded-3 p-3 ms-1 me-1 mb-1 '>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-users la-3x'></i>
                    <p className='m-0 h6 text-muted'>Access to an exponentially larger pool of qualified candidates</p>
                  </Stack>
                </Card>
                <Card className='border-1 bg-white rounded-3 p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-handshake la-3x'></i>
                    <p className='m-0 h6 text-muted'>White Glove service - Million Recruiters does the heavy lifting to identify the very best people</p>
                  </Stack>
                </Card>
                <Card className='border-1 bg-white rounded-3 p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-money la-3x'></i>
                    <p className='m-0 h6 text-muted'>Less than half the cost of traditional recruiting agencies</p>
                  </Stack>
                </Card> */}
                            </div>
                        </Col>

                        <Col xs={12} md={12} lg={5} xl={5} className="">
                            <div className='p-4'>
                                <h3 className='text-black fw-bolder  mt-2 mb-0'>A paradise for recruiters</h3>
                                <p className='m-0 p-0 mb-3 '>Recruit at your pace and earn rewards per placement</p>
                                <Stack gap={2} className='bg-white border border-1 p-1 rounded-3'>
                                    <div className='border-bottom  p-1'>
                                        <Stack direction="horizontal" gap={3}>
                                            {/* <i className='las la-4x la-money-bill me-1'></i> */}
                                            <i className='la-money-bill la la-3x m-2 me-1 p-2 bg-dark  text-white rounded-circle'></i>
                                            <div className="p-0">
                                                <b>Match Talent, Make Money</b><br />
                                                Earn up to $20,000 commission rewards per placement
                                            </div>
                                            <div className='ms-auto relative d-none d-lg-block' >
                                                <div className="p-2 ms-auto"><i className='las la-2x la-check-circle text-success me-1'></i></div>
                                                {/* <div className="p-2"><h3 className='text-danger'><i className='fi fi-rr-x'></i></h3></div> */}
                                            </div>
                                        </Stack>
                                    </div>

                                    <div className='border-bottom p-1'>
                                        <Stack direction="horizontal" gap={3}>
                                            {/* <i className='las la-4x la-calendar me-1'></i> */}
                                            <i className='la-calendar la la-3x  m-2 me-1 p-2 bg-dark  text-white rounded-circle'></i>
                                            <div className="p-0">
                                                <b>Flexibility</b><br />
                                                Recruit for whoever you want, whenever you want, wherever you want.
                                            </div>
                                            <div className='ms-auto relative d-none d-lg-block' >
                                                <div className="p-2 ms-auto"><i className='las la-2x la-check-circle text-success me-1'></i></div>
                                                {/* <div className="p-2"><h3 className='text-danger'><i className='fi fi-rr-x'></i></h3></div> */}
                                            </div>
                                        </Stack>
                                    </div>

                                    <div className='  p-1'>
                                        <Stack direction="horizontal" gap={3}>
                                            {/* <i className='las la-4x la-info-circle me-1'></i> */}
                                            <i className='la-info la la-3x m-2 me-1 p-2 bg-dark  text-white rounded-circle'></i>
                                            <div className="p-0">
                                                <b>Information transparency</b><br />
                                                Access to critical information such as number of candidates interviewing, previous candidate feedback, and much more.

                                            </div>
                                            <div className='ms-auto relative d-none d-lg-block'>
                                                <div className="p-2 ms-auto"><i className='las la-2x la-check-circle text-success me-1'></i></div>
                                                {/* <div className="p-2"><h3 className='text-danger'><i className='fi fi-rr-x'></i></h3></div> */}
                                            </div>
                                        </Stack>
                                    </div>


                                    {/* <div className='p-1'>
                                    <Stack direction="horizontal" gap={3}>
                                        <i className='las la-4x la-toolbox me-1'></i>
                                        <div className="p-0">
                                            <b>Recruiter tools</b><br />
                                            Whether you're a seasoned recruiter or a first-time recruiter, MR has all the tools to help you succeed.
                                        </div>

                                        <div className='ms-auto relative d-none d-lg-block'>
                                            <div className="p-2 ms-auto"><i className='las la-4x la-check text-success me-1'></i></div>
                                        </div>
                                    </Stack>
                                </div> */}

                                </Stack>
                            </div>
                        </Col>
                    </Row>

                </Container>



            </div>

        </div>
    );
};

export default HomeSection3;
