import React, { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import CustomMultiSelect from './CustomMultiSelect';

const GenericForm = ({ template, onSubmit }) => {
  // const [formData, setFormData] = useState({});


  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const initialFormData = {};

    template.fields.forEach((field) => {
      initialFormData[field.name] = field.value || '';
    });

    setFormData(initialFormData)

  }, [template]);



  const handleTagsRemove = (field, value_to_remove) => {
    const tags = formData[field];

    const filteredArray = tags.split(",").filter(item => item !== value_to_remove);

    const tagsValue = filteredArray.join(",")

    // if (tagsValue !== '') {
    setFormData((prevData) => ({
      ...prevData,
      [field]: tagsValue  //[...(prevData[name] || ""), tagsValue],
      // tags: '', // Clear the input field
    }));
    // }
    // console.log(formData)

  };


  const handleTagsInputKeyDown = (e, name) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      // Convert formData.tags to a string if it's not
      // const tagsValue = formData.tags.toString().trim();
      const tagsValue = e.target.value.toString().trim();
      console.log(tagsValue)


      if (tagsValue !== '') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: prevData[name] != "" ? prevData[name] + "," + tagsValue : tagsValue  //[...(prevData[name] || ""), tagsValue],
          // tags: '', // Clear the input field
        }));
      }
      console.log(formData)
      e.target.value = ""
    }
  };



  const handleInputChange = (e) => {
    const { name, value, type, checked, options, multiple, files } = e.target;

    // if (name === 'tags') {
    //   // Split the input value by commas and remove leading/trailing spaces
    //   const tagsArray = value.split(',').map((tag) => tag.trim());
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [name]: tagsArray, // Store the tags as an array
    //   }));
    // } else 

    if (type === 'select-multiple') {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedOptions,
      }));
    } else if (type == 'range') {
      // {(e) => setSalaryRange([parseInt(e.target.value, 10), salaryRange[1]])}
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    } else if (type === 'radio') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      const newValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;

      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit(formData);
    }

    setValidated(true);
    e.preventDefault();
    
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate  validated={validated}>
        <div className=''>
          {template.fields.map((field) => (
            <div key={field.name}>
              {field.description ? (
                <b>
                  {field.description}
                </b>
              ) : (
                <></>
              )}

              <div key={field.name} className="form-floating mb-4">
                {field.type === 'link' && (
                  <a href="#"
                    onClick={field.onClick}
                  >
                    <span dangerouslySetInnerHTML={{ __html: field.label }} />
                  </a>
                )}

                {field.type === 'tags' && (
                  <>
                    <Form.Control
                      type="text"
                      id={field.name}
                      name={field.name}
                      // value={formData[field.name] || ''}
                      className="form-control text-black bg-white shadow-none border-1"
                      placeholder="Add tags (press Enter to add)"
                      // onKeyDown={handleTagsInputKeyDown}
                      onKeyDown={(e) => {
                        handleTagsInputKeyDown(e, field.name)
                      }}
                      required={field.required}
                    // onChange={handleInputChange}
                    />
                              
                    <div className='mt-2'>
                      {formData[field.name] && formData[field.name].split(",").map((tag) => (
                        <span key={tag} className="badge border border-dark text-dark me-2 mb-2">
                          {tag} <i onClick={() => { handleTagsRemove(field.name, tag) }} className='la la-close'></i>
                        </span>
                      ))}
                    </div>

                  </>
                )}

                {field.type === 'range' && (
                  <>

                    <div className='m-0'>{field.label} (${Intl.NumberFormat('en-US').format(parseInt(formData[field.name], 10))})</div>

                    <Form.Range
                      type="range"
                      id={field.name}
                      name={field.name}
                      min={field.min || "0"} 
                      max={field.max || "1000000"} 
                      step={field.step || "10000"} 
                      className='form-range'
                      value={parseInt(formData[field.name], 10) || parseInt(field.value, 10)}
                      onChange={handleInputChange}
                    />


                  </>
                )}




                {field.type === 'select' && field.dependsOn == undefined && (
                  <>
                    <select
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || field.value}
                      className="form-select text-black bg-white shadow-none border-1"
                      required={field.required}
                      onChange={handleInputChange}
                    >
                      <option value="">Select an option</option>
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </>
                )}

                {field.type === 'select' && field.dependsOn && (
                  <>
                    {/* {formData[field.dependsOn] === field.condition ? ( */}
                    {
                      field.condition.indexOf(formData[field.dependsOn]) !== -1 && (
                        <select
                          id={field.name}
                          name={field.name}
                          value={formData[field.name] || field.value}
                          className="form-select text-black bg-white shadow-none border-1"
                          required={field.required}
                          onChange={handleInputChange}
                        >
                          <option value="">Select an option</option>
                          {field.options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                  </>
                )}

                {field.type === 'icon-select' && (
                  <>
                    {field.label}
                    <CustomMultiSelect onSelectionChange={handleInputChange} options={field.options} />
                  </>
                )}

                {field.type === 'multi-select' && (
                  <select
                    id={field.name}
                    name={field.name}
                    multiple
                    style={{ minHeight: "100px" }}
                    value={formData[field.name] || []}
                    className="form-select text-black bg-white shadow-none border-1"
                    required={field.required}
                    onChange={handleInputChange}
                  >
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}

                {field.type === 'text' && (
                  <>
                  <Form.Control
                    type="text"
                    id={field.name}
                    name={field.name}
                    value={formData[field.name] || field.value} // Use formData to set the value
                    className="form-control text-black bg-white shadow-none border-1"
                    required={field.required}
                    placeholder=""
                    onChange={handleInputChange}
                  />
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                              {/* <Form.Control.Feedback type="invalid">
              Please choose a username.
            </Form.Control.Feedback> */}
</>
                )}


                {field.type === 'password' && (
                  <input
                    type="password"
                    id={field.id || field.name}
                    name={field.name}
                    value={formData[field.name] || field.value} // Use formData to set the value
                    className="form-control text-black bg-white shadow-none border-1"
                    required={field.required}
                    placeholder=""
                    onChange={handleInputChange}
                  />
                )}

                {field.type === 'textarea' && (
                  <textarea
                    id={field.name}
                    name={field.name}
                    rows={field.size || 8}
                    style={{ height: "auto" }}
                    value={formData[field.name] || field.value} // Use formData to set the value
                    className="form-control  text-black bg-white shadow-none border-1"
                    placeholder=""
                    required={field.required}
                    onChange={handleInputChange}
                  ></textarea>
                )}
                {field.type === 'checkbox' && (
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id={field.name}
                      name={field.name}
                      className="form-check-input"
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor={field.name}>
                      {/* {field.label} */}
                      <span dangerouslySetInnerHTML={{ __html: field.label }} />
                    </label>
                  </div>
                )}

                {field.type === 'radio' && (
                  <>
                    <h6 htmlFor={field.name} className="form-label text-black">
                      {field.label}
                    </h6>
                    <div className="form-check">
                      {field.options.map((option) => (
                        <div key={option.value} className="form-check">
                          <input
                            type="radio"
                            id={`${field.name}-${option.value}`}
                            name={field.name}
                            value={option.value}
                            className="form-check-input"
                            onChange={handleInputChange}
                          />
                          <label className="form-check-label" htmlFor={`${field.name}-${option.value}`}>
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {field.type === 'file' && (
                  <input
                    type="file"
                    id={field.name}
                    name={field.name}
                    className="form-control text-black bg-white shadow-none border-1"
                    accept={field.accept || '*/*'}
                    onChange={handleInputChange}
                  />
                )}

                {field.type !== 'link' && field.type !== 'range' && field.type !== 'checkbox' && field.type !== 'icon-select' && field.type !== 'radio' && (
                  <label htmlFor={field.name} className="form-label small">
                    <span dangerouslySetInnerHTML={{ __html: field.label }} />
                  </label>
                )}
              </div>
            </div>
          ))}

        </div>

        {template.button ? (
          <Button type="submit" className="w-100 btn rounded-2" variant={template.button.variant}>
            {template.button.label}
          </Button>
        ) : (
          <button type="submit" className="w-100 btn btn-secondary text-white rounded-5">
            Submit
          </button>
        )}
      </Form>
    </>
  );
};

export default GenericForm;
