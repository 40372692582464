import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, ListGroup, ListGroupItem, Card, Alert, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../Utils/api';
import Markdown from '../..//Widgets/Markdown';
import CandidateWidget from '../../Widgets/CandidateWidget';
import { useParams } from 'react-router-dom';
import BannerMessage from '../BannerMessage';
import { setJobs } from '../../Store/dataSlice';
import { useHistory } from "react-router-dom";
import JobInfoCard from '../../Widgets/JobInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../Widgets/Avatar';


const CandidateDetails = ({ candidate_id, embed }) => {
    const history = useHistory();

    const [candidate, setCandidate] = useState(null)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [deletionConfirmation, setDeletionConfirmation] = useState(null);

    const { id } = useParams();
    const [candidateID, setCandidateID] = useState(null)

    // const [jobsApplied, setJobsApplied] = useState(null)

    const user = useSelector((state) => state.auth.user);

    useEffect(() => {

        // if (candidate != null) {
        //     return;
        // }
        console.log(candidate_id)

        if (candidate_id !== undefined) {
            setCandidateID(candidate_id)
            getCandidateDetails(candidate_id)
        } else {
            setCandidateID(id)
            getCandidateDetails(id)
        }



    }, [id, candidate_id]);


    const getCandidateDetails = async (candidate_id) => {
        console.log(candidate_id)

        var apiURL = '/candidates/' + candidate_id

        api.get(apiURL)
            .then((response) => {
                setCandidate(response.data || null)
                // getJobsApplied(candidate_id)
            })
            .catch((error) => {
                // setCandidateDetails([])
                console.error('Error fetching login :', error);
                setError(error.error)
            });

    }

    // const getJobsApplied = async (candidate_id) => {
    //     console.log(candidate_id)

    //     var apiURL = '/candidates/' + candidate_id + '/jobs'

    //     api.get(apiURL)
    //         .then((response) => {
    //             console.log(response.data);
    //             setJobsApplied(response.data || [])
    //         })
    //         .catch((error) => {
    //             // setCandidateDetails([])
    //             console.error('Error fetching login :', error);
    //             setError(error.error)
    //         });

    // }


    return (
        <div>


            {message && (
                <Alert variant='success'>{message}</Alert>
            )}

            {error && (
                <Alert variant='danger'>{error}</Alert>
            )}



            {deletionConfirmation && (
                <Alert variant='danger'>
                    <Stack direction='horizontal' gap={3}>
                        <div>Are you sure you want to delete this job?</div>
                        <div className='ms-auto'>
                            <button
                                onClick={() => {
                                    // deleteJob(deletionConfirmation);
                                    setDeletionConfirmation(null);
                                }}
                                className='btn btn-sm btn-danger ms-2'
                            >
                                Confirm
                            </button>
                            <button
                                onClick={() => setDeletionConfirmation(null)}
                                className='btn btn-sm btn-dark border-0 ms-2'
                            >
                                Cancel
                            </button>
                        </div>
                    </Stack>
                </Alert>
            )}

            {candidate && !embed && (
                <BannerMessage heading={candidate.name} subheading={candidate.role} />
            )}

            {/* <Stack gap={2}> */}


            <Container className={embed ? 'p-0' : ''}>
                <Row className='display-flex'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {candidate && (
                            <>


                                <Card>
                                    {embed && (


                                        <Card.Header>
                                            <Stack direction='horizontal' className='align-items-center' gap={3}>
                                                <div>
                                                    <h6 className='text-black m-0 p-0'><b>{candidate.name}</b></h6>
                                                    <p className='m-0 p-0'>{candidate.role}</p>
                                                </div>
                                                <div className='ms-auto'>

                                                    <a href="#" className="btn btn-transparent btn-sm shadow-none" onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(`/candidates/${candidate.id}`)
                                                    }} ><span className='badge bg-primary'><i className='fa fa-link'></i> Permalink</span></a>
                                                    {user.type == "recruiter" && (
                                                        <a href="#" className="btn btn-transparent  btn-sm shadow-none" onClick={(e) => {
                                                            e.preventDefault();
                                                            history.push(`/candidates/${candidate.id}/edit`)
                                                        }} ><span className='badge bg-primary'><i className='fa fa-link'></i> Edit</span></a>
                                                    )}
                                                </div>

                                            </Stack>
                                        </Card.Header>

                                    )}

                                    <Card.Body>

                                        <Stack direction='horizontal' gap={3}>
                                            {/* <img style={{ width: "120px", height: "120px" }} src={candidate.profile_image} className="img-fluid rounded-2" alt="img" /> */}

                                            {/* {candidate.profile_image != "" ? <img style={{ width: "120px", height: "120px" }} src={candidate.profile_image} className="img-fluid rounded-2" alt="img" /> :
                                                <div style={{ width: "120px", height: "120px" }} className='  text-center bg-primary text-white rounded'><i style={{ lineHeight: "120px" }} className='la-4x la la-user'></i> </div>
                                            } */}

                                            <Avatar
                                                style={{ width: "120px", height: "120px", borderRadius: "5%", fontSize: "24px" }}
                                                src={candidate.profile_image}
                                                name={candidate.name}
                                            />


                                            <div>
                                                <div>${candidate.min_salary} - ${candidate.max_salary} / {candidate.salary_type}</div>

                                                <div className='small p-0'>
                                                    <Stack direction='horizontal' gap={1}>
                                                        <i className='las la-map-marker me-1'></i>
                                                        <span>{candidate.location}</span>
                                                    </Stack>
                                                </div>

                                                <a href={candidate.linkedin_url} className='small p-0'>
                                                    <Stack direction='horizontal' gap={1}>
                                                        <i className='lab la-linkedin-in me-1'></i>
                                                        <span>LinkedIn</span>
                                                    </Stack>
                                                </a>
                                                <a href="#" className='small p-0'>
                                                    <Stack direction='horizontal' gap={1}>
                                                        <i className='las la-envelope me-1'></i>
                                                        <span>{candidate.email}</span>
                                                    </Stack>
                                                </a>
                                                <div className='small p-0'>
                                                    <Stack direction='horizontal' gap={1}>
                                                        <i className='las la-phone me-1'></i>
                                                        <span>{candidate.phone}</span>
                                                    </Stack>
                                                </div>

                                                {/* <div><span className='badge bg-info me-2 mt-2 mb-2'>{candidate.email}</span></div> */}
                                            </div>
                                        </Stack>

                                        <div className='mt-2'>
                                            {candidate.skills && candidate.skills.split(",").map((tag) => (
                                                <span key={tag} className="badge bg-info text-dark me-2 mb-2">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>

                                        <div className='mt-3'>
                                            <Markdown content={candidate.bio} />
                                        </div>

                                    </Card.Body>
                                </Card>
                            </>
                        )}

                    </Col>

                </Row>

                {embed ? "" :
                    <Row className='display-flex mt-3'>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                            <Card>
                                <Card.Header><strong>Jobs Submitted</strong></Card.Header>
                                {candidate && (
                                    <>
                                        {!candidate.job_candidates && (<Card.Body><div className='text-muted'><b>No Jobs Applied Yet!</b></div></Card.Body>)}



                                        {candidate.job_candidates && (

                                            <ListGroup variant="flush">
                                                {candidate.job_candidates.map((job) => (
                                                    <ListGroupItem key={job.job.id} >
                                                        <div className=' mb-2 mt-2'>
                                                            {/* <Stack direction="horizontal" className='align-top' gap={3} > */}
                                                                <div>
                                                                <Badge className='me-auto mt-2 float-end' variant="info">{job.stage.toUpperCase()}</Badge>
                                                                    <div className='me-auto'><JobInfoCard as="h6 text-bold" job={job.job} /></div>
                                                                    {/* <div className='text-muted small'>{job.job_type} | {job.role_type} | {job.location} | ${job.min_salary} - ${job.max_salary}</div> */}
                                                                </div>

                                                            {/* </Stack> */}



                                                        </div>

                                                    </ListGroupItem>
                                                ))}

                                            </ListGroup>
                                        )}
                                    </>
                                )}


                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                            <Card>
                                <Card.Header>Recommended Jobs</Card.Header>
                                <Card.Body><div className='text-muted'><b>Feature in progress!</b></div></Card.Body>
                            </Card>
                        </Col>

                    </Row>
                }
            </Container>


            {/* </Stack> */}
        </div>
    );
};

export default CandidateDetails;
