import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import GenericForm from './GenericForm';
import api from '../Utils/api';
import { useHistory } from 'react-router-dom';
import { Modal, Stack, Card, Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BannerMessage from './BannerMessage';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner
import Client from './Client/Client';
import Markdown from '../Widgets/Markdown';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../Store/authSlice';
import ListCandidates from './Recruiter/ListCandidates';
import CandidateWidget from '../Widgets/CandidateWidget';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CandidatePipeline from './CandidatePipeline';
import { Link } from 'react-router-dom';
import { getJobDetails, getJobs } from '../Store/dataSlice';
import JobCompensation from './JobCompensation';
import JobInfoCard from '../Widgets/JobInfoCard';
import DateTime from '../Widgets/DateTime';

const JobDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const [jobDetails, setJobDetails] = useState(null)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)

    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const prevIdRef = useRef();

    useEffect(() => {
        if (id !== prevIdRef.current) {
            // Prevent double call by updating the previous id
            prevIdRef.current = id;
            getData()
        }


    }, [id]);

    const jobs = useSelector((state) => state.data.jobs);


    useEffect(() => {
        if (jobs == null) {
            return
        }

        console.log(jobs)
        var _jobDetails = jobs.find((job) => job.id === id);
        setJobDetails(_jobDetails)
    }, [jobs]);

    const getData = async () => {
        if (jobs === null) {
            await dispatch(getJobs());
        }



        return


        try {
            // dispatch(setLoading(true));
            await api.get('/jobs/' + id)
                .then((response) => {
                    console.log(response.data)
                    setJobDetails(response.data)
                })
                .catch((error) => {
                    console.error('Error fetching login :', error);
                    setError(error.error)
                });
            // dispatch(setLoading(true));

        } catch (error) {
            // dispatch(setLoading(false));
            console.error('Error fetching items:', error);
        }

        dispatch(setLoading(false));
    };

    const addCandidateToPipeline = async (candidate) => {
        console.log("Candidate Selected for Job", candidate)

        var jobCandidate = {
            "candidate_id": candidate.id,
        }

        api.post(`/jobs/${id}/candidates`, jobCandidate)
            .then(response => {
                const data = response.data;
                console.log(data);

                setMessage("Your candidate was successfully submitted")
                // setSelectedCandidates(selectedCandidates => [candidate, ...selectedCandidates]);
                setShowModal(false)

            })
            .catch(error => {
                console.error('Error creating workflow:', error)
                setError(error.response.data)
            });
    }




    return (
        <>

            {/* <Modal show={showModal} size="xl" backdrop="static" keyboard={false} onHide={() => { setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title><h4 className='m-0 p-0'><b>Candidate Pipeline</b></h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListCandidates onSelect={addCandidateToPipeline} />
                    <CandidatePipeline />
                </Modal.Body>
            </Modal> */}

            <BannerMessage heading={jobDetails ? jobDetails.title : ""} subheading={jobDetails ?
                <div><DateTime timestamp={jobDetails.created_at} /></div>
                : ""}
                menu={jobDetails && (user.type == "recruiter" || user.type == "employer") ? <Link to={`/jobs/${jobDetails.id}/candidates/review`} className="btn btn-primary floating_button">
                    {user.type == "employer" ? <i className='la la-users la-1x'></i> : <i className='la la-user-plus la-1x'></i>}</Link>
                    : ""}
            />
            <Container className='mb-3'>

                {message && (
                    <div className='text-success p-3'><b>{message}</b></div>
                )}

                {error && (
                    <div className='text-danger p-3'><b>{error}</b></div>
                )}

                {jobDetails && (
                    <>
                        <Row className='display-flex '>


                            <Col className='mb-3' xl={9} lg={8} md={12} sm={12} xs={12}>

                                <Card>
                                    <Card.Body>

                                        <div className=''><JobInfoCard as="hidden" job={jobDetails} /></div>
                                        <div className=''>
                                            <Markdown content={jobDetails.additional_details} />
                                        </div>


                                    </Card.Body>
                                    {/* <Card.Footer>
                                    
                                    </Card.Footer>                                     */}
                                </Card>
                                <a href={jobDetails.url} target="_blank" className='mt-3 btn w-100 btn-dark'><b>More Details</b></a>



                            </Col>

                            <Col xl={3} lg={4} md={12} sm={12} xs={12}>
                                <div className=''>
                                <div className='mb-3'>
                                    <Client client={jobDetails.client} />
                                </div>

                                {user.type == "employer" && (
                                    <Card>
                                        <Card.Header><strong>Need Help?</strong></Card.Header>
                                        <Card.Body>
                                            Earn comission based on the stage your candidate is in.


                                            {/* <Button onClick={() => { setShowModal(true) }} variant="secondary text-white w-100 mt-5 mb-3">Submit Candidate</Button> */}

                                            {/* <Link to={`/jobs/${jobDetails.id}/candidates/review`} className="btn btn-primary text-white w-100 mt-3 mb-3">Review Candidates</Link> */}
                                        </Card.Body>

                                    </Card>
                                )}

                                {user.type == "recruiter" && (

                                    <div className=''>
                                        <JobCompensation />
                                        {/* <Link to={`/jobs/${jobDetails.id}/candidates/review`} className="btn btn-primary text-white w-100 mt-3 mb-2">Start Recruiting</Link> */}


                                        {/* <Button onClick={() => { setShowModal(true) }} variant="secondary text-white w-100 mt-5 mb-3">Submit Candidate</Button> */}



                                    </div>
                                )}
                                </div>
                            </Col>

                        </Row>
                    </>
                )}


            </Container>
        </>
    );
};

export default JobDetails;
