// import React from 'react';

import ReactDOM from 'react-dom/client';

import Blog from './Blog/Blog';
import Home from './Home/Home';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import React, { useEffect } from 'react';

import NavbarTop from './Common/NavbarTop';
import Footer from './Common/Footer';
import WorkflowDetails from './Workflow/WorkflowDetails';
import Workflows from './Workflow/Workflows';
import DashboardWorkflow from './Workflow/DashboardWorkflow';
import Dashboard from './App/Dashboard';
import AdminPage from './Admin/AdminPage';
import CreateJob from './App/Client/CreateJob';
import JobDetails from './App/JobDetails';
import Login from './Login/Login';
import { useSelector } from 'react-redux';
import AuthenticatedRoute from './AuthenticatedRoute';
import './theme.scss';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider';


import store from './Store/Store';
import Privacy from './Pages/Privacy';
import AboutUs from './Pages/AboutUs';
import CreateCandidate from './App/Recruiter/CreateCandidate';
import Candidates from './App/Recruiter/Candidates';
import CandidateDetails from './App/Recruiter/CandidateDetails';
import ReviewCandidates from './App/ReviewCandidates';
import logo from './assets/logo_1.png'
import { Col, Container, ListGroup, ListGroupItem, Navbar, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import CandidateProfile from './App/Candidate/CandidateProfile';
import WelcomeMessageSide from './App/WelcomeMessageSide';
import NavbarSide from './Common/NavbarSide';
import Agreement from './Pages/Agreement';
import Terms from './Pages/Terms';
import ScrollToTop from './ScrollToTop';
import EditProfile from './App/EditProfile';
import EditJobDetails from './App/Client/EditJobDetails';
// import { fetchUserProfile } from './Store/authSlice';
// import { useDispatch } from 'react-redux';

function Content() {

  // const dispatch = useDispatch();

  // useEffect(() => {
  //     dispatch(fetchUserProfile());
  // }, [dispatch]);


  const loggedIn = useSelector((state) => state.auth.loggedIn);



  return (
<ThemeProvider
  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
  minBreakpoint="xxs"
>



      <div id="content" className={` ${loggedIn ? 'open' : 'closed'}`}>
        <NavbarTop />
        <NavbarSide />


        <div className='min-vh-100'>

          <div className="bg-image"></div>


          <ScrollToTop />
          <Switch>
            
          <AuthenticatedRoute path="/" exact component={Dashboard} />

            {loggedIn && (
              <>
            <Route path="/recruiter/candidates" exact component={Candidates} />
            <Route path="/recruiter/create" exact component={CreateCandidate} />
            <Route path="/candidates/:id" exact component={CandidateDetails} />
            <Route path="/candidates/:id/edit" exact component={CreateCandidate} />


            <Route path="/candidate/profile" exact component={CandidateProfile} />

            <Route path="/profile" exact component={EditProfile} />

            <Route path="/employer/create" exact component={CreateJob} />
            <Route path="/jobs/:id" exact component={JobDetails} />
            <Route path="/jobs/:id/edit" exact component={CreateJob} />
            <Route path="/jobs/:id/candidates/review" exact component={ReviewCandidates} />

            <Route path="/linkedin" exact component={Home} />              
            <Route path="/blog" component={Blog} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/about" component={AboutUs} />
            <Route path="/agreement" component={Agreement} />
            <Route path="/terms" component={Terms} />

              </>
            )}
            

            <Route path="/blog" component={Blog} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/about" component={AboutUs} />
            <Route path="/agreement" component={Agreement} />
            <Route path="/terms" component={Terms} />

            {/* <Route
            path="/workflows"
            exact
            render={(props) => {
              const jwtToken = localStorage.getItem('jwtToken');
              const user = JSON.parse(localStorage.getItem('user'));
              const profile = localStorage.getItem('profile');
              const status = localStorage.getItem('status');
              return <DashboardWorkflow {...props} user={user} profile={profile} status={status} />;
            }}
          />

          <Route path="/workflow/:uuid" component={WorkflowDetails} /> */}
            <Route exact path="/login" component={Login} />
            <Route path="/login/:type" component={Login} />

            {/* <Route path="/admin" exact component={AdminPage} /> */}

          </Switch>

        </div>
        <div id="footer" className="bg-dark text-white p-5">
          <section className="footer-section container">
            <Footer />
          </section>
        </div>




      </div>
      
    {/* <div className='text-center small p-3'>
        <a href="/#/terms">Terms & Conditions</a> | <a href="/#/privacy">Privacy Policy</a> | <a href="/#/agreement">Recruiting Agreement</a>
      </div> */}

    </ThemeProvider>
  );
}

export default Content;
