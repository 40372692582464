import axios from 'axios';
// import {  useSelector } from 'react-redux';

import store from '../Store/Store';


const api = axios.create({
  baseURL: '/api', // Adjust the base URL to your API endpoint
});



api.interceptors.request.use((config) => {
  // const token = localStorage.getItem('jwtToken'); // Fetch the JWT token from storage
  // const jwtToken = useSelector((state) => state.auth.token);
  const state = store.getState();
  const jwtToken = state.auth.token;


  if (jwtToken) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`; // Add the token to the Authorization header
  }
  return config;
});


export const handleFileUpload = async (file) => {
  console.log(file)
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await api.post(`/upload`, formData);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}; 

export const getCandidates = async () => {
  try {
    const response = await api.get(`/candidates`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getJobCandidates = async (jobID) => {
  try {
    const response = await api.get('/jobs/' + jobID + "/candidates");
    return response.data || [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const removeCandidateFromPipeline = async (jobID, candidateID) => {

  try {
    const response = await api.delete(`/jobs/${jobID}/candidates/${candidateID}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
  // dispatch(setSubmittedData(jobID));
  // await api.delete(`/jobs/${jobID}/candidates/${candidate.id}`)
  //     .then(response => {
  //         const data = response.data;
  //     })
  //     .catch(error => {
  //         console.error('Error creating workflow:', error)
  //     });
  
  //     console.log("DISPATCH!!!!")
      
}


export default api;
