import React, { useState, useEffect, useRef } from 'react';
import GenericForm from './GenericForm';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Stack, Button, Offcanvas, ListGroup, ListGroupItem, Card, Alert } from 'react-bootstrap';
import api from '../Utils/api';
import { Link } from 'react-router-dom';
import TruncatedDescription from '../Widgets/TruncatedDescription';
import CandidatePipeline from './CandidatePipeline';
import SubmitCandidate from './SubmitCandidate';
import BannerMessage from './BannerMessage';
import Markdown from '../Widgets/Markdown';
import Client from './Client/Client';
import { useParams } from 'react-router-dom';
import { setSubmittedData } from '../Store/submitCandidateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../Store/dataSlice';
import JobCompensation from './JobCompensation';
import JobPostsCards from './JobPostsCards';
import JobInfoCard from '../Widgets/JobInfoCard';
import CandidatePipelineAlt from './CandidatePipelineAlt';
import { useHistory } from "react-router-dom";


const ReviewCandidates = () => {
    let history = useHistory();

    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.data.jobs);

    // const [jobs, setJobs] = useState(null)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [deletionConfirmation, setDeletionConfirmation] = useState(null);
    const persona = useSelector((state) => state.auth.persona);
    const [showModal, setShowModal] = useState(false);


    const [selectedJob, setSelectedJob] = useState(null)


    const { id } = useParams();
    const prevIdRef = useRef();


    // useEffect(() => {
    //     if (id !== prevIdRef.current) {
    //         // Prevent double call by updating the previous id
    //         prevIdRef.current = id;
    //         getData()
    //     }
    // }, [jobs]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await dispatch(getJobs());
    }

    useEffect(() => {
        if (id !== "-") {
            for (var job in jobs) {
                if (jobs[job].id == id) {
                    selectJob(jobs[job])
                }
            }
        } else {
            setShowModal(true)
        }
    }, [jobs]);

    const selectJob = (job) => {

        setShowModal(false);
        setSelectedJob(job);
        dispatch(setSubmittedData(job.id));

    }


    // const onCandidateAdded = () => {
    //     console.log("onCandidateAdded")
    // }

    return (
        <div>

            {/* <BannerMessage 
            menu={<Button onClick={() => { setShowModal(true) }} variant="info text-dark"><i className='la la-bars'></i></Button>}
            heading={selectedJob ? selectedJob.title : "Review Submissions" }
            subheading={selectedJob ? <div>{selectedJob.location} | {selectedJob.job_type} | {selectedJob.role_type} </div> : "Review all the candidates submitted to your role by millions of recruiters"}
             /> */}

            <BannerMessage
                menu={<Button onClick={() => { setShowModal(true) }} variant="primary floating_button "><i className='la la-sync'></i></Button>}
                heading="Review Submissions"
                subheading="Review all the candidates submitted to your role by millions of recruiters"
            />

            {message && (
                <Alert variant='success'>{message}</Alert>
            )}

            {error && (
                <Alert variant='danger'>{error}</Alert>
            )}


            <Offcanvas show={showModal} placement="end" onHide={() => { setShowModal(false) }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Select Job</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {/* <div className={`sidebar ${!selectedJob ? 'open' : ''}`}>
            <h5 className='p-2 '>Job Postings</h5> */}

                    <JobPostsCards size="lg" selectedJob={selectedJob} onClick={(job) => {
                        setShowModal(false);
                        history.push(`/jobs/${job.id}/candidates/review`)
                        selectJob(job);

                    }} />

                </Offcanvas.Body>
            </Offcanvas>



            <Container>

                <Row>
                    {/* <Col  xs={12} md={12} lg={3} xl={2}>
                    <JobPostsCards size="lg" selectedJob={selectedJob} onClick={(job) => {
                        setShowModal(false);
                        selectJob(job);
                    }} />
                    </Col> */}
                    <Col xs={12} md={12} lg={12} xl={12}>
                        {selectedJob && (
                            <>

                                <div className=''>
                                    {/* <Client id={selectedJob.type} /> */}

                                    {/* <div className='bg-white rounded-2 shadow-sm  p-3'>
                                        <Stack direction='horizontal' gap={1} className='align-top'>
                                            <div className=''>
                                                <h4 className='text-black m-0 p-0'>
                                                    <Link style={{ textDecoration: "none" }} className='text-black' to={`/jobs/${selectedJob.id}`}>
                                                        <b>{selectedJob.title}</b>
                                                    </Link>
                                                </h4>
                                               <JobInfoCard job={selectedJob} />


                                                <div className='mt-3'>Starbucks ACME Inc.</div>
                                                <div>Posted Jan 1st, 2023</div>
                                                <div>Last Modified on Jan 1st, 2023</div>
                                                <br />
                                                <a href="#" className='text-decoration-underline'>Need help? Get Help</a>
                                            </div>

                                            <div style={{ maxWidth: "320px", minWidth: "320px" }} className='ms-auto border-start p-3'>
                                                {persona == "recruiter" && (
                                                    <div >
                                                        <JobCompensation />
                                                    </div>
                                                )}

                                                {persona == "employer" && (
                                                    <div className='mt-3 bg-primary text-white  shadow-sm rounded-2 p-3'>
                                                        <b>What happens after your move a candidate? </b> We start the magic for you. Drag candidates from one stage to another and we will take care of the rest behing the scenes.
                                                    </div>
                                                )}



                                            </div>
                                        </Stack>


                                    </div> */}

                                    {/* <div className='mt-3 p-3 bg-white shadow-sm'><Markdown content={selectedJob.additional_details} /></div> */}

                                    <div className='h-100'>
                                        {/* <CandidatePipeline job={selectedJob} /> */}
                                        <CandidatePipelineAlt job={selectedJob} />
                                    </div>
                                </div>

                                {/* <Button variant="primary text-white w-100 mt-5 mb-3">Submit Candidate</Button> */}


                            </>
                        )}

                    </Col>
                </Row>

            </Container>

        </div>
    );
};

export default ReviewCandidates;
