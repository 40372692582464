// submitCandidateSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Initial state properties for the SubmitCandidate component
  // Add any other properties you want to share
  submittedData: null,
};

const submitCandidateSlice = createSlice({
  name: 'submitCandidate',
  initialState,
  reducers: {
    // Define actions to update the shared state
    setSubmittedData: (state, action) => {
      state.submittedData = action.payload;
    },
  },
});

export const { setSubmittedData } = submitCandidateSlice.actions;

export default submitCandidateSlice.reducer;
