import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LinkedInPage from '../Login/LinkedIn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Stack, Image, Container } from 'react-bootstrap';
import Avatar from './Avatar';
// import photo_1 from '../assets/photo_2.png'

const CandidateWidget = ({ candidate, onClick, onRemove }) => {

  // id={candidate.id} photo={candidate.profile_image} name={candidate.name} title={candidate.role} url={candidate.linkedin_url}
  const color = "bg-primary"

  return (
    <div className=''>
      <Stack className='vertical-stack align-items-top justify-content-top' gap={2} >
        <div className='' style={{ maxWidth: "65px", minWidth: "65px" }}  >
          <a href="#" className="text-black" onClick={(e) => { e.preventDefault(); if (onClick !== undefined) { onClick(candidate) } }}>
            {/* {candidate.profile_image != "" ? <Image className='w-100' src={candidate.profile_image} rounded alt="img" /> :
              <div className='w-100  text-center bg-dark text-white rounded p-3'><i className='la-2x la la-user'></i> </div>
            } */}


            <Avatar
              style={{ width: "100%",  borderRadius: "5%", fontSize: "24px" }}
              src={candidate.profile_image}
              name={candidate.name}
            />

          </a>
        </div>
        <div className='' >
          <p className='m-0 p-0'>
            <a href="#" className="text-black" onClick={(e) => { e.preventDefault(); if (onClick !== undefined) { onClick(candidate) } }}>
              {/* <Stack direction='horizontal' gap={3} className='w-100'> */}
              <div>
                <b>{candidate.name}</b>
                <div className='small'>{candidate.role}</div>
              </div>
              {/* <div>
                  <i className='las la-chevron-right'></i>
                </div>
              </Stack> */}
            </a>

            <Stack direction="vertical" gap={0} className='pt-1 text-start'>

              <div className='small p-0'>
                <Stack direction='horizontal' gap={1}>
                  <i className='las la-map-marker me-1'></i>
                  <span className='text-decoration-underline'>{candidate.location}</span>
                </Stack>
              </div>

              <a href={candidate.linkedin_url} className=' small p-0'>
                <Stack direction='horizontal' gap={1}>
                  <i className='lab la-linkedin-in me-1'></i>
                  <span className='text-decoration-underline'>LinkedIn</span>
                </Stack>
              </a>





            </Stack>



          </p>

        </div>

      </Stack>

      {/* {candidate.skills && 
      <div className='mt-2'>
      { candidate.skills.split(",").map((tag) => (
        <span key={tag} className="badge bg-info  text-dark me-2 mb-2">
          {tag}
        </span>
      ))}
    </div>
    } */}




    </div>
  );
};

export default CandidateWidget;
