import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';
import BannerMessage from '../App/BannerMessage';
import Markdown from '../Widgets/Markdown';

const Agreement = () => {

  const content = `

**Last updated: January 14th, 2024**

A contract ("Recruiting Contract") is formed between Million Recruiters LLC and the “Referrer” when the Referrer: 

1) Creates an account on Million Recruiters. Creating an account on Million Recruiters supersedes all previous recruiting agreements formed between Referrer and Million Recruiters (previous agreements between Referrer and Million Recruiters is effectively voided upon sign up). 
2) and accepts the Terms of Use as directed by Million Recruiters 

Only when a Recruiting Contract is formed between Million Recruiters and Referrer can the Referrer submit a “Referral” to Million Recruiters LLC. 

 

### 1. Referrals to Million Recruiters 

A “Referral” meets these conditions:  
1. Referrer has spoken to the candidate about the specific Job Post and confirmed interest. 
2. Referrer has provided accurate contact information for the candidate to Million Recruiters.  
3. Referrer has provided a current resume for the candidate to Million Recruiters. 
4. Referrer has explained why the candidate is a good fit.  
5. Referrer has completed all required steps to the best of their knowledge based on available information. 
6. The candidate is not a Duplicate Referral. 

 

### 2. Payment Terms 

If a Referral is successful within the Hiring Period, Referrer will be awarded the referral bonus. Key terms: 

- The total referral bonus amount will be paid depending on the specific recruiting agreement in place with the specific company the placement occurs. For example and in most agreements: in 3 installments across the 90-day Guarantee period. Million Recruiters debits bonus to Referrer’s account via Payment Provider according to the below schedule. 
    - 1/3 on 31st day of candidate's official start date 
    - Second 1/3 on 61st day of candidate's official start date 
    - Final 1/3 on 91st day of candidate's official start date 
- Referrer may withdraw rewards anytime after funds available.  
- Million Recruiters processes payouts by payment method of Referrer’s choice via Payment Provider. 

 

### 3. Relationship Scope  

Referrer is an independent contractor of Million Recruiters. This does not create an employment, partnership, or agency relationship. Referrer does not have authority to enter into contracts on behalf of Million Recruiters or Employer. Referrer acknowledges that Million Recruiters does not, in any way, supervise, direct, or control Referrers work in any manner. Million Recruiters does not set Referrers work hours and location of work. Million Recruiters does not provide Referrer with training or equipment needed for any Recruiting Contract or other activity. Million Recruiters will not deduct any amount for withholding, unemployment, Social Security, or other taxes as it would in the case of an employee. Referrer will be solely responsible for all tax returns and payments required to be filed with or made to any federal, state, or local tax authority, in any nation, with respect to any payment made by Million Recruiters to Referrer. Referrer, as an independent contractor, is free at all times to provide services to persons or businesses other than Million Recruiters, including any competitor of Million Recruiters. 

Referrer agrees not to contact Million Recruiters Employers (any company Million Recruiters is recruiting on behalf of) directly for the purpose of providing recruiting services if Referrer discovered such Employers' names via a Million Recruiters representative for a period of twelve (12) months after the Referral Date of the most recent Recruiting Agreement between Referrer and Million Recruiters. 

Violation of the above clause can lead to termination of this Agreement between Million Recruiters and the Referrer and/or legal action. 

### 4. Miscellaneous Terms 

Referrer will create and maintain records to document satisfaction of obligations under this Policy and any Recruiting Contract. Million Recruiters has the right to audit for compliance. 

### 5. Prohibited Activities  

- Contacting candidates improperly or misrepresenting companies 
- Ignoring instructions or feedback   
- Sharing confidential information 
- Contacting Million Recruiters’ employers (companies Million Recruiters is recruiting on behalf of) directly for the purpose of providing recruitment services 

By engaging with Million Recruiters’ platform, Referrer acknowledges and agrees to adhere to these terms. Failure to comply may result in agreement termination and/or legal action. 


`

  return (
    <>

    <BannerMessage heading="Independent Contractor Recruiting Agreement & Terms of Engagement" subheading="Last updated: January 14th, 2024" />


      <Container className='mb-5'>
    
        <Row className="g-4">
        <Col className=''>
          <Card>
            <Card.Body><Markdown content={content} /></Card.Body>
          </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Agreement;
