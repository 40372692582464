import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import api from '../Utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LinkedInPage from '../Login/LinkedIn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image, Container, ButtonGroup } from 'react-bootstrap';
import photo_1 from '../assets/photo_2.png'
import user_1 from '../assets/user_1.avif'
import user_2 from '../assets/user_2.avif'
import user_3 from '../assets/user_3.avif'
import user_4 from '../assets/user_4.avif'

import BoxAnimation from '../Widgets/BoxAnimation.js';

const HomeHero2 = () => {


  return (
    <>
      <div className='' >

        <div className="container ">
          <div className="row">
            <div className="col-md-12 col-sm-12  text-center">
              {/* <div className='text-center'><span className='bg-white rounded-pill p-2 ps-3 pe-3 mb-4 btn border-dark text-dark'><b>The all-in-one platform for hiring</b></span></div> */}
              <div className='lh-1 fs-5'><strong>The all-in-one platform for hiring</strong></div>
              <p className='fs-6 '>Amplify your quality and reach by leveraging the power of a million recruiters</p>
              <Row xs={12} md={12} lg={12} xl={12} className="g-4 mt-5">
                {/* Employer Callout */}
                <Col xs={12} md={12} lg={4} xl={4} className="col text-black  mb-1 icon-link icon-link-hover">
                  <div className='feature h-100'>
                  <div className='feature-inner'>
                  <div className="">
                    <div className="callout-icon">
                      {/* <i className="fi fi-rr-users" style={{ fontSize: "40px" }}></i> */}
                      <i className='las la-4x la-briefcase me-1 p-3 bg-dark text-white rounded-circle'></i>
                      {/* <Image roundedCircle thumbnail className='rounded' src="https://files.oaiusercontent.com/file-LOovHmnifCNQ25yIUkaihYej?se=2024-02-17T22%3A53%3A13Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3D289e3543-6116-4f5e-838d-1b1ffa518311.webp&sig=7zODLNCeyNgwGyKg5UA8IVjQp8dqW5hDcskGTBf%2Bow0%3D" /> */}

                    </div>
                    <div className="lh-sm mb-3 callout-title fs-5 mt-1 text-center">Built for<br /><span className='fw-bolder '>Employers</span></div>
                    <p className="fw-light">Tap into our open talent network to crowdsource and rapidly identify highly-skilled, ideal fit candidates.</p>

                    <Button href="/#/login/employer"  className='fs-6 h4 w-100' variant='dark'><span className=''>Start Hiring</span></Button>
                    <br/><a href="#" className='small text-decoration-underline'>Learn More</a>
                  </div>
                  </div>
                  </div>
                </Col>

                {/* Recruiter Callout */}
                <Col xs={12} md={12} lg={4} xl={4} className="col  text-black  mb-1 ">
                <div className='feature h-100'>
                {/* <div className="pricing-badge">
                        <span className="badge">Recruiters</span>
                    </div> */}
                  <div className='feature-inner'>
                  <div className="">
                    <div className="callout-icon">
                      {/* <i className="fi fi-rr-envelope" style={{ fontSize: "40px" }}></i> */}
                      <i className='las la-4x la-business-time me-1 p-3 bg-dark  text-white rounded-circle'></i>
                      {/* <Image roundedCircle thumbnail className='rounded' src="https://lh3.googleusercontent.com/bard/APmgjFvbJGRztj0jmLBf82-yqhzPBBLXNgEowjxCH7QTa4JFS8MzIEGi-UfbAKuyh0rGjkkeqZYC8G7ALFASYpt-BInwQQI2i2GQjsOhO7HesgnYM65syjwBJ98PGNS-1cK6gywGktyTnYcpJ79PLRQcJdX9mXCmap7f_ZPMr2N2bapvZXJ7VYnH6BlsB-cIc88AxMhL1VUiAUSx-KuuPzZecsqPFEcoFiyc1799XWDD_mpApVj8e63iXV3H7AGq3sXtvQmet_I8A6bPcJ5UsiwYKR4W6biCBnCWJLJ3J3lN4GYCTxZdOzAspytvLfyooAqU3YAeJILTeqYq2WjY_TA" /> */}
                    </div>
                    <div className="lh-sm mb-3 callout-title fs-5 mt-1 text-center">Powered by<br /><span className='fw-bolder '>Recruiters</span></div>
                    {/* <h5 className="callout-title  mt-1 text-center">Powered by<br /><span className='fw-bolder '>Recruiters</span></h5> */}
                    <p className="fw-light">Unlimited earning potential. Million Recruiters gives you the platform and resources to earn big commissions.</p>

                    <Button href="/#/login/recruiter" className='fs-6 h4 w-100' variant='dark'><span className=''>Start Recruiting</span></Button>
                    <br/><a href="#" className='small text-decoration-underline'>Learn More</a>
                  </div>
                  </div></div>
                </Col>

                {/* Candidate Callout */}
                <Col xs={12} md={12} lg={4} xl={4} className="col text-black  mb-1 ">
                <div className='feature h-100'>
                  <div className='feature-inner'>
                  <div className=" ">
                    <div className="callout-icon">
                      {/* <i className="fi fi-rr-note" style={{ fontSize: "40px" }}></i> */}
                      <i className='las la-4x la-users me-1 p-3 bg-dark  text-white rounded-circle'></i>

                      {/* <Image roundedCircle thumbnail className='rounded' src="https://files.oaiusercontent.com/file-MIVz4dfinzSFsk7oiKM2dlDw?se=2024-02-17T22%3A48%3A40Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Da8882a3e-c53c-4256-af93-6377df028988.webp&sig=bpcHE2xpTlqFdiEJILn4JdcJCIS3DT4p4AU5AT1e2q8%3D" /> */}

                    </div>
                    <div className="lh-sm mb-3 callout-title fs-5 mt-1 text-center">Empowering<br /><span className='fw-bolder '>Top Candidates</span></div>
                    {/* <h5 className="callout-title  mt-1 text-center">Empowering<br /><span className='fw-bolder primary'>Top Candidates</span></h5> */}
                    <p className="fw-light">Our algorithm does the hard work, so you get personalized results for free. Upload your resume – we'll do the rest.</p>
                    <Button href="/#/login/candidate" className='fs-6 h4 w-100' variant='light'><span className=''>Join Waitlist</span></Button>
                    <br/><a href="#" className='small text-decoration-underline'>Learn More</a>
                  </div>
                  </div></div>
                </Col>
              </Row>

            </div>
          </div>

          {/* <div className='text-center mt-4'>
            <h4>Learn More</h4>
            <ButtonGroup className=''>
              <Button variant="outline-dark" className=' p-2 ps-4 pe-4'>How does it work?</Button>
              <Button variant="outline-dark" className=' p-2 ps-4 pe-4'>Why Million Recruiters?</Button>
              <Button variant="outline-dark" className=' p-2 ps-4 pe-4'>Sign Up</Button>
            </ButtonGroup>
          </div> */}
        </div>
      </div>


    </>
  );
};

export default HomeHero2;
