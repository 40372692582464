import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import pointInPolygon from '@turf/boolean-point-in-polygon'; // Import pointInPolygon function

const MapPicker = ({ onLocationSelect }) => {
  useEffect(() => {
    // Create a map centered at a specific location and with an initial zoom level
    const map = L.map('map').setView([37.0902, -95.7129], 4);

    // Add a tile layer (e.g., OpenStreetMap) to the map
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    // Load GeoJSON data for the United States
    // axios.get('../assets/us-boundaries.geojson').then((response) => {
      const usGeoJson = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                46.052313958474194,
                30.15258905067337
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -116.15513620745864,
                -21.16451954957708
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -3.18821558582969,
                -76.97025651414386
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                146.33842826926872,
                30.384328054825396
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -80.79212570566175,
                53.249533977769005
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -116.79017692936529,
                4.681514361159795
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -59.00177540485204,
                60.88241152355081
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -15.879365998525437,
                44.57157857278351
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -26.10207148521792,
                45.06682070380695
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -96.80965289002053,
                -37.39746747554567
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                20.377321176501315,
                30.311820378466813
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -126.12920644360133,
                27.590535859922007
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -158.79679200462806,
                67.86360634492013
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -85.85134460511253,
                -17.001605156175877
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -16.654959444349878,
                -43.78261353494059
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -103.09771960361968,
                -45.12893305207268
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                66.46662637879349,
                63.553403937229746
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -22.671485231244468,
                -10.632109786417425
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -78.77710758096184,
                -50.19339908155893
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                125.21571565096426,
                41.66692303047879
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                66.88235394656392,
                -88.0054646080729
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -69.21554564373776,
                -13.654050485433915
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -98.7841655276479,
                29.73505489588723
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                67.71235110339416,
                -80.38741150866304
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -52.113479909211726,
                27.87415855509145
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                170.97941001413733,
                45.18006460709805
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -4.230540125413889,
                -29.914228394167548
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                78.53656561721726,
                38.30308909962397
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                91.24264619021692,
                -73.13207286767705
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -173.05188440838927,
                -71.21977247789852
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -69.34796407337396,
                6.130947486501208
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -94.52344980370817,
                74.35483288283916
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -179.67706919204733,
                44.115865389701014
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                62.00456453806354,
                0.4296806817611287
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -66.59206215957785,
                3.589124663273995
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -70.64086265202424,
                -84.33539443327058
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                144.6763216805714,
                82.67634860511656
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -38.11472795694645,
                27.43462542145985
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -14.129546160878679,
                0.07500151458071258
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -51.50949887188112,
                17.374454470701583
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -119.74411393003362,
                66.29698467729301
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                60.396753874765075,
                89.27188840447329
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -103.44295758550862,
                -54.88122143871954
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -60.876778699221354,
                75.34570934171349
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                44.356575623766986,
                -8.378920495124135
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                150.1554682129984,
                84.4480290737241
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -60.12884842364669,
                -78.26763710186277
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -85.00619785675136,
                -45.75107369407229
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -65.91783258358313,
                43.02496771273259
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                53.849714346592215,
                6.308632340345284
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -66.41477849028271,
                -56.837104113620576
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -165.48300676697636,
                -26.52542716625316
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                144.17481622027776,
                0.40390281863113664
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -154.78811637047292,
                -30.98496952023696
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -68.09729348719901,
                -88.60445490510247
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -27.422470104245402,
                32.2851223098735
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                72.3040487428937,
                18.6320297139234
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                35.10521056133573,
                -32.622199247687874
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                48.228668056252445,
                -87.08207913029027
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                3.5978140678503934,
                64.26586460569236
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                10.501808293254777,
                -8.395117734664378
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                96.5339563561989,
                48.25852889835008
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                176.64753873717288,
                -29.903188212689052
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                132.58028111846696,
                14.251094119239358
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                162.99418228242885,
                23.004701137222185
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -36.79341832439788,
                -6.41190216307288
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                4.6847919260531246,
                27.241573141938154
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                88.610856276179,
                -74.81627124067057
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -140.79873447622361,
                73.43714258022428
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                95.12809130976704,
                -45.37670916201178
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -105.51493627407767,
                -60.334207571598924
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -178.46002044439032,
                -68.98647564352935
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -115.54165969461151,
                0.24988938011762496
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                157.34186809382163,
                -15.705835310984032
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                165.84422142862246,
                -35.08084720206746
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                59.823894488189104,
                -15.064239519644683
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -153.48094268098916,
                30.30801398572677
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -169.23568394764482,
                -25.277675481095745
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                53.72498949242232,
                -67.35677321959353
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                10.52640462993601,
                -30.231951432674535
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -75.33176733829018,
                55.49234715839778
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -167.76476894059394,
                80.01931179413215
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                161.8051403394591,
                48.77347711941012
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                88.50781347042131,
                -86.9370353252287
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                58.88024179534379,
                -67.2818065050185
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -159.85469926438088,
                -80.37966428996184
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                53.200935562027134,
                -15.815489721920905
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                164.74300393965018,
                -16.917872819380527
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -10.621293787008277,
                72.60070263274035
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                141.36908697596905,
                -88.23085774359163
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -65.64873006403748,
                -33.400797536755384
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                152.31419251187324,
                81.69213184263167
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                112.48810567935838,
                -22.409762781930624
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                137.44035127411618,
                -32.21571578394969
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -62.9162602248385,
                66.05909289428286
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                127.94142705598445,
                -49.4180435992991
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -53.40069048802767,
                -12.291768874293098
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                107.50649810711785,
                59.033756192087914
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -103.02544379421357,
                -67.83903165503045
              ]
            },
            "properties": {}
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -42.53063885897777,
                -61.837999741172716
              ]
            },
            "properties": {}
          }
        ]
      };

      // Create a GeoJSON layer for the United States boundaries
      const usLayer = L.geoJSON(usGeoJson);

      // Add the United States layer to the map
      usLayer.addTo(map);

      // Add a click event listener to the map
      map.on('click', (e) => {
        // Check if the clicked location is within the United States boundaries
        const clickedLocation = {
            type: 'Point',
            coordinates: [e.latlng.lng, e.latlng.lat], // Ensure correct order
          };
          
        const isWithinUS = pointInPolygon(clickedLocation, usGeoJson);

        if (isWithinUS) {
          const location = {
            lat: e.latlng.lat,
            lng: e.latlng.lng,
          };
          // Pass the selected location to the parent component
          onLocationSelect(location);
        } else {
          // Notify the user that the location is outside the United States
          alert('Please select a location within the United States.');
        }
      });
    // });
  }, [onLocationSelect]);

  return <div id="map" style={{ height: '400px' }}></div>;
};

export default MapPicker;
