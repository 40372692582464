import React, { useState } from 'react';

const TruncatedDescription = ({ description, maxLength }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const truncatedText = description.substring(0, maxLength);
  const shouldTruncate = description.length > maxLength;

  return (
    <div className='small'>
      <div className={`description ${expanded ? 'expanded' : 'truncated'}`}>
        {expanded ? description : truncatedText}
      
      {shouldTruncate && (
        <span className=' ms-2 text-dark' href="#"  onClick={toggleExpand}>
          {expanded ? <i className='fi fi-rr-angle-up'></i> : <i className='fi fi-rr-angle-down'></i>}
        </span>
      )}
      </div>
    </div>
  );
};

export default TruncatedDescription;