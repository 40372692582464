import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo_1.png';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeMessageSide from '../App/WelcomeMessageSide';
import WelcomeMessage from '../App/WelcomeMessage';
import WelcomeMessageAlt from '../App/WelcomeMessageAlt';
import SupportCard from '../SupportCard';
import { Button } from 'react-bootstrap';
import { logoutAsync } from '../Store/authSlice';
import { useHistory } from 'react-router-dom';
import HomeHero1 from '../Home/HomeHero1';
import HomeHero2 from '../Home/HomeHero2';

function NavbarSide() {
  const history = useHistory();

  const [sidebarVisible, setSidebarVisible] = useState(true);
  // const persona = useSelector((state) => state.auth.persona);
  const user = useSelector((state) => state.auth.user);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  // const [status, setStatus] = useState(null);
  const status = useSelector((state) => state.auth.status);

  const dispatch = useDispatch();
  const sidebarClass = 'close';

  // document.body.classList.add(sidebarClass)

  const toggleSidebar = () => {
    const body = document.body;

    if (sidebarVisible) {
      body.classList.add(sidebarClass);
    } else {
      body.classList.remove(sidebarClass);
    }
    setSidebarVisible(!sidebarVisible)


    // setSidebarVisible(prevSidebarVisible => {
    //     if (prevSidebarVisible) {
    //         // If the sidebar is currently visible, remove the class from the body
    //         body.classList.remove(sidebarClass);
    //     } else {
    //         // If the sidebar is currently not visible, add the class to the body
    //         body.classList.add(sidebarClass);
    //     }

    //     // Return the updated state
    //     return !prevSidebarVisible;
    // });
  };


  useEffect(() => {
    // const s = localStorage.getItem('status')
    // const s = useSelector((state) => state.auth.status);
    // console.log(s)
    // setStatus(s)
  }, []);


  // // Event handler for the resize event
  // handleResize = () => {
  //   setSidebarVisible(true)
  //   // this.setState({
  //   //   windowWidth: window.innerWidth,
  //   // });
  // };


  const logout = async () => {
    await dispatch(logoutAsync({}));
    history.push('/');
    return
  };

  return (
    <>
      {/* // className={`${loggedIn ? '' : 'hidden'}`}> */}

      {/* d-lg-none d-md-none */}
      {/* <Button id="sidebar_menu_icon" style={{zIndex: "999999"}} className={`toggle-button  ${loggedIn ? '' : 'hidden'} ${sidebarVisible ? 'active' : ''}`} */}



      <div id="sidebar" className={`${loggedIn ? '' : 'hidden'} ${sidebarVisible ? 'show border-end' : 'hide'}`}>


        <div className=''>
          <Button variant='transparent' id="sidebar_menu_icon" style={{ zIndex: "999999px" }} className={`p-0 border-0 shadow-none  toggle-button  btn-sm ${loggedIn ? '' : 'hidden'} `}
            onClick={() => {
              toggleSidebar();
            }}
          ><i className='las la-bars la-1x'></i></Button>
        </div>


        {/* Toggle button for small screens */}
        {/* <button className={`toggle-button d-lg-none ${sidebarVisible ? 'active' : ''}`} onClick={toggleSidebar}>
        Toggle Sidebar
      </button> */}

        {/* Sidebar */}
        <div className='vh-100' style={{ position: "relative" }}>
          {/* <div className='p-3 mt-3 text-center mb-3'><span className=''><img src={logo} style={{ height: "70px" }} alt="Logo" /></span></div> */}

          {loggedIn ? <WelcomeMessageSide /> : <div className='text-center p-5'><img src={logo} className='w-100' /></div>}



          {/* <div className='mt-3 p-3'>
          <WelcomeMessageAlt />
          </div> */}

          {loggedIn ?
            <div className=''>
              <div className=''><NavLink exact className="nav-link text-black" to="/"><i className='las la-home me-2'></i><span>Dashboard</span></NavLink></div>
              {/* <div className='p-2 mb-1'><NavLink className="nav-link text-black" to="/employer/create"><i className='fa fa-briefcase me-2'></i>Post Job</NavLink></div> */}
              {/* Status is: {status} */}
              {status != null && status == "ACTIVE" ? <>
                {user.type == "recruiter" && (
                  <>
                    <div className=''>
                      <NavLink className=" nav-link text-black" to="/recruiter/candidates"><i className='las la-users me-2'></i><span>Candidates</span></NavLink>
                    </div>
                    {/* <div className=' ps-4 pt-1 mb-1 ms-4'>
                    <NavLink className=" nav-link text-black" to="/recruiter/candidates"><i className='las la-user-edit me-2'></i>Manage</NavLink>
                  </div>
                  <div className=' ps-4 pt-1 mb-1 ms-4'>
                    <NavLink className=" nav-link text-black" to="/recruiter/create"><i className='las la-user-plus me-2'></i>Add New</NavLink>
                  </div> */}
                    <div className=''>
                      <NavLink className="nav-link text-black" to="/jobs/-/candidates/review"><i className='las la-briefcase me-2'></i><span>My Submissions</span></NavLink>
                    </div>
                  </>
                )}
                {user && (
                  <>
                    {user.type == "employer" && (
                      <>
                        <div className=''>
                          <NavLink className="nav-link text-black" to="/employer/create"><i className='las la-briefcase me-2'></i><span>Post Job</span></NavLink>
                        </div>
                        <div className=''>
                          <NavLink className="nav-link text-black" to="/jobs/-/candidates/review"><i className='las la-user-plus me-2'></i><span>Review Submissions</span></NavLink>
                        </div>
                      </>
                    )}
                    {/* <div className='h6 ps-4 pt-3 mb-1'><NavLink className="nav-link text-black" to="/profile"><i className='las la-edit me-2'></i>Edit Profile</NavLink></div> */}
                    {/* <div className='h6 ps-4 pt-3 mb-1'><NavLink className="nav-link text-black" to="/admin"><i className='las la-cog me-2'></i>Admin</NavLink></div> */}
                  </>
                )}

                <div className='profile-card-smalll'>
                  <div className=''>
                    <NavLink className="nav-link text-black" to="/profile"><i className='las la-edit me-2'></i><span>Edit Profile</span></NavLink>
                  </div>
                  <div className=''>
                    <NavLink className="nav-link text-black" to="/blog"><i className='las la-briefcase me-2'></i><span>Blog</span></NavLink>
                  </div>
                  <div className=''>
                    <a href="#" className="nav-link text-black" onClick={(e) => { e.preventDefault(); logout() }}><i className='las la-sign-out-alt me-2'></i><span>Logout</span></a>
                  </div>

                </div>
                {/* <div className=''>
                  <div className="nav-link text-black"><i className='las la-info-circle me-2'></i><span>Fine Print</span></div>
                </div>

                <div className='text-start small ps-4'>
                    <NavLink className="nav-link text-black" to="/terms"><span>Terms & Conditions</span></NavLink>
                  </div>

                  <div className='text-start small ps-4'>
                    <NavLink className="nav-link text-black" to="/privacy"><span>Privacy Policy</span></NavLink>
                  </div>
                  <div className='text-start small ps-4'>
                    <NavLink className="nav-link text-black" to="/agreement"><span>Recruiting Agreement</span></NavLink>
                  </div> */}



                {/* <div className='h6 ps-4 pt-3  mb-1'><NavLink className="nav-link text-black" to="/workflows"><i className='las la-network-wired me-2'></i>Workflows</NavLink></div> */}

              </> : <>
                <div className=''>
                  <a className="nav-link text-black" onClick={(e) => { e.preventDefault(); logout() }}><i className='las la-sign-out-alt me-2'></i><span>Logout</span></a>
                </div>
              </>}


              {/* <div className='h6 ps-4 pt-3  mb-1'><a className="nav-link text-black" href="#" onClick={(e) => { e.preventDefault(); logout() }}><i className='las la-sign-out-alt me-2'></i>Logout</a></div> */}
              {/* <NavDropdown.Item href="#" onClick={(e) => { e.preventDefault(); logout() }}>Logout</NavDropdown.Item> */}
            </div>

            :
            <div className='mt-1'>
              <div className='h6 ps-4 pt-3 mb-1'>
                <NavLink className="nav-link text-black" to="/#"><i className='las la-home me-2'></i>Home</NavLink>
              </div>
              <div className='h6 ps-4 pt-3 mb-1'>
                <NavLink className="nav-link text-black" to="/blog"><i className='las la-briefcase me-2'></i>Blog</NavLink>
              </div>
            </div>
          }
          <div className="support-card p-2" style={{ position: "absolute", zIndex: "0", bottom: "100px", right: "10px", left: "10px" }} >

            <SupportCard />

          </div>

        </div>
      </div>
    </>
  );
}

export default NavbarSide;
