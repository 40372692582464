import React, { useState, useEffect, useRef } from 'react';

import CardBody from 'react-bootstrap/esm/CardBody';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import CreateJob from '../Client/CreateJob';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import JobPosts from '../JobPosts';
import { CardHeader, CardTitle, ListGroup, ListGroupItem, Stack } from 'react-bootstrap';
import BannerMessage from '../BannerMessage';
import GenericForm from '../GenericForm';
import RecuiterProfile from '../RecuiterProfile';
import WelcomeMessage from '../WelcomeMessage';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import Messages from '../Messages';
import WelcomeMessageSide from '../WelcomeMessageSide';
import JobPostsCards from '../JobPostsCards';


function DashboardRecruiter() {
    const [showModal, setShowModal] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const profile = useSelector((state) => state.auth.profile);

    useEffect(() => {

    }, []);

    return (
        <div className="">


            <BannerMessage heading="Candidate Dashboard" subheading="Amplify your quality and reach by leveraging the power of everyone, everywhere" />
            <Container className=' mb-3'>

                <Row className='display-flex h-100'>


                    <Col xs={12} md={12} lg={12} xl={12} >

                        {/* <p className='m-0 p-0'><b>Please see all posted roles below</b></p> */}
                        <div className=''>
                            <JobPostsCards />
                        </div>


                    </Col>
{/* 
                    <Col xs={12} md={12} lg={3} xl={3} >
                            <Messages />



                    </Col> */}




                </Row>

            </Container>

        </div>
    );
}

export default DashboardRecruiter;
