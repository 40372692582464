import React, { useState, useEffect, useRef } from 'react';
import GenericForm from '../GenericForm';
import BannerMessage from '../BannerMessage';
import { Nav, NavLink, NavItem, Card, Stack, Row, Container, Col, Alert, Button } from 'react-bootstrap';
import api from '../../Utils/api';
import Client from './Client';
import { useDispatch, useSelector } from 'react-redux';
import Markdown from '../../Widgets/Markdown';
import { getJobsAsync } from '../../Store/dataSlice';
import JobInfoCard from '../../Widgets/JobInfoCard';
import MapPicker from '../../Widgets/MapPicker';
import { useParams } from 'react-router-dom';

const usStates = [
    {
        label: "Not Applicable",
        value: "-NA-",
    },
    {
      label: "Alabama",
      value: "AL",
    },
    {
      label: "Alaska",
      value: "AK",
    },
    {
      label: "Arizona",
      value: "AZ",
    },
    {
      label: "Arkansas",
      value: "AR",
    },
    {
      label: "California",
      value: "CA",
    },
    {
      label: "Colorado",
      value: "CO",
    },
    {
      label: "Connecticut",
      value: "CT",
    },
    {
      label: "Delaware",
      value: "DE",
    },
    {
      label: "Florida",
      value: "FL",
    },
    {
      label: "Georgia",
      value: "GA",
    },
    {
      label: "Hawaii",
      value: "HI",
    },
    {
      label: "Idaho",
      value: "ID",
    },
    {
      label: "Illinois",
      value: "IL",
    },
    {
      label: "Indiana",
      value: "IN",
    },
    {
      label: "Iowa",
      value: "IA",
    },
    {
      label: "Kansas",
      value: "KS",
    },
    {
      label: "Kentucky",
      value: "KY",
    },
    {
      label: "Louisiana",
      value: "LA",
    },
    {
      label: "Maine",
      value: "ME",
    },
    {
      label: "Maryland",
      value: "MD",
    },
    {
      label: "Massachusetts",
      value: "MA",
    },
    {
      label: "Michigan",
      value: "MI",
    },
    {
      label: "Minnesota",
      value: "MN",
    },
    {
      label: "Mississippi",
      value: "MS",
    },
    {
      label: "Missouri",
      value: "MO",
    },
    {
      label: "Montana",
      value: "MT",
    },
    {
      label: "Nebraska",
      value: "NE",
    },
    {
      label: "Nevada",
      value: "NV",
    },
    {
      label: "New Hampshire",
      value: "NH",
    },
    {
      label: "New Jersey",
      value: "NJ",
    },
    {
      label: "New Mexico",
      value: "NM",
    },
    {
      label: "New York",
      value: "NY",
    },
    {
      label: "North Carolina",
      value: "NC",
    },
    {
      label: "North Dakota",
      value: "ND",
    },
    {
      label: "Ohio",
      value: "OH",
    },
    {
      label: "Oklahoma",
      value: "OK",
    },
    {
      label: "Oregon",
      value: "OR",
    },
    {
      label: "Pennsylvania",
      value: "PA",
    },
    {
      label: "Rhode Island",
      value: "RI",
    },
    {
      label: "South Carolina",
      value: "SC",
    },
    {
      label: "South Dakota",
      value: "SD",
    },
    {
      label: "Tennessee",
      value: "TN",
    },
    {
      label: "Texas",
      value: "TX",
    },
    {
      label: "Utah",
      value: "UT",
    },
    {
      label: "Vermont",
      value: "VT",
    },
    {
      label: "Virginia",
      value: "VA",
    },
    {
      label: "Washington",
      value: "WA",
    },
    {
      label: "West Virginia",
      value: "WV",
    },
    {
      label: "Wisconsin",
      value: "WI",
    },
    {
      label: "Wyoming",
      value: "WY",
    },
  ];

  
const CreateJob = () => {
    const dispatch = useDispatch();

    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [activeStep, setActiveStep] = useState(null)
    const user = useSelector((state) => state.auth.user);

    var obj = {
        "client_id": user.profile.company
    }

    const [jobDescription, setJobDescription] = useState({
        "client_id": user.profile.company
    })


    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getJobDetails(id)
        } 
    }, [id]);



    const getJobDetails = async (id) => {

      var apiURL = '/jobs/' + id

      api.get(apiURL)
          .then((response) => {
            setJobDescription(response.data)
          })
          .catch((error) => {
              console.error('Error fetching login :', error);
              setError(error.error)
          });

  }

    useEffect(() => {
        // const profile = JSON.parse(localStorage.getItem('profile'));

        // setJobDescription({ ...jobDescription, ...obj });
        console.log(obj)
        setActiveStep(1)
    }, []);



    // jobDescription ? parseInt(jobDescription.min_salary.replace(/,/g, ''), 10) : 
    const steps = [
        {
            id: 1,
            title: "Job Details",
            subtitle: "More details about the position",
            template: {
                fields: [
                    { name: 'title', label: 'Job Title', type: 'text', value: jobDescription.title, required: true },
                    // { name: 'range', label: 'Salary', type: 'range', value: 10000, required: true },
                    { name: 'skills', label: 'Top Skills Required (press Enter to add)', value: jobDescription.skills, type: 'tags' },
                    { name: 'additional_details', label: 'Describe the responsibilities of this job, required work experience, skills, or education.', value: jobDescription.additional_details, type: 'textarea', required: true },
                    { name: 'url', label: 'Link to existing Job posting', value: jobDescription.url, type: 'text', required: true },
                    {
                        name: 'experience_level', label: 'Experience Level?', type: 'select', value: jobDescription.experience_level, options: [
                            {
                                "label": "Junior",
                                "value": "junior"
                            },
                            {
                                "label": "Mid",
                                "value": "mid"
                            },
                            {
                                "label": "Senior",
                                "value": "senior"
                            }
                        ], required: true
                    },
                    {
                        name: 'role_type', label: 'What type of Role is this?', value: jobDescription.role_type, type: 'select', options: [
                            {
                                "label": "Full Time",
                                "value": "fulltime"
                            },
                            {
                                "label": "Contract to Hire",
                                "value": "contract-to-hire"
                            },
                            {
                                "label": "Temporary / Contract Position",
                                "value": "contractor"
                            }
                        ], required: true
                    },

                    {
                        name: 'job_type', label: 'Job Type', type: 'select',  value: jobDescription.job_type, options: [
                            {
                                "label": "Remote",
                                "value": "remote"
                            },
                            {
                                "label": "On-Site",
                                "value": "onsite"
                            },
                            {
                                "label": "Hybrid",
                                "value": "hybrid"
                            }
                        ], required: true
                    },
                    {
                        name: 'location', label: 'Primary Work Location', value: jobDescription.location, type: 'select', options: usStates,
                        dependsOn: 'job_type', // Specify the field dependency
                        condition: ["onsite", "hybrid"],
                    },
                ],
                button: {
                    label: "Next",
                    variant: "dark"
                },
            },
            onSubmit: function (formData) {
                setJobDescription({ ...jobDescription, ...formData });
                console.log(jobDescription)
                setActiveStep(2)
            }
        },
        // {
        //     id: 2,
        //     title: "Location",
        //     subtitle: "Details about job location",
        //     template: {
        //         fields: [
        //             // { name: 'company', label: 'Company name', type: 'text', value: "", required: true },
        //             // { name: 'location', label: 'Location', type: 'text', value: "", required: true },

        //             {
        //                 name: 'location', label: 'Primary Work Location', type: 'select', options: usStates, required: true
        //             },
        //         ],
        //         button: {
        //             label: "Next",
        //             variant: "primary"
        //         },
        //     },
        //     onSubmit: function (formData) {
        //         console.log(formData);
        //         setJobDescription({ ...jobDescription, ...formData });

        //         console.log(jobDescription)
        //         // userProfile.name = formData.name
        //         setActiveStep(3)
        //     }
        // },
        {
            id: 2,
            title: "Salary",
            subtitle: "",
            template: {
                fields: [
                    {
                        name: 'salary_type', label: 'Salary Type', value: jobDescription.salary_type, type: 'select', options: [
                            {
                                "label": "Hourly",
                                "value": "hourly"
                            },
                            {
                                "label": "Yearly",
                                "value": "yearly"
                            },
                        ], required: true
                    },
                    { name: 'min_salary', label: 'Minium Salary in USD', value: jobDescription.min_salary, type: 'range', required: true },
                    { name: 'max_salary', label: 'Maximum Salary in USD', type: 'range',value: jobDescription.max_salary, required: true },
                ],
                button: {
                    label: "Next",
                    variant: "dark"
                },
            },
            onSubmit: function (formData) {
                setJobDescription({ ...jobDescription, ...formData });
                console.log(jobDescription)
                setActiveStep(3)
            }
        },
        // {
        //     id: 4,
        //     title: "Additional Details",
        //     subtitle: "Please provide any addtional details you like the recuriters to know",
        //     template: {
        //         fields: [
        //             { name: 'additional_details', label: 'Describe the responsibilities of this job, required work experience, skills, or education.', type: 'textarea', required: true },
        //         ],
        //         button: {
        //             label: "Next",
        //             variant: "primary"
        //         },
        //     },
        //     onSubmit: function (formData) {
        //         setJobDescription({ ...jobDescription, ...formData });
        //         console.log(jobDescription)
        //         setActiveStep(5)
        //     }
        // },
        {
            id: 3,
            title: "Review and Create",
            subtitle: "",
            template: {
                fields: [
                    { name: 'terms', label: 'By clicking, you acknowledge that you have read and understood, and agree to Million Recruiters <a href="/#/terms">Terms & Conditions</a> and <a href="/#/privacy">Privacy Policy</a>.', type: 'checkbox', required: true },
                ],
                button: {
                    label: "Submit Job",
                    variant: "dark"
                },
            },
            onSubmit: function (formData) {
                setJobDescription({ ...jobDescription, ...formData });
                console.log(jobDescription)

                // userProfile.terms = formData.terms
                // userProfile.agreement = formData.agreement
                // setUserProfile(userProfile)

                api.post(`/jobs`, jobDescription)
                    .then(response => {
                        const data = response.data;
                        console.log(data);
                        setJobDescription({})
                        setActiveStep(1)
                        setMessage("Your job was successfully created")
                        dispatch(getJobsAsync());
                    })
                    .catch(error => {
                        console.error('Error creating workflow:', error)
                        setError(error.response.data)
                    });
            }
        }
    ]




    return (
        <>
            <BannerMessage 
            subheading="Let's get started" heading={id ? "Edit Job Details" : "New Job"} 
            menu={<Button onClick={() => {  }} variant="primary floating_button "><i className='la la-calendar'></i></Button>}
            />

            <Container className='mb-5'>

                <Row>
                    <Col xs={12} md={9} lg={9} xl={9}>
                        {/* <Col className='offset-md-2 col-md-8'> */}
                        {/* <ListGroup horizontal className='w-100 shadow-sm rounded-2'> */}
                        <div className=' text-center'>
                            <Nav variant="underline" defaultActiveKey="/home" className='mb-3'>
                                {steps.map((step) => (
                                    <Nav.Item onClick={() => {
                                        if (activeStep < step.id) {
                                            return
                                        }
                                        setActiveStep(step.id)
                                    }} >
                                        <NavLink className={`ms-2 me-auto text-center w-100 text-center ${activeStep == step.id ? 'active' : ''} `} >
                                            <div className="fw-bold">{step.title}</div>
                                            {/* <div className='small'>{step.subtitle}</div> */}
                                        </NavLink>
                                        {/* <span className="badge bg-primary rounded-pill">14</span> */}
                                    </Nav.Item>

                                ))}
                            </Nav>


                            {message && (
                                <div className='alert alert-success mb-2'><b>{message}</b></div>
                            )}

                            {error && (
                                <div className='alert alert-danger m-2'><b>{error}</b></div>
                            )}

                        </div>


                        {/* 
                        <ul className='list-group list-group-numbered'>

                            {steps.map((step) => (
                                <li onClick={() => { setActiveStep(step.id)}} className={`list-group-item  d-flex justify-content-between align-items-start ${activeStep == step.id ? 'active' : ''}`} >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{step.title}</div>
                                        {step.subtitle}
                                    </div>
                                </li>

                            ))}

                        </ul>

                    </Col>
                    <Col > */}
                        <Card className='mb-3'>
                            <div className=''>
                                {steps.map((step) => (
                                    <Stack direction="vertical" gap={3} className={activeStep == step.id ? 'active' : 'hidden'} key={step.id}>
                                        {/* <Card.Header className='bg-grey-gradient text-dark border-0'> */}
                                        <Card.Header >
                                            {/* <h5 className='m-0 p-0'>{step.title}</h5> */}
                                            <b>{step.subtitle} ({step.id} / {steps.length})</b>
                                        </Card.Header>

                                        
                                        {/* </Card.Header>
                                        
                                        <Card.Body> */}
                                        <Card.Body>
                                        {
                                            step.id == steps.length && (
                                                <div className='mb-3'><b className='text-muted'>Job Preview</b>
                                                    <div className='p-3 rounded-2 bg-white border'>

                                                        <Stack direction="horizontal" className='align-top mb-3' gap={3} >

                                                            <Stack direction="vertical" className='align-top' gap={0} >
                                                                <h4 className='m-0 p-0'>{jobDescription.title}</h4>
                                                                <JobInfoCard job={jobDescription} />
                                                                {/* <div className='m-0 p-0'>{jobDescription.company}</div>
                                                                <div>{jobDescription.location} | {jobDescription.job_type} | {jobDescription.role_type} </div>
                                                                <div>${jobDescription.min_salary} - ${jobDescription.max_salary} / {jobDescription.salary_type}</div> */}
                                                                <div className='mt-2 small'>
                                                                    <Markdown content={jobDescription.additional_details} />

                                                                </div>
                                                                {/* <div className='mb-3'><span className='badge bg-dark me-2'>{jobDetails.job_type}</span><span className='badge bg-dark'>{jobDetails.role_type}</span></div> */}
                                                            </Stack>
                                                            {/* {jobDescription.client} */}
                                                            <div style={{ maxWidth: "200px" }} className='ms-auto'>
                                                                {/* <Client id={jobDescription.client} /> */}
                                                            </div>
                                                        </Stack>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <GenericForm template={step.template} onSubmit={step.onSubmit} />
                                        </Card.Body>
                                        {/* </Card.Body> */}
                                    </Stack>
                                ))}
                            </div>
                            {/* <GenericForm template={template} onSubmit={handleFormSubmit} /> */}

                        </Card>
                    </Col>
                    <Col xs={12} md={3} lg={3} xl={3}>
                    <Card className='mb-3'>
                            <Card.Header className=''><b>Need Help?</b></Card.Header>
                            <Card.Body>
                            <Stack direction="vertical" gap={1} className=''>
                                <Stack direction="horizontal" gap={3} >
                                    <div className='h1 m-0 p-0 text-center'><i className='las la-calendar '></i></div>
                                    <div>
                                        <p>If you like to be contacted by a company representative. Click below to setup chat</p>
                                    </div>
                                </Stack>
                                <a target="_blank" className="rounded-2 btn btn-outline-dark  w-100" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3Kl_qY76FNj_-p8wzHzKb-0Uqb142xLfhvfzFS34Krz--iDZDDp-StOo3PEAKAUQ_vinlvVerf">Calendly</a>

                            </Stack>
                            </Card.Body>
                            </Card>


                            <Card className=''>
                            <Card.Header  className=''><b>What happens next? What happens after your job gets posted?</b></Card.Header>
                            <Card.Body>
                            <div className='text-center'><i className='las la-handshake text-black la-5x'></i></div>
                            <p>
                                
                                Thousands of freelance recruiters on the platform will submit their best candidates and our team of internal recruiters will identify and send across the absolute best of the best for your consideration.</p>
                            </Card.Body>
                            </Card>


                    </Col>
                    {/* <Col xs={12} md={3} lg={3} xl={3}>


                        <div className='bg-primary text-white rounded-2 p-3'>
                            Need Help?
                        </div>
                    </Col> */}
                </Row>



            </Container>
        </>
    );
};

export default CreateJob;
