import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';
import GenericForm from '../App/GenericForm';
import api from '../Utils/api';
import BlogPosts from './BlogPosts';
import BannerMessage from '../App/BannerMessage';

const Blog = () => {

  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [blogPost, setBlogPost] = useState({})
  const [activeStep, setActiveStep] = useState(null)

  useEffect(() => {
    setActiveStep(1)
  }, []);

  const steps = [
    {
      id: 1,
      title: "Basic Information",
      subtitle: "General info about blog post",
      template: {
        fields: [
          { name: 'title', label: 'Blog Title', type: 'text', value: "", required: true },
          { name: 'description', label: 'Description', type: 'text', value: "", required: true },
          { name: 'url', label: 'Link', type: 'text', value: "", required: true },

        ],
        button: {
          label: "Next",
          variant: "primary"
        },
      },
      onSubmit: function (formData) {
        console.log(formData);
        setBlogPost({ ...blogPost, ...formData });
        setActiveStep(2)
      }
    },
    {
      id: 2,
      title: "Create Blog",
      subtitle: "",
      template: {
        fields: [
          {
            name: 'source', label: 'Source', type: 'select', options: [
              {
                "icon": "fa fa-linkedin",
                "label": "LinkedIn",
                "value": "linkedin"
              },
              {
                "icon": "fa fa-google",
                "label": "Google",
                "value": "google"
              },
              {
                "icon": "fa fa-twitter",
                "label": "Twitter",
                "value": "twitter"
              }
            ], required: true
          },

        ],
        button: {
          label: "Create Blog",
          variant: "primary"
        },
      },
      onSubmit: function (formData) {
        api.post(`/blog`, { type: formData.source, details: blogPost })
          .then(response => {
            const data = response.data;
            console.log(data);
            setBlogPost({})
            setActiveStep(1)
            setMessage("Your Blog Post was successfully created")
          })
          .catch(error => {
            console.error('Error creating workflow:', error)
            setError(error.response.data)
          });
      }
    }
  ]



  const template = {
    fields: [
      { name: 'title', label: 'Job Title', type: 'text', required: true },
      { name: 'url', label: 'Job Posting URL', type: 'text', required: true },
      { name: 'sarma', label: 'Samar', type: 'textarea', required: true },
      { name: 'years_experience', label: 'Years of Experience Required', type: 'text', required: true },
      { name: 'required_skills', label: 'Skills', type: 'textarea', required: false },
      { name: 'subscribe', label: 'Subscribe to Newsletter', type: 'checkbox' },
      { name: 'resume', label: 'Upload Resume', type: 'file', accept: '.pdf,.doc' },
      // Add more fields as needed
    ],
  };

  const handleFormSubmit = (formData) => {
    // Handle the form data here, e.g., send it to the server or perform any other actions
    console.log('Form data submitted:', formData);
  };



  return (
    <>

<BannerMessage heading="Our Blog" subheading="Amplify your quality and reach by leveraging the power of everyone, everywhere" />


      <Container className='mb-2'>
        {/* <Row>
                <Col>
                
                </Col>
              </Row> */}
        <Row className=''>
          <Col>
            {message && (
              <Alert variant='success'>{message}</Alert>
            )}

            {error && (
              <Alert variant='danger'>{error}</Alert>
            )}
          </Col>
        </Row>

        <Row className="g-4">
          <Col xs={12} md={9} lg={12} xl={12}>
            <BlogPosts />
            {/* <ul className='list-group list-group-numbered'>
                            {steps.map((step) => (
                                <li onClick={() => { setActiveStep(step.id)}} className={`list-group-item  d-flex justify-content-between align-items-start ${activeStep == step.id ? 'active' : ''}`} >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{step.title}</div>
                                        {step.subtitle}
                                    </div>
                                </li>
                            ))}

                        </ul> */}

          </Col>
     
        </Row>


        <Row className='mt-5'>
          <Col className=''>
</Col>
        </Row>
      </Container>
    </>
  );
};

export default Blog;
