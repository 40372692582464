import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Dashboard from './App/Dashboard';
import Home from './Home/Home';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const jwtToken = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const persona = useSelector((state) => state.auth.persona);
  const status = useSelector((state) => state.auth.status);

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn || jwtToken ? (
          <Dashboard {...props} user={user} persona={persona} status={status} />
        ) : (
          <Home {...props} />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
