import React, { useState, useEffect, Link, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../Utils/api';
import Spinner from 'react-bootstrap/Spinner'; // Import Bootstrap Spinner
import { useDispatch } from 'react-redux';
import { loginAsync } from '../Store/authSlice';


function LinkedInValidate() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = React.useState("");

  const initialized = useRef(false);

  const [loading, setLoading] = useState(false)
  const [loggedIn, setLoggedIn] = useState(null)
  const [token, setToken] = useState(null)
  const [email, setEmail] = useState(null)
  const [name, setName] = useState(null)
  const [photo, setPhoto] = useState(null)


  // useEffect(() => {
  //   const token = localStorage.getItem('jwtToken');
  //   if (token) {
  //     setLoggedIn(true)
  //     setToken(localStorage.getItem('jwtToken'))
  //     const token = JSON.parse(localStorage.getItem('user'));
  //     setEmail(token.email)
  //     setName(token.name)
  //     setPhoto(token.picture)
  //   }
  // }, []);


  


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
    } else {
      setLoading(false);
      return
    }

    const urlParams = new URLSearchParams(window.location.search);
    const retCode = urlParams.get('code');
    const state = urlParams.get('state');
    const error = urlParams.get('error_description');
    if (error !== null) {
      setError(error)
    }



    if (retCode === null) {
      return
    }

    setLoading(true);

    const existingState = localStorage.getItem("state")
    if (existingState !== state) {
      setLoading(false)
      // history.push('/');
      window.history.replaceState(null, '', '/')
      return
    }

    localStorage.removeItem("state")
    validateLogin(retCode, state)

  }, []);

  const validateLogin = async(retCode, state) => {

    await dispatch(loginAsync({
      code: retCode, state: state 
    }, "linkedin"));

    // history.push('/#/');
    window.history.replaceState(null, '', '/')

    return
    
    api.post(`/linkedin/validate`, { code: retCode, state: state })
      .then(response => {
        const data = response.data;
        console.log(data)
        
        localStorage.setItem('status', data.status);
        localStorage.setItem('jwtToken', data.token);
        localStorage.setItem('user', data.metadata);
        if(data.profile !== "") {
          localStorage.setItem('profile', data.profile);
        }
        // localStorage.setItem('profile', data.persona);
        localStorage.setItem('persona', data.type);
        history.push('/');
        window.location.reload();
      })
      .catch(error => {
        console.error('Error creating workflow:', error)
        setError(error.response.data)
        setLoading(false);
    });    
  }

  return (
    <div className=''>
      
      {error && <div className='ps-3 pt-2 pe-2 text-danger'><b>Login Failed</b></div>}
      {loading ? (
        <div className="text-center ps-3 pt-1 pe-0 ">
          <Spinner className='p-0' style={{ lineHeight:"30px", height: "30px", width: "30px"}} animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          {/* <p>Loading...</p> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LinkedInValidate;


