// AppInitializer.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserProfile } from './Store/authSlice'; // Adjust the path as necessary
import { useSelector } from 'react-redux';

const AppInitializer = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  console.log("app init")
  useEffect(() => {
    if(loggedIn) {
        dispatch(fetchUserProfile());
    }
  }, [dispatch]);

  return null; // This component doesn't render anything
};

export default AppInitializer;
