// store.js
import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // You can choose your storage engine
import authReducer from './authSlice';
import submitCandidateReducer from './submitCandidateSlice'; // Import your submitCandidateSlice
import dataReducer from './dataSlice';

const serializableCheckMiddleware = store => next => action => {
    console.log('Dispatching action:', action);
    let result = next(action);
    console.log('New state:', store.getState());
    return result;
}

const persistConfig = {
  key: 'root', // Change this key as needed
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    submitCandidate: submitCandidateReducer,
    data: dataReducer
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(serializableCheckMiddleware),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          // api: myCustomApiService,
          otherValue: 42
        }
      }
    })

});
export const persistor = persistStore(store); // Export persistor
export default store;
