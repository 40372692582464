import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

const CustomMultiSelect = ({ options, onSelectionChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

    onSelectionChange(selectedOptions)
  };

  return (
    <>
    <CardGroup className=" custom-multi-select">
      {options.map((option) => (
        <Card
          key={option.value}
          className={` option ${selectedOptions.includes(option.value) ? 'selected' : ''}`}
          onClick={() => toggleOption(option.value)}
        >
            <div className='text-center pt-5'><span style={{fontSize: "34px"}} className={option.icon} alt={option.label} /></div>
            <Card.Body className='text-center'>
          <span>{option.label}</span>
          </Card.Body>
        </Card>
      ))}
      </CardGroup>
      <div className="selected-options">
        {selectedOptions.map((selectedOption) => (
          <span key={selectedOption}>{selectedOption}</span>
        ))}
      </div>
    
    </>
  );
};

export default CustomMultiSelect;
