import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';
import BannerMessage from '../App/BannerMessage';
import Markdown from '../Widgets/Markdown';

const AboutUs = () => {

  const content = `

**Last updated: January 14th, 2024**

`

  return (
    <>

    {/* <BannerMessage heading="Independent Contractor Recruiting Agreement & Terms of Engagement" subheading="" /> */}


      <Container className='mt-5 mb-5'>
    
        <Row className="g-4">
        <Col className='offset-md-2 col-md-8'>
            <h2 className='p-3'>About Us</h2>
                    <div className='bg-white rounded-2 shadow-sm  p-5 mb-5'>

      <Markdown content={content} />

</div>

          </Col>
     
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
