import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import api from '../Utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LinkedInPage from '../Login/LinkedIn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image, Card, CardGroup, Container, Stack } from 'react-bootstrap';
import TeamMemberCarousel from './TeamMemberCarousel';
import img23 from '../assets/banner.avif'
import HomeSection3 from './HomeSection3';
import HomeHero1 from './HomeHero1';
import PhotoGrid from './PhotoGrid.js';
import hero_1 from '../assets/hero_1.png'
// import flow from '../assets/flow.png'
import flow from '../assets/MR1.png'

const HomeSection2 = () => {


  return (
    <div className='bg-white' style={{ position: "relative" }} >
      <div className='section-bg'></div>



      <div className='bg-transparent p-5'>
        <div className='text-center'><span className='bg-white rounded-pill p-2 ps-3 pe-3 mb-4 btn border-primary text-primary'><b>How it works?</b></span></div>




        {/* <Container>
  <Row>


    <Col className='col-3'>
</Col>   
<Col className='col-6'>
    <div className='p-5 text-center'>
    <Image className='w-100 ' src={flow} />
    </div>
    </Col> 
  </Row>
</Container> */}

        <Container>
          <Row>
            <Col>

            </Col>
          </Row>
          <Row className="g-4">
            {/* Employer Callout */}
            {/* <Col style={{ position: 'relative' }} xs={12} md={12} lg={5} xl={5} className=" p-0 pe-3 rounded-3 ">
              <div className='text-center' style={{ marginTop: "0px", marginLeft: "0px", marginRight: "-16px", marginBottom: "20px" }} >
                <img src={img23} className='w-100 rounded-top-3' />
              </div>
            </Col> */}




            <Col xs={12} md={12} lg={4} xl={4} className="">

            {/* <p className='p-4'><span className='highlight fs-4'>No monthly fees, no initiation fees, no gimmicks.</span><br/>The only time you'll ever get an invoice from us is if you hire a Million Recruiters candidate. We then distribute it to the Recruiter. When you're happy (i.e. you've made a hire), the Recruiter gets paid.</p> */}


              {/* <p>Amplify your quality and reach by leveraging the power of a million recruiters</p> */}
              <div className=' p-3'>
                {/* <h4 className='ms-3 text-black fw-bolder '>How it works:</h4> */}

                {/* <CardGroup> */}
                <Card className='border-1 border-black border-opacity-50  p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className=' la la-users la-3x'></i>
                    <p className='m-0 h6 '>Thousands of qualified recruiters submit candidates for your open positions</p>
                  </Stack>
                </Card>
                <div className='text-start ms-4 ps-2'><i className='text-muted la la-arrow-down la-2x'></i></div>
                <Card className='border-1 border-black border-opacity-50 bg-white rounded-3 p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-filter la-3x'></i>
                    <p className='m-0 h6 '>Million Recruiters' team of all-star recruiters vets the submissions and selects the top 3 candidates at a time</p>
                  </Stack></Card>
                <div className='text-start ms-4 ps-2'><i className='text-muted la la-arrow-down la-2x'></i></div>
                <Card className='border-1 border-black border-opacity-50 bg-white rounded-3 p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-gem la-3x'></i>
                    <p className='m-0 h6 '>You receive only the diamond-level candidates who have been thoroughly vetted</p>
                  </Stack>
                </Card>
              </div>
              {/* </CardGroup> */}
            </Col>



            <Col xs={12} md={12} lg={4} xl={4} className="">
              {/* <HomeHero1 /> */}

              <img src={hero_1} className='w-100 ' />

              {/* <div className='text-center bg-warning rounded-3 p-2 row justify-content-center align-items-center '>
                <img src={flow} className='w-100 ps-1 pe-1' />
              </div> */}
              {/* <div className='bg-white p-3 rounded-3'>
                <h4 className='ms-3 text-black fw-bolder'>Benefits to Employers:</h4>
                <Card className='border-1 bg-white  rounded-3 p-3 ms-1 me-1 mb-1 '>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-users la-3x'></i>
                    <p className='m-0 h6 text-muted'>Access to an exponentially larger pool of qualified candidates</p>
                  </Stack>
                </Card>
                <Card className='border-1 bg-white rounded-3 p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-handshake la-3x'></i>
                    <p className='m-0 h6 text-muted'>White Glove service - Million Recruiters does the heavy lifting to identify the very best people</p>
                  </Stack>
                </Card>
                <Card className='border-1 bg-white rounded-3 p-3 ms-1 me-1 mb-1'>
                  <Stack direction='horizontal' gap={3} className='text-left'>
                    <i className='la la-money la-3x'></i>
                    <p className='m-0 h6 text-muted'>Less than half the cost of traditional recruiting agencies</p>
                  </Stack>
                </Card>
              </div> */}



            </Col>

            <Col style={{ position: 'relative' }} xs={12} md={12} lg={4} xl={4} className="">

            <p className='p-5'><span className='highlight fs-4'>No monthly fees, no initiation fees, no gimmicks.</span><br/>The only time you'll ever get an invoice from us is if you hire a Million Recruiters candidate. We then distribute it to the Recruiter. When you're happy (i.e. you've made a hire), the Recruiter gets paid.</p>

            </Col> 

            {/* <Col style={{ position: 'relative' }} xs={12} md={12} lg={6} xl={6} className="">
<PhotoGrid />
</Col> */}
            {/* <Col style={{ position: 'relative' }} xs={12} md={12} lg={4} xl={4} className="">
                            <div className='p-4'><HomeHero1 /></div>
                                <img src={img23} className='mt-5  pt-5 w-100 rounded-top-3' />
</Col> */}

          </Row>

        </Container>


      </div>
    </div>
  );
};

export default HomeSection2;
