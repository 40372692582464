import React, { useState, useEffect, useRef } from 'react';
// import Workflows from './Workflows';
// import CreateWorkflow from './CreateWorkflow'
import CardBody from 'react-bootstrap/esm/CardBody';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import illustration_1 from '../assets/illustration_2.webp'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Container from 'react-bootstrap/Container'
import WelcomeMessage from './WelcomeMessage';
import { Alert, Stack, Image } from 'react-bootstrap';
import DashboardRecruiter from './Recruiter/DashboardRecruiter';
import EditProfile from './EditProfile';
import api from '../Utils/api';
import { useHistory } from 'react-router-dom';
import DashboardClient from './Client/DashboardClient';
import DashboardCandidate from './Candidate/DashboardCandidate';
import BannerMessage from './BannerMessage';
import { updateStatus } from '../Store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import CreateProfile from './CreateProfile';

function Dashboard({ }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [editProfile, setEditProfile] = useState(false)
  const [error, setError] = useState(null)
  const [code, setCode] = useState(null)
  const user = useSelector((state) => state.auth.user);
  const status = useSelector((state) => state.auth.status);
  // const [name, setName] = useState(null)
  // const [photo, setPhoto] = useState(null)

  const [email, setEmail] = useState(null)
  const [verify, setVerify] = useState(false)

  useEffect(() => {

  }, []);

  // 

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const resendVerification = () => {
    const isValid = validateEmail(email)
    if(!isValid) {
      setError("Please verify your email address")
      return
    }
    setError(null)
    api.post(`/user/verify/resend`, { email: email })
      .then(response => {
        const data = response.data;
        console.log(data);
        // localStorage.setItem("status", data.status)
        setVerify(true)
        // history.push('/');
      })
      .catch(error => {
        console.error('Error creating workflow:', error)
        setError(error.response.data)
      });
  }
  const verifyUser = async () => {
    api.post(`/user/verify`, { code: code })
      .then(response => {
        const data = response.data;
        console.log(data);
        // localStorage.setItem("status", data.status)

        dispatch(updateStatus(data.status));

        history.push('/');
      })
      .catch(error => {
        console.error('Error creating workflow:', error)
        setError(error.response.data)
      });
  };


  return (
    <>
    {user && (
      <div className="home">
      {status == "PENDING_PROFILE_CREATION" && (
        <div>
          <div className='text-dark bg-info fw-bold p-3'>Welcome to Million Recruiters. Let's get your profile created.</div>
          <EditProfile />
        </div>
      )}

      {(user.type == "recruiter" || user.type == "candidate") && status == "PENDING_MANUAL_VERIFICATION" && (
        <>
          {/* <div className='text-dark bg-info fw-bold p-3'>Welcome to Million Recruiters. Your application is currently under review.</div> */}

          <Container>
            <Row className='row justify-content-center align-items-center vh-100-adjusted '>
              <Col md={{ span: 4, offset: 0 }} className=' text-center'>
                <Card className='bg-transparent border-0'>

                  <Card.Body>
                    {/* <Card.Title><h5>Application Pending Review</h5></Card.Title> */}
                    <div className='text-center'><Image className='w-100' src={illustration_1} /></div>
                    <h5 className='mt-3'>Thank you for signing up for Million Recruiters! We're excited to have you on board soon. </h5>
                    <p>We are reviewing your application and will get in touch with you shortly via email. If you have any questions in the meantime than please reach out to us at <a className='text-decoration-underline' href="mailto:team@millionrecruiters.com">team@millionrecruiters.com</a></p>
                  </Card.Body>
                </Card>
              </Col></Row>
          </Container>
        </>
      )}

      {user.type == "employer" && status == "PENDING_EMAIL_VERIFICATION" && (
        <>
          {/* <div className='text-dark bg-info fw-bold p-3'>Before you can proceed, you need to verify your company email. Please provide the code we sent to your email below.</div> */}

          <Container>
            <Row className='row justify-content-center align-items-center vh-100-adjusted'>
              <Col md={{ span: 5, offset: 0 }} className=' text-start'>
                <Card className=''>
                  <Card.Header>
                    <strong>Email Verification</strong>
                    <br/>Please provide your company/corporate email for verification
                  </Card.Header>

                  <Card.Body>
                  {/* <div className='text-center'><Image className='w-100' src={illustration_1} /></div> */}
                  <div className='text-center'>

                      {
                        verify ? <i className='la la-10x la-envelope-open'></i> : <i className='la la-10x la-envelope'></i>
                      }

                  </div>
                    {/* <div className=' text-warning p-2  border-0'><b>Before you can proceed, you need to verify your company email. Please provide the code we sent to your email below</b></div> */}
                    {error && (<div className='text-danger mb-3 ps-2'><strong>{error}</strong></div>)}

                    {/* <label className="form-label">Please provide your company/corporate email. We will email you a code for verification.</label> */}
                    <Stack direction='horizontal' gap={3} className='mb-3'>
                      <div className="w-100">
                        <input type='email' className='form-control' placeholder=""
                          value={email}
                          defaultValue={user.profile.email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* <div className="p-0 form-text ps-2"><a href="#" onClick={resendVerification}>If you have not recieved the email, please click Re-Send Verification</a></div> */}
                      </div>
                      <div className="p-0"><Button variant="info" className='' onClick={resendVerification}><i className='la la-send'></i></Button></div>
                    </Stack>


                    {verify && (
                      <div className=''>
                        <div className="form-floating mb-3 ">
                          <input type='text' className='form-control' placeholder='000000'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                          <label className="form-label">Verification Code From Email</label>
                          <div className="p-0 form-text ps-2"><a href="#" onClick={resendVerification}>If you have not recieved the email, please click Re-Send Verification</a></div>
                        </div>
                        <Stack direction="vertical" gap={3} >
                          <div className="p-0"><Button variant="success" className='w-100' onClick={verifyUser}>Verify</Button></div>
                        </Stack>

                      </div>
                    )}

                  </Card.Body>
                  <Card.Footer>
                    <div id="passwordHelpBlock" className="form-text">
                      If you have any questions, concerns, or require assistance, please do not hesitate to contact Support
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>

          </Container>



        </>
      )}

      {user.type == "recruiter" && status == "ACTIVE" && (
        <DashboardRecruiter />
      )}

      {user.type == "employer" && status == "ACTIVE" && (
        <DashboardClient user={user} />
      )}

      {user.type == "candidate" && status == "ACTIVE" && (
        <DashboardCandidate />
      )}


    </div>
    )}
    </>
  );
}

export default Dashboard;
