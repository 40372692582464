import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Card, CardHeader, CardTitle, ListGroup, ListGroupItem, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Messages({ heading, subheading }) {
  return (
    <div className=' '>
        {/* <Card className=' '>

<Card.Header className=''><b><i className='la la-envelope me-2'></i>Messages</b></Card.Header>

    <ListGroup variant="flush" >
        <ListGroupItem >
            <Stack direction="horizontal" gap={3} className='align-top'>
                <h5 className='text-center'><i className='las la-bell text-success'></i></h5>
                <p className='small p-0 m-0'><b>No new messages</b></p>
            </Stack>
        </ListGroupItem>
    </ListGroup>

</Card> */}
     </div>

  );
}

export default Messages;
