// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import api from '../Utils/api';
import { setCandidates, setJobs } from './dataSlice';

const initialState = {
    loading: false,
    loggedIn: false,
    user: null,
    // token: null,
    status: null,
    // persona: null,
    // profile: null,
    // email: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            console.log("loading", action.payload)
            state.loading = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        setUser: (state, action) => {
            console.log(action.payload)
            state.user = action.payload;
        },
        // setProfile: (state, action) => {
        //     if (action.payload !== "") {
        //         state.profile = JSON.parse(action.payload);
        //     } else {
        //         state.profile = null; // Set to null instead of an empty string
        //     }
        // },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        // setEmail: (state, action) => {
        //     state.email = action.payload;
        // },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        // setPersona: (state, action) => {
        //     state.persona = action.payload;
        // },
        logout: (state) => {
            return {
                ...initialState, // Reset to initial state
            };
        },


    },
});

export const fetchUserProfile = () => async (dispatch) => {
    try {
        const user = await getUserProfile();
        if (user) {
            dispatch(setUser(user));
            dispatch(setStatus(user.status));
            dispatch(setLoggedIn(true));
        }
    } catch (error) {
        console.error("Error fetching user profile:", error.message);
        // Optionally handle errors, e.g., by clearing auth state
    }
};


// Asynchronous action using redux-thunk
export const logoutAsync = () => async (dispatch) => {
    console.log("logoutAsync")
    try {
        // dispatch(setLoading(true));
        // const response = await simulateLoginAPI(credentials);
        // Dispatch actions based on the result
        dispatch(setToken(null));
        dispatch(setUser(null));
        // dispatch(setProfile(null));
        dispatch(setStatus(null));
        // dispatch(setPersona(null));
        // dispatch(setEmail(null));
        dispatch(setLoggedIn(false));


        dispatch(setJobs(null));
        dispatch(setCandidates(null));
        // dispatch(setLoading(false));
    } catch (error) {
        throw (error.message)

    }
};

export const { logout, setLoading, setLoggedIn,  setToken, setUser, setStatus } = authSlice.actions;

export const updateStatus = (status) => async (dispatch) => {
    dispatch(setStatus(status));
};

// Asynchronous action using redux-thunk
export const loginAsync = (credentials, loginType) => async (dispatch) => {
    console.log("loginAsync", loginType)
    try {
        // dispatch(setLoading(true));
        var response = {}
        if (loginType == "local") {
            response = await simulateLoginAPI(credentials);
        } else {
            response = await verifyLinkedinAPI(credentials);
        }
        dispatch(setToken(response.token));

        const user = await getUserProfile();
        console.log(user)
        dispatch(setUser(user));
        dispatch(setStatus(user.status));
        // dispatch(setPersona(response.user.type));
        // dispatch(setEmail(response.user.email));
        // dispatch(setProfile(response.user.profile));
        dispatch(setLoggedIn(true));
        // dispatch(setLoading(false));
    } catch (error) {
        console.log("ERROR!", error)
        // dispatch(setLoading(false));
        throw (error.message)
    }
};

// Asynchronous action using redux-thunk
export const updateUserProfileAsync = (formData) => async (dispatch) => {
    console.log("updateUserProfileAsync")
    try {
        // dispatch(setLoading(true));
        console.log(formData.profile)
        const response = await updateUserProfile(formData);
        console.log("dispatched", response);
        dispatch(setUser(response));
        dispatch(setStatus(response.status));
        // dispatch(setPersona(response.type));
        // dispatch(setProfile(response.profile));
        // dispatch(setEmail(response.email));
        // dispatch(setLoading(false));
    } catch (error) {
        console.log("ERROR!", error)
        // dispatch(setLoading(false));
        throw (error.message)
    }
};

const updateUserProfile = async (formData) => {
    console.log("updateUserProfile")
    console.log('Form data submitted:', formData);
    var data = {}
    await api.post(`/user/profile`, formData)
        .then(response => {
            data = response.data;
            // localStorage.setItem("status", data.status)
            // history.push('/');
        })
        .catch(error => {
            console.error('Error creating workflow:', error)
            throw (error.message)
        });

    return data
};

const getUserProfile = async () => {
    console.log("getUserProfile")
    var data = {}
    await api.get(`/user/profile`)
        .then(response => {
            data = response.data;
        })
        .catch(error => {
            console.error('Error creating workflow:', error)
            throw (error.message)
        });

    return data
};


// Simulated API call (replace with actual API call)
const simulateLoginAPI = async (credentials) => {
    console.log("simulateLoginAPI")
    var data = {}
    await api.post('/local/login', credentials)
        .then((response) => {
            console.log(response.data)
            // throw new Error('Login failed. Please check your credentials.');
            data = response.data;
        })
        .catch((error) => {
            console.error('Error fetching login :', error);
            throw new Error('Login failed. Please check your credentials.');
        });

    return data
};

const verifyLinkedinAPI = async (credentials) => {
    console.log("verifyLinkedinAPI")
    var data = {}
    await api.post('/linkedin/validate', credentials)
        .then((response) => {
            data = response.data;
            console.log(data)
        })
        .catch((error) => {
            console.error('Error fetching login :', error);
            throw new Error('Error fetching login :', error);
        });

    return data
};


export default authSlice.reducer;
