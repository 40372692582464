import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';
import BannerMessage from '../App/BannerMessage';
import Markdown from '../Widgets/Markdown';

const Privacy = () => {

  const content = `

**Last updated: January 14th, 2024**

At Million Recruiters, protecting users' privacy is of utmost importance. This Privacy Policy explains how we collect, use, share, and protect personal data. Please review it carefully.  

By accessing or using our Services, you acknowledge you have read and consent to the data practices described herein. Any undefined terms carry the meaning in our Terms of Use, which incorporates this Privacy Policy.  

You may request this Policy in an alternative format by emailing office@millionrecruiters.com. 

This Policy covers treatment of the personal data we gather through your use of our Services. It does not cover non-affiliated companies or individuals we do not control or manage. 

 

### 1. Personal Data Collection  
We collect personal data from: 

- You directly, such as account creation, survey responses, contact inquiries. 
- Use of interactive Services tools and features. 
- Cookies and similar technologies that collect data about your use of our Services. 
- Public records sources. 
- Third parties including vendors assisting with Services, advertising, analytics, fraud protection, or generating leads.  
- Social networks if you provide credentials to sign in through them. 

### 2. Uses of Personal Data 
We use personal data to:  

- Provide, customize, and improve our Services. 
- Market and sell access to our Services.  
- Respond to inquiries and communicate based on your preferences. 
- Meet legal and regulatory requirements. 
- Protect legal rights of you, Million Recruiters, and others. 

### 3. Personal Data Sharing 
We may share personal data with: 

- Third parties to meet legal obligations. 
- Successor entities in a business transfer.   
- Aggregated or anonymized form that cannot identify you. 

### 4. Data Security and Retention 
We employ appropriate physical, technical, and organizational safeguards based on data sensitivity. You should also take measures to protect your data. We retain personal data as long as required to provide Services or meet legal requirements. 

### 5. Children's Privacy  
Our Services are not for those under 16. If we learn we collected data from a child under 16 we will promptly delete it. Contact us if you believe a child provided us personal data. 

### 6. Your Privacy Rights 
You may contact us to prevent sharing of your data with third parties for direct marketing. California residents have additional rights under California law to make such requests. 

### 7. European Union Data Subject Rights 
EU residents may have rights under GDPR including access to or erasure of data. Contact us with questions. 

### 8. Changes to the Privacy Policy 
We may change this Policy and will provide notice by website post, email, or other means. Please review regularly. Contact us with any questions. 



`

  return (
    <>

<BannerMessage heading="Privacy Policy" subheading="Last updated: January 14th, 2024" />


<Container className='mb-5'>

  <Row className="g-4">
  <Col className=''>
    <Card>
      <Card.Body><Markdown content={content} /></Card.Body>
    </Card>
    </Col>
  </Row>
</Container>
    </>
  );
};

export default Privacy;
