import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Row, Container, Col, Alert } from 'react-bootstrap';

import GenericForm from '../GenericForm';
import api from '../../Utils/api';
const CreateClient = ({ onCreate }) => {

  // type Client struct {
  //   ID           string    `json:"id"`   // Email
  //   Type         string    `json:"type"` // Persona
  //   Name         string    `json:"name"`
  //   Description  string    `json:"description"`
  //   URL          string    `json:"url"`
  //   Image        string    `json:"image"`
  //   Location     string    `json:"location"`
  //   DateCreated  time.Time `json:"date_created"`
  //   DateModified time.Time `json:"date_modified"`
  //   Created_By   string    `json:"created_by"`
  // }
  
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [blogPost, setBlogPost] = useState({})
  const [activeStep, setActiveStep] = useState(null)

  useEffect(() => {
    setActiveStep(1)
  }, []);

  const steps = [
    {
      id: 1,
      title: "Basic Information",
      subtitle: "General info about your company",
      template: {
        fields: [
          { name: 'name', label: 'Company Name', type: 'text', value: "", required: true },
          { name: 'description', label: 'Description', type: 'text', value: "", required: true },
          { name: 'url', label: 'Website', type: 'text', value: "", required: true },
          { name: 'image', label: 'Image', type: 'text', value: "", required: true },
          { name: 'location', label: 'Location', type: 'text', value: "", required: true },

        ],
        button: {
          label: "Next",
          variant: "primary"
        },
      },
      onSubmit: function (formData) {
        console.log(formData);
        api.post(`/clients`, formData )
        .then(response => {
          const data = response.data;
          console.log(data);
          onCreate()
        })
        .catch(error => {
          console.error('Error creating workflow:', error)
          setError(error.response.data)
        });
      }
    }
  ]






  return (
    <>

      
            <div >
              {/* <h3>New Company</h3> */}
              <Card className=''>
                {steps.map((step) => (
                  <Stack direction="vertical" gap={3} className={activeStep == step.id ? 'active' : 'hidden'} key={step.id}>
                    <Card.Header>
                      
                        {/* <h5 className='m-0 p-0'>{step.title}</h5> */}
                        <strong>{step.subtitle}</strong>
                      
                    </Card.Header>
                    <Card.Body>
                      <GenericForm template={step.template} onSubmit={step.onSubmit} />
                    </Card.Body>
                  </Stack>
                ))}
              </Card>

            </div>
          
    </>
  );
};

export default CreateClient;
