import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import { Image, Stack, Modal, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function SupportCard() {
  const history = useHistory();
  const dispatch = useDispatch();


  return (
    <>

      <Card className=' bg-light border border-info text-center'>
        <Card.Body className='text-center'>
          <i className='las la-5x la-hands-helping'></i>
          <div className=''>
          <h5 className='m-0 p-0 mb-2'>Need Help?</h5>
          <div style={{ lineHeight: "1em"}} className='m-0 p-0'>Let's get some time together to work it out</div>
          <Button className='mt-1 rounded-2 w-100' variant='dark'>Schedule Time</Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default SupportCard;
