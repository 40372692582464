import React from 'react';
import GenericForm from '../App/GenericForm.js';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

const newsletter_template = {
  fields: [
      { name: 'email', label: 'Your Email', type: 'text', required: true },
      { name: 'terms', label: 'By clicking this you agree to our terms and conditions.', type: 'checkbox', required: true },
      // Add more fields as needed
  ],
};

const Footer = () => {
  const handleFormSubmit = (formData) => {
    console.log('Form data submitted:', formData);
    // api.post(`/user/profile`, { email: email, profile: formData, type: profile })
    //     .then(response => {
    //         const data = response.data;
    //         console.log(data);
    //         localStorage.setItem("status", data.status)
    //         history.push('/');
    //     })
    //     .catch(error => {
    //         console.error('Error creating workflow:', error)
    //         setError(error.response.data)
    //     });
};

  return (
    <div className='container footer'>
        <div className="row">
          <div className="col-md-4 p-3">
          <h5 className='m-0 text-white'>Spread Love</h5>
          Follow MR on <i className='fa fa-twitter text-light'></i>

          {/* <h5 className='text-white m-0 mt-3'>Be in the know...</h5>
          Sign up for our newsletter */}
{/* 
          <div className="text-white mt-3 rounded-3" >
            <GenericForm template={newsletter_template} onSubmit={handleFormSubmit} />
          </div> */}


          </div>
          <div className="col-md-4 p-3">
           <h5 className='text-white'>Important Links</h5>
           <ul className=''>
           <li className=''><Link to="/about" className='text-white'>About MR</Link></li>
           <li className=''><Link to="/terms" className='text-white'>Terms</Link></li>
           <li className=''><Link to="/privacy" className='text-white'>Privacy Policy</Link></li>
            <li className=''><Link  to="/agreement" className='text-white'>Independent Contractor Recruiting Agreement & Terms of Engagement</Link></li>
            {/* <li className=''><a href="#" className='text-white'>Link One</a></li>
            <li className=''><a href="#" className='text-white'>Link One</a></li> */}
           </ul>
          </div>
          <div className="col-md-4 p-3">

          <h5 className='text-white m-0 mt-3'>Be in the know...</h5>
          Sign up for our newsletter

          <div className="text-white mt-3 rounded-3" >
            <GenericForm template={newsletter_template} onSubmit={handleFormSubmit} />
          </div>
          {/* <h6 className='text-white'>Privacy Policy and Disclaimer</h6>
        <p className="">At millionrecruiters.com, we respect your privacy and are committed to protecting your personal information. This Privacy Policy will help you understand how we collect, use, and safeguard your personal information. By using millionrecruiters.com, you consent to the practices described in this Privacy Policy. Please take a moment to read this <Link to="/privacy" className='text-decoration-underline text-white'> Privacy Policy</Link> carefully.</p> */}
            
          </div>
        </div>
    </div>
  );
};

export default Footer;
